<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-form ref="form">
      <!-- :readonly="
        this.$route.query.statusPage === 'view' ||
          this.$route.name === 'service-add'
      " -->
      <v-card>
        <isttoolbar
          title="Service Form Details"
          dark
          color="primary"
          icon="mdi-briefcase"
        />
        <v-container>
          <v-row class="mt-1">
            <v-col cols="6">
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProduct"
                    :value="data.orderProduct"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Type </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductType"
                    :value="data.orderProductType"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Series </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductSeries"
                    :value="data.orderProductSeries"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Product Serial Number </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="orderProductSN"
                    :value="data.orderProductSN"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Inspection Result Notes </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-textarea
                    hide-details="auto"
                    :value="data.reportType"
                    ref="notes"
                    class="ma-0"
                    flat
                    outlined
                    readonly
                    dense
                    solo
                    :rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <!-- <v-row align="center" class="mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Calibration </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="calibrationName"
                    :value="data.calibrationName"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" class="mb-4 mr-2 ml-2">
                <v-col cols="4" class="pa-1">
                  <span class="title"> Inspection </span>
                </v-col>
                <v-col cols="8" align-self="start" class="pa-1">
                  <v-text-field
                    hide-details="auto"
                    ref="inspeactionName"
                    :value="data.inspectionName"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  ></v-text-field>
                </v-col>
              </v-row> -->

              <!-- JIKA DATANYA ADALAH GAS METER -->
              <div v-if="data.orderProduct == 'Gas Meter'">
                <v-row align="center" class="mt-4">
                  <v-col cols="12" class="pa-1" style="text-align: center">
                    <span class="title"> Reference Gas </span>
                  </v-col>
                </v-row>
                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="12" class="pa-1">
                    <v-data-table
                      :headers="headers"
                      :items="dataGas"
                      class="elevation-1"
                      :options.sync="options"
                      :server-items-length="Number(data.totalGas)"
                      hide-default-footer
                    >
                      <template v-slot:[`item.lot`]="{ item }">
                        <v-text-field
                          v-model="item.lot"
                          hide-details="auto"
                          class="ma-0"
                          ref="lot"
                          flat
                          outlined
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <template v-slot:[`item.cyl`]="{ item }">
                        <v-text-field
                          v-model="item.cyl"
                          hide-details="auto"
                          class="ma-0"
                          ref="cyl"
                          flat
                          outlined
                          dense
                          solo
                        ></v-text-field>
                      </template>
                      <template v-slot:no-data></template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>

              <!-- JIKA DATANYA ADALAH UTI METER -->
              <div v-if="data.orderProduct == 'UTI Meter'">
                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="4" class="pa-1">
                    <span class="title"> Tape Length </span>
                  </v-col>
                  <v-col cols="8" align-self="start" class="pa-1">
                    <v-row style="display: flex; flex-direction: column">
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.tl10"
                          label="10 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl15"
                          label="15 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl20"
                          label="20 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl25"
                          label="25 M"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.tl30"
                          label="30 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl35"
                          label="35 M"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.tl40"
                          label="40 M"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />

                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="4" class="pa-1">
                    <span class="title"> Valve Connection </span>
                  </v-col>
                  <v-col cols="8" align-self="start" class="pa-1">
                    <v-row style="display: flex; flex-direction: column">
                      <v-col
                        style="display: flex; flex-direction: row; gap: 20px"
                      >
                        <v-checkbox
                          dense
                          v-model="checkbox.pfTap"
                          label="PF 2-1/4” TAP"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.fc50"
                          label="F/C50 Valve"
                        ></v-checkbox>
                        <v-checkbox
                          dense
                          v-model="checkbox.q2Valve"
                          label="Q2 Valve"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <!-- INSPECTION TOOLBAR -->
        <isttoolbar
          title="Inspection Result"
          class="mb-2 mt-2"
          dark
          color="primary"
        >
          <v-spacer />
          <v-btn
            v-if="
              ['Inspection is Completed', 'Inspection is Pending'].includes(
                docStatus.orderNo?.statusDesc
              )
            "
            color="grey"
            @click="openAddInspectionDialog"
          >
            <v-icon left>mdi-plus</v-icon> Update Inspection
          </v-btn>
        </isttoolbar>

        <!-- TABLE INSPECTION -->
        <v-data-table
          class="elevation-1 mt-8 mr-10 ml-10"
          :headers="headersInspector"
          :items="dataInspector"
        >
          <!-- Kolom No (index/nomor urut) -->
          <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 }}
          </template>

          <!-- Custom slot for checkbox -->
          <template v-slot:[`item.checkbox`]="{ item }">
            <v-checkbox v-model="item.checked" />
          </template>

          <!-- Kolom Actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="
                ['Inspection is Completed', 'Inspection is Pending'].includes(
                  docStatus.orderNo?.statusDesc
                )
              "
              icon
              color="red"
              @click="deleteInspection(item)"
              title="Delete Inspection"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:no-data> No data available </template>
        </v-data-table>

        <br />
      </v-card>
    </v-form>

    <v-form ref="form" :readonly="this.$route.query.statusPage === 'view'">
      <v-card>
        <!-- KHUSUS GAS DETECTOR  -->
        <div v-if="data.orderProduct == 'Gas Meter'">
          <isttoolbar
            title="Result of Works"
            class="mb-2 mt-5"
            dark
            color="primary"
          ></isttoolbar>

          <v-row>
            <v-col cols="12">
              <small class="mt-2 w-md-80 mr-10 ml-10 mt-5"
                >*Gunakan titik (.) untuk Nilai Desimal</small
              >
              <v-data-table
                :headers="workResult"
                :items="dataCalibration"
                class="elevation-1 mt-2 w-md-80 pa pa-md mr-10 ml-10"
                :options.sync="options"
                :server-items-length="Number(data.totalGas)"
                hide-default-footer
              >
                <!-- Kolom No (index/nomor urut) -->
                <template v-slot:[`item.no`]="{ index }">
                  {{ index + 1 }}
                </template>

                <template v-slot:[`item.preCalibration`]="{ item }">
                  <v-text-field
                    v-model="item.preCalibration"
                    hide-details="auto"
                    class="ma-0"
                    ref="lot"
                    flat
                    outlined
                    dense
                    solo
                    :rules="[noLettersRule]"
                  ></v-text-field>
                </template>

                <template v-slot:[`item.postCalibration`]="{ item }">
                  <v-text-field
                    v-model="item.postCalibration"
                    hide-details="auto"
                    class="ma-0"
                    ref="cyl"
                    flat
                    outlined
                    dense
                    solo
                    :rules="[noLettersRule]"
                  ></v-text-field>
                </template>

                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="6" class="mt-4 pl-12">
              <span class="title d-block mb-2">Call Period :</span>

              <v-row>
                <!-- Radio Group (Default Options) -->
                <v-col cols="12" sm="6">
                  <v-radio-group
                    v-model="callPeriodSelected"
                    column
                    :disabled="enabledCustom"
                    dense
                    class="mt-0"
                  >
                    <v-radio
                      v-for="option in defaultCallPeriods"
                      :key="option"
                      :label="option"
                      :value="option"
                    />
                  </v-radio-group>
                </v-col>

                <!-- Custom Period Input -->
                <v-col cols="12" sm="6" class="d-flex align-center">
                  <v-checkbox
                    v-model="enabledCustom"
                    class="mr-2 mt-0"
                    hide-details
                    dense
                    @change="clearRadioIfCustom"
                  />
                  <v-text-field
                    v-model="customValue"
                    :disabled="!enabledCustom"
                    type="number"
                    min="1"
                    label="Value"
                    class="mr-2"
                    dense
                    hide-details
                    style="max-width: 100px"
                  />
                  <v-select
                    v-model="customUnit"
                    :items="['day', 'months', 'years']"
                    :disabled="!enabledCustom"
                    label="Unit"
                    dense
                    hide-details
                    style="max-width: 120px"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <isttoolbar
            title="Replacement Parts"
            class="mb-2 mt-5"
            dark
            color="primary"
          >
            <v-spacer />
            <v-btn color="grey" @click="openAddReplacementDialog">
              <v-icon left>mdi-plus</v-icon> Update Parts
            </v-btn>
          </isttoolbar>

          <v-data-table
            :headers="headersChangeParts"
            :items="dataReplacementsParts"
            class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10 mt-5"
            :options.sync="options"
            :server-items-length="Number(data.totalGas)"
            hide-default-footer
          >
            <!-- ✅ Use bracketed syntax for slots with dot -->
            <template v-slot:[`item.oldProduct`]="{ item }">
              <v-text-field
                v-model="item.oldProduct"
                hide-details="auto"
                class="ma-0"
                ref="oldProduct"
                flat
                outlined
                dense
                solo
              ></v-text-field>
            </template>

            <template v-slot:[`item.newProduct`]="{ item }">
              <v-text-field
                v-model="item.newProduct"
                hide-details="auto"
                class="ma-0"
                ref="newProduct"
                flat
                outlined
                dense
                solo
              ></v-text-field>
            </template>

            <template v-slot:[`item.checkbox`]="{ item }">
              <v-checkbox v-model="item.checked" />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="red"
                @click="deleteReplacement(item)"
                title="Delete"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-slot:no-data> No data available </template>
          </v-data-table>
        </div>

        <!-- KHUSUS UTI METER  -->
        <div v-if="data.orderProduct == 'UTI Meter'">
          <isttoolbar
            title="Result of Works"
            class="mb-2 mt-5"
            dark
            color="primary"
          ></isttoolbar>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="workResultUTI"
                :items="dataCalibration"
                class="elevation-1 mt-2 w-md-80 pa pa-md mr-10 ml-10 mt-5"
                :options.sync="options"
                :server-items-length="Number(data.totalGas)"
                hide-default-footer
              >
                <!-- Kolom No (index/nomor urut) -->
                <template v-slot:[`item.no`]="{ index }">
                  {{ index + 1 }}
                </template>

                <template v-slot:[`item.preCalibration`]="{ item }">
                  <v-row
                    style="
                      display: flex;
                      justify-content: center;
                      flex-direction: row;
                      width: 25vh;
                    "
                  >
                    <v-col
                      style="border-right: 1px solid #ccc; padding-right: 8px"
                    >
                      <p><b>Reference Pre</b></p>
                      <v-text-field
                        v-model="item.preCalibration"
                        hide-details="auto"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                      />
                    </v-col>

                    <v-col style="padding-left: 8px">
                      <p><b>Under Test Pre</b></p>
                      <v-text-field
                        v-model="item.preDeviceUnderTest"
                        hide-details="auto"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.postCalibration`]="{ item }">
                  <v-row
                    style="
                      display: flex;
                      justify-content: center;
                      flex-direction: row;
                      width: 25vh;
                    "
                  >
                    <v-col
                      style="border-right: 1px solid #ccc; padding-right: 8px"
                    >
                      <p><b>Reference Post</b></p>
                      <v-text-field
                        v-model="item.postCalibration"
                        hide-details="auto"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                      />
                    </v-col>
                    <v-col style="padding-left: 8px">
                      <p><b>Under Test Post</b></p>
                      <v-text-field
                        v-model="item.postDeviceUnderTest"
                        hide-details="auto"
                        class="ma-0"
                        flat
                        outlined
                        dense
                        solo
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'calibration')"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item, 'calibration')">
                    mdi-delete
                  </v-icon>
                </template>

                <template v-slot:no-data></template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="6" class="mt-4 pl-12">
              <span class="title d-block mb-2">Call Period :</span>

              <v-row>
                <!-- Radio Group (Default Options) -->
                <v-col cols="12" sm="6">
                  <v-radio-group
                    v-model="callPeriodSelected"
                    column
                    :disabled="enabledCustom"
                    dense
                    class="mt-0"
                  >
                    <v-radio
                      v-for="option in defaultCallPeriods"
                      :key="option"
                      :label="option"
                      :value="option"
                    />
                  </v-radio-group>
                </v-col>

                <!-- Custom Period Input -->
                <v-col cols="12" sm="6" class="d-flex align-center">
                  <v-checkbox
                    v-model="enabledCustom"
                    class="mr-2 mt-0"
                    hide-details
                    dense
                    @change="clearRadioIfCustom"
                  />
                  <v-text-field
                    v-model="customValue"
                    :disabled="!enabledCustom"
                    type="number"
                    min="1"
                    label="Value"
                    class="mr-2"
                    dense
                    hide-details
                    style="max-width: 100px"
                  />
                  <v-select
                    v-model="customUnit"
                    :items="['day', 'months', 'years']"
                    :disabled="!enabledCustom"
                    label="Unit"
                    dense
                    hide-details
                    style="max-width: 120px"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <isttoolbar
            title="Replacement Parts"
            class="mb-2 mt-5"
            dark
            color="primary"
          >
            <v-spacer />
            <v-btn color="grey" @click="openAddReplacementDialog">
              <v-icon left>mdi-plus</v-icon> Update Parts
            </v-btn>
          </isttoolbar>

          <v-data-table
            :headers="headersChangeParts"
            :items="dataReplacementsParts"
            class="elevation-1 mt-2 mb-2 w-md-80 pa pa-md mr-10 ml-10 mt-5"
            :options.sync="options"
            :server-items-length="Number(data.totalGas)"
            hide-default-footer
          >
            <template v-slot:[`item.oldProduct`]="{ item }">
              <v-text-field
                v-model="item.oldProduct"
                hide-details="auto"
                class="ma-0"
                ref="oldProduct"
                flat
                outlined
                dense
                solo
              ></v-text-field>
            </template>

            <template v-slot:[`item.newProduct`]="{ item }">
              <v-text-field
                v-model="item.newProduct"
                hide-details="auto"
                class="ma-0"
                ref="newProduct"
                flat
                outlined
                dense
                solo
              ></v-text-field>
            </template>

            <template v-slot:[`item.checkbox`]="{ item }">
              <v-checkbox v-model="item.checked" />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                color="red"
                @click="deleteReplacement(item)"
                title="Delete"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-slot:no-data> No data available </template>
          </v-data-table>
        </div>

        <!-- CATATAN SERVICES ENGINEER -->
        <v-row align="center" class="mr-8 ml-8 mt-4 mb-4">
          <v-col cols="2" class="pa-1">
            <span class="title">Services Notes</span>
          </v-col>
          <v-col align-self="start" class="pa-1">
            <v-textarea
              hide-details="auto"
              v-model="servicesNotes"
              ref="engineer-notes"
              class="ma-0"
              flat
              outlined
              dense
              solo
              :rows="3"
            />
          </v-col>
        </v-row>

        <!-- ACTION GAS DETECTOR DAN UTI METER  -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="saveNewService()">
            Save Service
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <!-- DIALOG ADD REPLACEMENTS -->
    <v-dialog v-model="addReplacementDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline font-weight-medium justify-center">
          Add New Replacement Part
        </v-card-title>

        <v-card-text class="pt-2 px-4">
          <v-form @submit.prevent="addReplacement">
            <v-textarea
              v-model="newReplacement.reportType"
              label="Change Parts Description"
              outlined
              dense
              placeholder="e.g. Replaced O2 sensor..."
              hide-details="auto"
              autofocus
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end px-4 pb-4">
          <v-btn text color="grey darken-1" @click="closeAddReplacementDialog">
            Cancel
          </v-btn>
          <v-btn color="green" text @click="addReplacement"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG ADD INSPECTION -->
    <v-dialog v-model="addInspectionDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline font-weight-medium justify-center">
          Add New Inspection
        </v-card-title>

        <v-card-text class="pt-2 px-4">
          <v-form @submit.prevent="addInspection">
            <v-text-field
              v-model="newInspection.reportType"
              label="Report Type"
              outlined
              dense
              placeholder="e.g. Visual, Leak Test, etc"
              hide-details="auto"
              autofocus
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end px-4 pb-4">
          <v-btn text color="grey darken-1" @click="closeAddInspectionDialog">
            Cancel
          </v-btn>
          <v-btn color="green" text @click="addInspection"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: ["dialog", "data", "docStatus"],
  data: () => ({
    noLettersRule: (value) => {
      // Mengizinkan angka dan titik
      const regex = /^[0-9]*\.?[0-9]*$/;
      return regex.test(value) || "Input must be a valid number";
    },
    checkbox: {
      tl10: false,
      tl15: false,
      tl20: false,
      tl25: false,
      tl30: false,
      tl35: false,
      tl40: false,
      pfTap: false,
      fc50: false,
      q2Valve: false,
    },
    workformInspection: [],
    callPeriodSelected: "",
    customRadioOption: "",
    defaultCallPeriods: ["-1 day", "6 months", "12 months"],
    enabledCustom: false,
    customValue: "",
    customUnit: "",
    headers: [
      {
        text: "Lot",
        align: "start",
        sortable: false,
        value: "lot",
      },
      {
        text: "Cyl",
        align: "center",
        sortable: false,
        value: "cyl",
      },
      {
        text: "Gas Type",
        align: "center",
        sortable: false,
        value: "gasType",
      },
    ],
    options: {
      page: 0,
      size: -1,
    },
    headersInspector: [
      { text: "No", value: "no" },
      { text: "Report Types", value: "reportType", width: "60%" },
      { text: "Option", value: "checkbox" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    headersChangeParts: [
      {
        text: "No",
        align: "start",
        value: "no",
      },
      {
        text: "Change Parts",
        value: "reportType",
        width: "50%",
      },
      {
        text: "Old Product S/N",
        value: "oldProduct",
      },
      {
        text: "New Product S/N",
        value: "newProduct",
      },
      {
        text: "Option",
        value: "checkbox",
      },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    workResult: [
      { text: "No", value: "no" },
      { text: "Gas Type", align: "start", value: "gasType" },
      {
        text: "Reference Gas Concentration",
        value: "concentration",
      },
      { text: "Pre-Calibration", value: "preCalibration" },
      { text: "Post-Calibration", value: "postCalibration" },
      { text: "Unit", value: "unit" },
      // { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    workResultUTI: [
      { text: "No", value: "no" },
      { text: "Deskripsi", align: "start", value: "deskripsi" },
      {
        text: "Pre-Calibration Readings",
        value: "preCalibration",
        sortable: false,
      },
      {
        text: "Post-Calibration Readings",
        value: "postCalibration",
        sortable: false,
      },
    ],
    dataGas: [],
    dataCalibration: [],
    dataInspector: [],
    dataReplacementsParts: [],
    underTestPost: "",
    underTestPre: "",
    servicesNotes: "",
    addInspectionDialog: false,
    newInspection: { reportType: "", checked: false },
    addReplacementDialog: false,
    newReplacement: { reportType: "", checked: false },
  }),
  watch: {
    callPeriodSelected(val) {
      if (val) {
        this.enabledCustom = false;
        this.customValue = "";
        this.customUnit = "";
      }
    },
    dialog: {
      handler: function (after) {
        if (after) {
          // Menyiapkan List Data Gas
          let newArray = [];
          let product = this.data.orderProduct;

          if (product == "Gas Meter") {
            // Ambil dari listCalibration, bukan listGas
            this.data.listCalibration.forEach((el) => {
              newArray.push({
                no: el.no || "",
                noTube: el.noTube || "",
                gasType: el.gasType || "",
                concentration: el.concentration || "",
                unit: el.unit || "",
                deskripsi: el.deskripsi || "",
                maximumErrorAllowed: el.maximumErrorAllowed || "",
                preCalibration: el.preCalibration || "",
                postCalibration: el.postCalibration || "",
                lot: el.lot || "",
                cyl: el.cyl || "",
              });
            });
            this.dataGas = newArray;
          }

          if (product == "UTI Meter") {
            const tapeLengthMap = {
              10: "tl10",
              15: "tl15",
              20: "tl20",
              25: "tl25",
              30: "tl30",
              35: "tl35",
              40: "tl40",
            };

            const tape = (this.data.tapeLength || "").match(/\d+/)?.[0];
            if (
              tape &&
              Object.prototype.hasOwnProperty.call(tapeLengthMap, tape)
            ) {
              this.checkbox[tapeLengthMap[tape]] = true;
            }

            if (this.data.valveConnector === "PF 2-1/4” TAP") {
              this.checkbox.pfTap = true;
            } else if (this.data.valveConnector === "F/C50 Valve") {
              this.checkbox.fc50 = true;
            } else if (this.data.valveConnector === "Q2 Valve") {
              this.checkbox.q2Valve = true;
            }
          }

          if (product == "Other") {
            console.log(this.data);
          }

          this.data.reportType = this.data.engineerNotes;

          this.servicesNotes = this.data.servicesNote || "";

          // Menyiapkan List Data Calibration Gas
          let calibrationArray = [];
          this.data.listCalibration.forEach((el) => {
            calibrationArray.push({
              ...el,
              preDeviceUnderTest: el.preDeviceUnderTest || "",
              postDeviceUnderTest: el.postDeviceUnderTest || "",
            });
          });
          this.dataCalibration = calibrationArray;

          // Menyiapkan data inspection report berdasarkan params configuration
          let newArrayInspector = [];
          this.data.inspectionReport.forEach((el) => {
            if (el.checked != true) {
              el.checked = "";
            }
            el.jenis = "inspection";
            newArrayInspector.push({ ...el });
          });
          this.dataInspector = newArrayInspector;

          if (
            this.data.workResult === undefined ||
            this.data.replacementsParts === undefined
          ) {
            // Menyiapkan data service berdasarkan form inspection reports
            let newArrayReplacementsParts = [];
            this.data.inspectionReport.forEach((el) => {
              if (
                // jika belum ada workResults dan replacementParts
                this.data.workResult === undefined ||
                this.data.replacementsParts === undefined
              ) {
                el.checked = "";
              }

              if (el.reportType) {
                el.reportType = el.reportType.replace("must be replaced.", ".");
                el.reportType = el.reportType.replace(
                  "must be repaired/replaced.",
                  "."
                );
                el.reportType = el.reportType.replace(
                  "is still working, but near the end of it’s service life. Sensor replacement is recommended.",
                  "."
                );
                el.reportType = el.reportType.replace(
                  "is still working, but near the end of it’s service life. Customer can request for replacement or use it as is.",
                  "."
                );
                el.reportType = el.reportType.replace(
                  "is still working, but reaching the end of it’s service life. Sensor replacement is recommended.",
                  "."
                );
                el.reportType = `Replaced ${el.reportType}`;
              }
              el.jenis = "service";

              newArrayReplacementsParts.push({ ...el });
            });

            // setelah menyesuaikan data service text dengan replace, sekarang menghapus value kembar
            const filteredReport = newArrayReplacementsParts.filter(
              (obj, index, arr) => {
                return (
                  arr
                    .map((mapObj) => mapObj.reportType)
                    .indexOf(obj.reportType) === index
                );
              }
            );

            // mengurutkan data service kembali
            filteredReport.sort((a, b) => a.no - b.no);

            // Mengupdate properti no pada data service secara urut
            for (let i = 0; i < filteredReport.length; i++) {
              filteredReport[i].no = String(i + 1);
            }
            this.dataReplacementsParts = filteredReport;
          } else {
            let newArrayReplacementsParts = [];
            this.data.replacementsParts.forEach((el) => {
              if (el.checked != true) {
                el.checked = "";
              }
              el.jenis = "service";

              newArrayReplacementsParts.push({ ...el });
            });
            this.dataReplacementsParts = newArrayReplacementsParts;

            if (
              this.data.workResult &&
              this.data.workResult.callPeriod &&
              !this.defaultCallPeriods.includes(this.data.workResult.callPeriod)
            ) {
              this.customRadioOption = this.data.workResult.callPeriod;
              this.defaultCallPeriods.push(this.customRadioOption);
            }

            this.callPeriodSelected = this.data.workResult.callPeriod;

            this.dataCalibration = this.data.workResult.resultCalibration;
          }
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    deleteReplacement(item) {
      this.dataReplacementsParts = this.dataReplacementsParts
        .filter((i) => i.no !== item.no)
        .map((el, index) => ({ ...el, no: String(index + 1) }));

      this.data.replacementsParts = [...this.dataReplacementsParts];
      this.$toast.success("Replacement part deleted.");
    },
    openAddReplacementDialog() {
      this.newReplacement = { reportType: "", checked: false };
      this.addReplacementDialog = true;
    },
    closeAddReplacementDialog() {
      this.addReplacementDialog = false;
    },
    addReplacement() {
      if (!this.newReplacement.reportType.trim()) {
        return this.$toast.error("Change Parts description cannot be empty!");
      }

      const newItem = {
        ...this.newReplacement,
        no: String(this.dataReplacementsParts.length + 1),
        jenis: "service",
      };

      this.dataReplacementsParts.push(newItem);
      this.data.replacementsParts = [...this.dataReplacementsParts];
      this.addReplacementDialog = false;
      this.$toast.success("Replacement Part added successfully!");
    },
    clearRadioIfCustom(val) {
      if (val) this.callPeriodSelected = "";
    },
    openAddInspectionDialog() {
      this.newInspection = { reportType: "", checked: false };
      this.addInspectionDialog = true;
    },
    closeAddInspectionDialog() {
      this.addInspectionDialog = false;
    },
    addInspection() {
      if (!this.newInspection.reportType.trim())
        return this.$toast.error("Report Type cannot be empty!");
      const newItem = {
        ...this.newInspection,
        no: this.dataInspector.length + 1,
      };
      this.dataInspector.push(newItem);
      this.data.inspectionReport = [...this.dataInspector];
      this.addInspectionDialog = false;
      this.$toast.success("Inspection added successfully!");
    },
    deleteInspection(item) {
      this.dataInspector = this.dataInspector
        .filter((i) => i.no !== item.no)
        .map((el, i) => ({ ...el, no: i + 1 }));
      this.data.inspectionReport = [...this.dataInspector];
      this.$toast.success("Inspection deleted successfully!");
    },
    saveNewService() {
      // Handle Call Period
      let callPeriod = this.callPeriodSelected;

      if (this.enabledCustom) {
        if (!this.customValue || !this.customUnit) {
          this.$toast.error("Please enter a valid custom call period.");
          return;
        }
        callPeriod = `${this.customValue} ${this.customUnit}`;
      }

      // Handle Calibration Data
      let resultCalibration = [];

      if (this.data.orderProduct === "UTI Meter") {
        // Untuk UTI Meter → simpan data lengkap
        resultCalibration = this.dataCalibration.map((item) => ({
          ...item,
          preDeviceUnderTest: item.preDeviceUnderTest || "",
          postDeviceUnderTest: item.postDeviceUnderTest || "",
        }));
      } else {
        // Untuk Gas Meter dan lainnya → hapus properti yang tidak dibutuhkan
        resultCalibration = this.dataCalibration.map((item) => {
          const copy = { ...item };
          delete copy.preDeviceUnderTest;
          delete copy.postDeviceUnderTest;
          return copy;
        });
      }

      // Filter Replacement Parts yang dicentang
      const filteredReplacements = this.dataReplacementsParts.filter(
        (item) => item.checked === true
      );

      // Validasi minimal satu replacement dipilih
      if (filteredReplacements.length === 0) {
        this.$toast.error("Please select at least one replacement part.");
        return;
      }

      this.data.servicesNote = this.servicesNotes || "";

      const result = {
        resultCalibration,
        callPeriod,
      };

      this.doAdd(result, filteredReplacements);
    },
    doAdd(workResult, replacementParts) {
      this.data["workResult"] = workResult;
      this.data["replacementsParts"] = replacementParts;
      this.data.inspectionReport = this.dataInspector;
      console.log(this.data, "<< CEK FINAL DATA");
      this.$emit("save");
    },
  },
};
</script>
