// Mutations
export const MUTATE_TAB_INVOICE = "invoice/MUTATE_TAB_INVOICE";
export const MUTATE_STATUS_SUBMIT = "invoice/MUTATE_STATUS_SUBMIT";
export const MUTATE_ADD_STATE = "invoice/MUTATE_ADD_STATE";
export const MUTATE_STATUS_PAGE = "invoice/MUTATE_STATUS_PAGE";
export const MUTATE_RESET_STATE_PREMIUM = "premium/MUTATE_RESET_STATE_PREMIUM";
export const MUTATE_REQUIRED_CUSTOMER = "invoice/MUTATE_REQUIRED_CUSTOMER";
export const MUTATE_REQUIRED_CLASS_OF_RISK =
  "invoice/MUTATE_REQUIRED_CLASS_OF_RISK";
export const MUTATE_REQUIRED_INSURENCE_PERIODE_END =
  "invoice/MUTATE_REQUIRED_INSURENCE_PERIODE_END";
export const MUTATE_REQUIRED_INSURENCE_PERIODE_FROM =
  "invoice/MUTATE_REQUIRED_INSURENCE_PERIODE_FROM";
export const MUTATE_REQUIRED_ACTIVE_DATE =
  "invoice/MUTATE_REQUIRED_ACTIVE_DATE";
export const MUTATE_REQUIRED_BUSINESS_CATEGORY =
  "invoice/MUTATE_REQUIRED_BUSINESS_CATEGORY";
export const MUTATE_REQUIRED_ACCOUNT_EXECUTIVE =
  "invoice/MUTATE_REQUIRED_ACCOUNT_EXECUTIVE";
export const MUTATE_REQUIRED_SEGMENT = "invoice/MUTATE_REQUIRED_SEGMENT";
export const MUTATE_STATUS_APPROVE = "invoice/MUTATE_STATUS_APPROVE";
export const MUTATE_STATUS_DATA = "invoice/MUTATE_STATUS_DATA";
export const MUTATE_STATUS_ENGINEERING_FEE =
  "invoice/MUTATE_STATUS_ENGINEERING_FEE";

export const MUTATE_APPROVE_BY = 'MUTATE_APPROVE_BY'
export const MUTATE_APPROVE_DATE = 'MUTATE_APPROVE_DATE'
export const MUTATE_CLOSE_BY = 'MUTATE_CLOSE_BY'
export const MUTATE_CLOSE_DATE = 'MUTATE_CLOSE_DATE'

export const MUTATE_ID_INVOICE = "invoice/MUTATE_ID_INVOICE";
export const MUTATE_CUSTOMER = "invoice/MUTATE_CUSTOMER";
export const MUTATE_CUSTOMER_ID = "invoice/MUTATE_CUSTOMER_ID";
export const MUTATE_CUSTOMER_NAME = "invoice/MUTATE_CUSTOMER_NAME";
export const MUTATE_INVOICE_TYPE = "invoice/MUTATE_INVOICE_TYPE";
export const MUTATE_REFF_NO = "invoice/MUTATE_REFF_NO";
export const MUTATE_STATUS_DESC = "invoice/MUTATE_STATUS_DESC";
export const MUTATE_ID_COVER_NOTE = "invoice/MUTATE_ID_COVER_NOTE";
export const MUTATE_CREATE_BY = "invoice/MUTATE_CREATE_BY";
export const MUTATE_CREATE_DATE = "invoice/MUTATE_CREATE_DATE";
export const MUTATE_MODIFIED_BY = "invoice/MUTATE_MODIFIED_BY";
export const MUTATE_MODIFIED_DATE = "invoice/MUTATE_MODIFIED_DATE";
export const MUTATE_DATA_EMPLOYEE = "invoice/MUTATE_DATA_EMPLOYEE";
export const MUTATE_DATA_SEGMENT = "invoice/MUTATE_DATA_SEGMENT";
export const MUTATE_DATA_SUB_SEGMENT = "invoice/MUTATE_DATA_SUB_SEGMENT";

// ------CLASS TAB------
export const MUTATE_ACTIVE_DATE = "invoice/MUTATE_ACTIVE_DATE";
export const MUTATE_BUSINESS_CATEGORY = "invoice/MUTATE_BUSINESS_CATEGORY";
export const MUTATE_BUSINESS_NAME = "invoice/MUTATE_BUSINESS_NAME";
export const MUTATE_DESCRIPTION = "invoice/MUTATE_DESCRIPTION";
export const MUTATE_INSURENCE_PERIODE_END =
  "invoice/MUTATE_INSURENCE_PERIODE_END";
export const MUTATE_INSURENCE_PERIODE_FROM =
  "invoice/MUTATE_INSURENCE_PERIODE_FROM";
export const MUTATE_INSURED = "invoice/MUTATE_INSURED";
export const MUTATE_LOCATION = "invoice/MUTATE_LOCATION";
export const MUTATE_MASTER_POLICY = "invoice/MUTATE_MASTER_POLICY";
export const MUTATE_POLICY_NO = "invoice/MUTATE_POLICY_NO";
export const MUTATE_RISK_CODE = "invoice/MUTATE_RISK_CODE";
export const MUTATE_RISK_NAME = "invoice/MUTATE_RISK_NAME";
export const MUTATE_ID_ACCOUNT_EXECUTIVE =
  "invoice/MUTATE_ID_ACCOUNT_EXECUTIVE";
export const MUTATE_SEGMENT_CODE = "invoice/MUTATE_SEGMENT_CODE";
export const MUTATE_SUB_SEGMENT_CODE = "invoice/MUTATE_SUB_SEGMENT_CODE";

// ------VALUE TAB------
export const MUTATE_ENGINEERING_PERCENTAGE =
  "invoice/MUTATE_ENGINEERING_PERCENTAGE";
export const MUTATE_CLIENT_VAT_PERCENTAGE =
  "invoice/MUTATE_CLIENT_VAT_PERCENTAGE";
export const MUTATE_CURRENCY = "invoice/MUTATE_CURRENCY";
export const MUTATE_EXCHANGE_RATE = "invoice/MUTATE_EXCHANGE_RATE";
export const MUTATE_INCOME_BROKERAGE = "invoice/MUTATE_INCOME_BROKERAGE";
export const MUTATE_INCOME_NET = "invoice/MUTATE_INCOME_NET";
export const MUTATE_INCOME_TOTAL = "invoice/MUTATE_INCOME_TOTAL";
export const MUTATE_INCOME_VARIOUS = "invoice/MUTATE_INCOME_VARIOUS";
export const MUTATE_INCOME_VAT = "invoice/MUTATE_INCOME_VAT";
export const MUTATE_INSURER_VAT_PERCENTAGE =
  "invoice/MUTATE_INSURER_VAT_PERCENTAGE";
export const MUTATE_INSURER_PPH_PERCENTAGE =
  "invoice/MUTATE_INSURER_PPH_PERCENTAGE";
export const MUTATE_PREMIUM_RATE = "invoice/MUTATE_PREMIUM_RATE";
export const MUTATE_SUM_INSURED = "invoice/MUTATE_SUM_INSURED";
export const MUTATE_TO_CLIENT_ADM = "invoice/MUTATE_TO_CLIENT_ADM";
export const MUTATE_TO_CLIENT_GROSS = "invoice/MUTATE_TO_CLIENT_GROSS";
export const MUTATE_TO_CLIENT_GROSS_TOTAL =
  "invoice/MUTATE_TO_CLIENT_GROSS_TOTAL";
export const MUTATE_TO_CLIENT_POL_END_COST =
  "invoice/MUTATE_TO_CLIENT_POL_END_COST";
export const MUTATE_TO_CLIENT_PREMIUM = "invoice/MUTATE_TO_CLIENT_PREMIUM";
export const MUTATE_TO_CLIENT_STAMP_DUTY =
  "invoice/MUTATE_TO_CLIENT_STAMP_DUTY";
export const MUTATE_TO_CLIENT_VAT = "invoice/MUTATE_TO_CLIENT_VAT";
export const MUTATE_TO_UNDERWRITER_GROSS =
  "invoice/MUTATE_TO_UNDERWRITER_GROSS";
export const MUTATE_TO_UNDERWRITER_NET = "invoice/MUTATE_TO_UNDERWRITER_NET";
export const MUTATE_TO_UNDERWRITER_POL_END_COST =
  "invoice/MUTATE_TO_UNDERWRITER_POL_END_COST";
export const MUTATE_TO_UNDERWRITER_PPH23 =
  "invoice/MUTATE_TO_UNDERWRITER_PPH23";
export const MUTATE_TO_UNDERWRITER_PREMIUM =
  "invoice/MUTATE_TO_UNDERWRITER_PREMIUM";
export const MUTATE_TO_UNDERWRITER_STAMP_DUTY =
  "invoice/MUTATE_TO_UNDERWRITER_STAMP_DUTY";
export const MUTATE_TO_UNDERWRITER_VAT = "invoice/MUTATE_TO_UNDERWRITER_VAT";
export const MUTATE_VARIOUS_ADD_BROKERAGE =
  "invoice/MUTATE_VARIOUS_ADD_BROKERAGE";
export const MUTATE_VARIOUS_COMMISSION_OUT =
  "invoice/MUTATE_VARIOUS_COMMISSION_OUT";
export const MUTATE_VARIOUS_OTHERS = "invoice/MUTATE_VARIOUS_OTHERS";
export const MUTATE_VARIOUS_TOTAL = "invoice/MUTATE_VARIOUS_TOTAL";
export const MUTATE_PREMIUM_CLIENT_INSTALLMENT =
  "invoice/MUTATE_PREMIUM_CLIENT_INSTALLMENT";

// ------INSURANCE TAB------
export const MUTATE_INSURANCE_SHARING_LIST_UNDER =
  "invoice/MUTATE_INSURANCE_SHARING_LIST_UNDER";
export const MUTATE_INSURANCE_SHARING_LIST =
  "invoice/MUTATE_INSURANCE_SHARING_LIST";
export const MUTATE_PREMIUM = "invoice/MUTATE_PREMIUM";
export const MUTATE_EF_PREMIUM = "invoice/MUTATE_EF_PREMIUM";
export const MUTATE_TOTAL_ADMIN_COST = "invoice/MUTATE_TOTAL_ADMIN_COST";
export const MUTATE_TOTAL_AMOUNT = "invoice/MUTATE_TOTAL_AMOUNT";
export const MUTATE_TOTAL_BROKERAGE = "invoice/MUTATE_TOTAL_BROKERAGE";
export const MUTATE_TOTAL_BROKERAGE_AMOUNT =
  "invoice/MUTATE_TOTAL_BROKERAGE_AMOUNT";
export const MUTATE_TOTAL_EF_AMOUNT = "invoice/MUTATE_TOTAL_EF_AMOUNT";
export const MUTATE_TOTAL_EF_PERCENTAGE = "invoice/MUTATE_TOTAL_EF_PERCENTAGE";
export const MUTATE_TOTAL_EF_SHARING_AMOUNT =
  "invoice/MUTATE_TOTAL_EF_SHARING_AMOUNT";
export const MUTATE_TOTAL_GROSS = "invoice/MUTATE_TOTAL_GROSS";
export const MUTATE_TOTAL_PPH23 = "invoice/MUTATE_TOTAL_PPH23";
export const MUTATE_TOTAL_PERCENTAGE = "invoice/MUTATE_TOTAL_PERCENTAGE";
export const MUTATE_TOTAL_STAMP_DUTY = "invoice/MUTATE_TOTAL_STAMP_DUTY";
export const MUTATE_TOTAL_TOTAL_AMOUNT = "invoice/MUTATE_TOTAL_TOTAL_AMOUNT";
export const MUTATE_TOTAL_VAT = "invoice/MUTATE_TOTAL_VAT";
export const MUTATE_MULTI_INSURED = "invoice/MUTATE_MULTI_INSURED";

// ------INSTALLMENT TAB------
export const MUTATE_DOC_TYPE = "invoice/MUTATE_DOC_TYPE";
export const MUTATE_FILE_NAME = "invoice/MUTATE_FILE_NAME";
export const MUTATE_TO_CLIENT_INSTALLMENT_LIST =
  "invoice/MUTATE_TO_CLIENT_INSTALLMENT_LIST";
export const MUTATE_TO_CLIENT_TOTAL_AMOUNT =
  "invoice/MUTATE_TO_CLIENT_TOTAL_AMOUNT";
export const MUTATE_TO_CLIENT_TOTAL_PERCENTAGE =
  "invoice/MUTATE_TO_CLIENT_TOTAL_PERCENTAGE";
export const MUTATE_TO_CLIENT_TOTAL_ADMIN_COST =
  "invoice/MUTATE_TO_CLIENT_TOTAL_ADMIN_COST";
export const MUTATE_TO_CLIENT_TOTAL_STAMP_DUTY =
  "invoice/MUTATE_TO_CLIENT_TOTAL_STAMP_DUTY";
export const MUTATE_TO_CLIENT_TOTAL_VAT = "invoice/MUTATE_TO_CLIENT_TOTAL_VAT";
export const MUTATE_TO_CLIENT_TOTAL_GROSS =
  "invoice/MUTATE_TO_CLIENT_TOTAL_GROSS";
export const MUTATE_TO_CLIENT_TOTAL_TOTAL_AMOUNT =
  "invoice/MUTATE_TO_CLIENT_TOTAL_TOTAL_AMOUNT";
export const MUTATE_TO_UNDERWRITER_INSTALLMENT_LIST =
  "invoice/MUTATE_TO_UNDERWRITER_INSTALLMENT_LIST";
export const MUTATE_TO_UNDERWRITER_TOTAL_AMOUNT =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_AMOUNT";
export const MUTATE_TO_UNDERWRITER_TOTAL_PERCENTAGE =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_PERCENTAGE";
export const MUTATE_TO_UNDERWRITER_TOTAL_ADMIN_COST =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_ADMIN_COST";
export const MUTATE_TO_UNDERWRITER_TOTAL_BROKERAGE =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_BROKERAGE";
export const MUTATE_TO_UNDERWRITER_TOTAL_GROSS =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_GROSS";
export const MUTATE_TO_UNDERWRITER_TOTAL_PPH =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_PPH";
export const MUTATE_TO_UNDERWRITER_TOTAL_STAMP_DUTY =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_STAMP_DUTY";
export const MUTATE_TO_UNDERWRITER_TOTAL_TOTAL_AMOUNT =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_TOTAL_AMOUNT";
export const MUTATE_TO_UNDERWRITER_TOTAL_VAT =
  "invoice/MUTATE_TO_UNDERWRITER_TOTAL_VAT";

// ------AGENT TAB------
export const MUTATE_AGENT_ID = "invoice/MUTATE_AGENT_ID";
export const MUTATE_AGENT_NAME = "invoice/MUTATE_AGENT_NAME";
export const MUTATE_AMOUNT = "invoice/MUTATE_AMOUNT";
export const MUTATE_AGENT_LIST = "invoice/MUTATE_AGENT_LIST";
export const MUTATE_AGENT_TOTAL_AMOUNT = "invoice/MUTATE_AGENT_TOTAL_AMOUNT";

// Actions
export const ACTION_TAB_INVOICE = "invoice/ACTION_TAB_INVOICE";
export const ACTION_STATUS_SUBMIT = "invoice/ACTION_STATUS_SUBMIT";
export const ACTION_ADD_STATE = "invoice/ACTION_ADD_STATE";
export const ACTION_STATUS_PAGE = "invoice/ACTION_STATUS_PAGE";
export const ACTION_RESET_STATE_PREMIUM = "premium/ACTION_RESET_STATE_PREMIUM";
export const ACTION_REQUIRED_CUSTOMER = "invoice/ACTION_REQUIRED_CUSTOMER";
export const ACTION_REQUIRED_CLASS_OF_RISK =
  "invoice/ACTION_REQUIRED_CLASS_OF_RISK";
export const ACTION_REQUIRED_INSURENCE_PERIODE_END =
  "invoice/ACTION_REQUIRED_INSURENCE_PERIODE_END";
export const ACTION_REQUIRED_INSURENCE_PERIODE_FROM =
  "invoice/ACTION_REQUIRED_INSURENCE_PERIODE_FROM";
export const ACTION_REQUIRED_ACTIVE_DATE =
  "invoice/ACTION_REQUIRED_ACTIVE_DATE";
export const ACTION_REQUIRED_BUSINESS_CATEGORY =
  "invoice/ACTION_REQUIRED_BUSINESS_CATEGORY";
export const ACTION_REQUIRED_ACCOUNT_EXECUTIVE =
  "invoice/ACTION_REQUIRED_ACCOUNT_EXECUTIVE";
export const ACTION_REQUIRED_SEGMENT = "invoice/ACTION_REQUIRED_SEGMENT";
export const ACTION_STATUS_APPROVE = "invoice/ACTION_STATUS_APPROVE";
export const ACTION_STATUS_DATA = "invoice/ACTION_STATUS_DATA";
export const ACTION_STATUS_ENGINEERING_FEE =
  "invoice/ACTION_STATUS_ENGINEERING_FEE";

export const ACTION_ID_INVOICE = "invoice/ACTION_ID_INVOICE";
export const ACTION_CUSTOMER = "invoice/ACTION_CUSTOMER";
export const ACTION_CUSTOMER_ID = "invoice/ACTION_CUSTOMER_ID";
export const ACTION_CUSTOMER_NAME = "invoice/ACTION_CUSTOMER_NAME";
export const ACTION_INVOICE_TYPE = "invoice/ACTION_INVOICE_TYPE";
export const ACTION_REFF_NO = "invoice/ACTION_REFF_NO";
export const ACTION_STATUS_DESC = "invoice/ACTION_STATUS_DESC";
export const ACTION_ID_COVER_NOTE = "invoice/ACTION_ID_COVER_NOTE";
export const ACTION_CREATE_BY = "invoice/ACTION_CREATE_BY";
export const ACTION_CREATE_DATE = "invoice/ACTION_CREATE_DATE";
export const ACTION_MODIFIED_BY = "invoice/ACTION_MODIFIED_BY";
export const ACTION_MODIFIED_DATE = "invoice/ACTION_MODIFIED_DATE";
export const ACTION_DATA_EMPLOYEE = "invoice/ACTION_DATA_EMPLOYEE";
export const ACTION_DATA_SEGMENT = "invoice/ACTION_DATA_SEGMENT";
export const ACTION_DATA_SUB_SEGMENT = "invoice/ACTION_DATA_SUB_SEGMENT";

// ------CLASS TAB------
export const ACTION_ACTIVE_DATE = "invoice/ACTION_ACTIVE_DATE";
export const ACTION_BUSINESS_CATEGORY = "invoice/ACTION_BUSINESS_CATEGORY";
export const ACTION_BUSINESS_NAME = "invoice/ACTION_BUSINESS_NAME";
export const ACTION_DESCRIPTION = "invoice/ACTION_DESCRIPTION";
export const ACTION_INSURENCE_PERIODE_END =
  "invoice/ACTION_INSURENCE_PERIODE_END";
export const ACTION_INSURENCE_PERIODE_FROM =
  "invoice/ACTION_INSURENCE_PERIODE_FROM";
export const ACTION_INSURED = "invoice/ACTION_INSURED";
export const ACTION_LOCATION = "invoice/ACTION_LOCATION";
export const ACTION_MASTER_POLICY = "invoice/ACTION_MASTER_POLICY";
export const ACTION_POLICY_NO = "invoice/ACTION_POLICY_NO";
export const ACTION_RISK_CODE = "invoice/ACTION_RISK_CODE";
export const ACTION_RISK_NAME = "invoice/ACTION_RISK_NAME";
export const ACTION_ID_ACCOUNT_EXECUTIVE =
  "invoice/ACTION_ID_ACCOUNT_EXECUTIVE";
export const ACTION_SEGMENT_CODE = "invoice/ACTION_SEGMENT_CODE";
export const ACTION_SUB_SEGMENT_CODE = "invoice/ACTION_SUB_SEGMENT_CODE";

// ------VALUE TAB------
export const ACTION_ENGINEERING_PERCENTAGE =
  "invoice/ACTION_ENGINEERING_PERCENTAGE";
export const ACTION_CLIENT_VAT_PERCENTAGE =
  "invoice/ACTION_CLIENT_VAT_PERCENTAGE";
export const ACTION_CURRENCY = "invoice/ACTION_CURRENCY";
export const ACTION_EXCHANGE_RATE = "invoice/ACTION_EXCHANGE_RATE";
export const ACTION_INCOME_BROKERAGE = "invoice/ACTION_INCOME_BROKERAGE";
export const ACTION_INCOME_NET = "invoice/ACTION_INCOME_NET";
export const ACTION_INCOME_TOTAL = "invoice/ACTION_INCOME_TOTAL";
export const ACTION_INCOME_VARIOUS = "invoice/ACTION_INCOME_VARIOUS";
export const ACTION_INCOME_VAT = "invoice/ACTION_INCOME_VAT";
export const ACTION_INSURER_VAT_PERCENTAGE =
  "invoice/ACTION_INSURER_VAT_PERCENTAGE";
export const ACTION_INSURER_PPH_PERCENTAGE =
  "invoice/ACTION_INSURER_PPH_PERCENTAGE";
export const ACTION_PREMIUM_RATE = "invoice/ACTION_PREMIUM_RATE";
export const ACTION_SUM_INSURED = "invoice/ACTION_SUM_INSURED";
export const ACTION_TO_CLIENT_ADM = "invoice/ACTION_TO_CLIENT_ADM";
export const ACTION_TO_CLIENT_GROSS = "invoice/ACTION_TO_CLIENT_GROSS";
export const ACTION_TO_CLIENT_GROSS_TOTAL =
  "invoice/ACTION_TO_CLIENT_GROSS_TOTAL";
export const ACTION_TO_CLIENT_POL_END_COST =
  "invoice/ACTION_TO_CLIENT_POL_END_COST";
export const ACTION_TO_CLIENT_PREMIUM = "invoice/ACTION_TO_CLIENT_PREMIUM";
export const ACTION_TO_CLIENT_STAMP_DUTY =
  "invoice/ACTION_TO_CLIENT_STAMP_DUTY";
export const ACTION_TO_CLIENT_VAT = "invoice/ACTION_TO_CLIENT_VAT";
export const ACTION_TO_UNDERWRITER_GROSS =
  "invoice/ACTION_TO_UNDERWRITER_GROSS";
export const ACTION_TO_UNDERWRITER_NET = "invoice/ACTION_TO_UNDERWRITER_NET";
export const ACTION_TO_UNDERWRITER_POL_END_COST =
  "invoice/ACTION_TO_UNDERWRITER_POL_END_COST";
export const ACTION_TO_UNDERWRITER_PPH23 =
  "invoice/ACTION_TO_UNDERWRITER_PPH23";
export const ACTION_TO_UNDERWRITER_PREMIUM =
  "invoice/ACTION_TO_UNDERWRITER_PREMIUM";
export const ACTION_TO_UNDERWRITER_STAMP_DUTY =
  "invoice/ACTION_TO_UNDERWRITER_STAMP_DUTY";
export const ACTION_TO_UNDERWRITER_VAT = "invoice/ACTION_TO_UNDERWRITER_VAT";
export const ACTION_VARIOUS_ADD_BROKERAGE =
  "invoice/ACTION_VARIOUS_ADD_BROKERAGE";
export const ACTION_VARIOUS_COMMISSION_OUT =
  "invoice/ACTION_VARIOUS_COMMISSION_OUT";
export const ACTION_VARIOUS_OTHERS = "invoice/ACTION_VARIOUS_OTHERS";
export const ACTION_VARIOUS_TOTAL = "invoice/ACTION_VARIOUS_TOTAL";
export const ACTION_PREMIUM_CLIENT_INSTALLMENT =
  "invoice/ACTION_PREMIUM_CLIENT_INSTALLMENT";

// ------INSURANCE TAB------
export const ACTION_INSURANCE_SHARING_LIST_UNDER =
  "invoice/ACTION_INSURANCE_SHARING_LIST_UNDER";
export const ACTION_INSURANCE_SHARING_LIST =
  "invoice/ACTION_INSURANCE_SHARING_LIST";
export const ACTION_PREMIUM = "invoice/ACTION_PREMIUM";
export const ACTION_EF_PREMIUM = "invoice/ACTION_EF_PREMIUM";
export const ACTION_TOTAL_ADMIN_COST = "invoice/ACTION_TOTAL_ADMIN_COST";
export const ACTION_TOTAL_AMOUNT = "invoice/ACTION_TOTAL_AMOUNT";
export const ACTION_TOTAL_BROKERAGE = "invoice/ACTION_TOTAL_BROKERAGE";
export const ACTION_TOTAL_BROKERAGE_AMOUNT =
  "invoice/ACTION_TOTAL_BROKERAGE_AMOUNT";
export const ACTION_TOTAL_EF_AMOUNT = "invoice/ACTION_TOTAL_EF_AMOUNT";
export const ACTION_TOTAL_EF_PERCENTAGE = "invoice/ACTION_TOTAL_EF_PERCENTAGE";
export const ACTION_TOTAL_EF_SHARING_AMOUNT =
  "invoice/ACTION_TOTAL_EF_SHARING_AMOUNT";
export const ACTION_TOTAL_GROSS = "invoice/ACTION_TOTAL_GROSS";
export const ACTION_TOTAL_PPH23 = "invoice/ACTION_TOTAL_PPH23";
export const ACTION_TOTAL_PERCENTAGE = "invoice/ACTION_TOTAL_PERCENTAGE";
export const ACTION_TOTAL_STAMP_DUTY = "invoice/ACTION_TOTAL_STAMP_DUTY";
export const ACTION_TOTAL_TOTAL_AMOUNT = "invoice/ACTION_TOTAL_TOTAL_AMOUNT";
export const ACTION_TOTAL_VAT = "invoice/ACTION_TOTAL_VAT";
export const ACTION_MULTI_INSURED = "invoice/ACTION_MULTI_INSURED";

// ------INSTALLMENT TAB------
export const ACTION_DOC_TYPE = "invoice/ACTION_DOC_TYPE";
export const ACTION_FILE_NAME = "invoice/ACTION_FILE_NAME";
export const ACTION_TO_CLIENT_INSTALLMENT_LIST =
  "invoice/ACTION_TO_CLIENT_INSTALLMENT_LIST";
export const ACTION_TO_CLIENT_TOTAL_AMOUNT =
  "invoice/ACTION_TO_CLIENT_TOTAL_AMOUNT";
export const ACTION_TO_CLIENT_TOTAL_PERCENTAGE =
  "invoice/ACTION_TO_CLIENT_TOTAL_PERCENTAGE";
export const ACTION_TO_CLIENT_TOTAL_ADMIN_COST =
  "invoice/ACTION_TO_CLIENT_TOTAL_ADMIN_COST";
export const ACTION_TO_CLIENT_TOTAL_STAMP_DUTY =
  "invoice/ACTION_TO_CLIENT_TOTAL_STAMP_DUTY";
export const ACTION_TO_CLIENT_TOTAL_VAT = "invoice/ACTION_TO_CLIENT_TOTAL_VAT";
export const ACTION_TO_CLIENT_TOTAL_GROSS =
  "invoice/ACTION_TO_CLIENT_TOTAL_GROSS";
export const ACTION_TO_CLIENT_TOTAL_TOTAL_AMOUNT =
  "invoice/ACTION_TO_CLIENT_TOTAL_TOTAL_AMOUNT";
export const ACTION_TO_UNDERWRITER_INSTALLMENT_LIST =
  "invoice/ACTION_TO_UNDERWRITER_INSTALLMENT_LIST";
export const ACTION_TO_UNDERWRITER_TOTAL_AMOUNT =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_AMOUNT";
export const ACTION_TO_UNDERWRITER_TOTAL_PERCENTAGE =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_PERCENTAGE";
export const ACTION_TO_UNDERWRITER_TOTAL_ADMIN_COST =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_ADMIN_COST";
export const ACTION_TO_UNDERWRITER_TOTAL_BROKERAGE =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_BROKERAGE";
export const ACTION_TO_UNDERWRITER_TOTAL_GROSS =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_GROSS";
export const ACTION_TO_UNDERWRITER_TOTAL_PPH =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_PPH";
export const ACTION_TO_UNDERWRITER_TOTAL_STAMP_DUTY =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_STAMP_DUTY";
export const ACTION_TO_UNDERWRITER_TOTAL_TOTAL_AMOUNT =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_TOTAL_AMOUNT";
export const ACTION_TO_UNDERWRITER_TOTAL_VAT =
  "invoice/ACTION_TO_UNDERWRITER_TOTAL_VAT";

// ------AGENT TAB------
export const ACTION_AGENT_ID = "invoice/ACTION_AGENT_ID";
export const ACTION_AGENT_NAME = "invoice/ACTION_AGENT_NAME";
export const ACTION_AMOUNT = "invoice/ACTION_AMOUNT";
export const ACTION_AGENT_LIST = "invoice/ACTION_AGENT_LIST";
export const ACTION_AGENT_TOTAL_AMOUNT = "invoice/ACTION_AGENT_TOTAL_AMOUNT";

// Getters
