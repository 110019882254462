<template>
  <v-card flat>
    <isttoolbar title="Customers" dark color="secondary" icon="mdi-cog-outline">
      <v-spacer></v-spacer>
      <v-btn outlined color="white" @click="goToAdd">
        <v-icon left>mdi-plus</v-icon>Add Customer
      </v-btn>
    </isttoolbar>
    <v-card>
      <v-card-title>
        Customers List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-btn
            @click="actionActivation(item, 'verifikasi')"
            icon
            v-if="!item.isActive && !item.isVerify && item.isRegistered"
          >
            <v-icon color="orange" small> mdi-account-alert </v-icon>
          </v-btn>
          <v-btn
            @click="actionActivation(item, 'nonActivated')"
            icon
            v-if="item.isActive && item.isVerify && item.isRegistered"
          >
            <v-icon color="green" small> mdi-check </v-icon>
          </v-btn>
          <v-btn
            @click="actionActivation(item, 'activated')"
            icon
            v-if="!item.isActive && item.isVerify && item.isRegistered"
          >
            <v-icon color="red" small> mdi-close </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="!item.isVerify"
                class="pointer"
                @click="clickVerify(item)"
              >
                <v-list-item-title>Verifikasi Customer</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.isActive || item.isVerify"
                class="pointer"
                @click="clickView(item)"
              >
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.isActive || item.isVerify"
                class="pointer"
                @click="clickEdit(item)"
              >
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.isActive || item.isVerify"
                class="pointer"
                @click="clickDelete(item)"
              >
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogDelete"
        persistent
        width="unset"
        min-width="290px"
      >
        <v-card min-width="290px">
          <v-card-title class="justify-center" style="word-break: inherit"
            >Are you sure want to Delete ?</v-card-title
          >
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              @click="
                deleteData();
                dialogDelete = false;
              "
              outlined
              >Yes</v-btn
            >
            <v-btn
              color="red"
              min-width="100px"
              @click="dialogDelete = false"
              outlined
              >No</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Deleted</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Deleted</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="resetDialog"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import {
  firebaseDB,
  customersCollection,
  paramsCollection,
} from "../../../firebase";
export default {
  components: {
    isttoolbar,
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "No", value: "index" },
      { text: "Customer Name", align: "start", value: "customerName" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Telp", value: "telp" },
      { text: "Address", value: "address" },
      { text: "Activated", value: "isActive" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    items: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogDelete: false,
    item: {},
    changeState: false,
    changeStateVerify: false,
  }),
  async created() {
    await this.getListData();
    // this.getListDataParam()
  },
  methods: {
    async actionActivation(payload, action) {
      if (action == "verifikasi") {
        await this.clickVerify(payload);
      } else {
        if (action == "activated") {
          this.changeState = true;
        }

        if (action == "nonActivated") {
          this.changeState = false;
        }

        const newData = {
          isActive: this.changeState,
          updatedAt: new Date().toISOString(),
          updatedBy: "Admin",
        };

        firebaseDB
          .doc(`/customers/${payload.customerId}`)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              throw new Error("Customer not found");
            }
            return doc.ref.update(newData);
          })
          .then(async () => {
            this.dialog.success = true;
            await this.getListData();
          })
          .catch((error) => {
            console.error("Error updating customer:", error.message);
            this.dialog.error = true;
            this.dialog.errorMessage = "Something went wrong";
          });
      }
    },
    async clickVerify(payload) {
      const newData = {
        isActive: true,
        isVerify: true,
        isDeleted: false,
        updatedAt: new Date().toISOString(),
        updatedBy: "Self",
      };

      firebaseDB
        .doc(`/customers/${payload.customerId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            throw new Error("Customer not found");
          }
          return doc.ref.update(newData);
        })
        .then(async () => {
          this.dialog.success = true;
          await this.getListData();
        })
        .catch((error) => {
          console.error("Error updating customer:", error.message);
          this.dialog.error = true;
          this.dialog.errorMessage = "Something went wrong";
        });
    },
    resetDialog() {
      this.dialog = {
        show: false,
        success: false,
        error: false,
        errorMessage: "",
      };
      this.item = {};
    },
    goToAdd() {
      this.$router.replace({ name: "customers-add" });
    },
    clickView(item) {
      this.$router.replace({
        name: "customers-edit",
        query: { customerId: item.customerId, statusPage: "view" },
      });
    },
    clickEdit(item) {
      this.$router.replace({
        name: "customers-edit",
        query: { customerId: item.customerId, statusPage: "edit" },
      });
    },
    clickDelete(item) {
      this.dialogDelete = true;
      this.item = item;
    },
    // soft delete
    // deleteData() {
    //   this.dialog.success = false;
    //   this.dialog.error = false;
    //   this.dialog.show = true;
    //   const newData = {
    //     isDeleted: true,
    //     deletedAt: new Date().toISOString(),
    //     deletedBy: "Admin",
    //   };
    //   firebaseDB
    //     .doc(`/customers/${this.item.customerId}`)
    //     .get()
    //     .then((doc) => {
    //       if (!doc.exists) {
    //         this.dialog.error = true;
    //         this.dialog.errorMessage = "Customer not found";
    //       }
    //       return doc.ref.update(newData);
    //     })
    //     .then(() => {
    //       this.dialog.success = true;
    //       this.getListData();
    //     })
    //     .catch(() => {
    //       this.dialog.error = true;
    //       this.dialog.errorMessage = "Somthing Went Wrong";
    //     });
    // },

    // HARD DELETE
    deleteData() {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;

      firebaseDB
        .doc(`/customers/${this.item.customerId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Customer not found";
            throw new Error("Customer not found");
          }
          return doc.ref.delete(); // <== Hapus permanen
        })
        .then(() => {
          this.dialog.success = true;
          this.getListData();
        })
        .catch((error) => {
          this.dialog.error = true;
          this.dialog.errorMessage = error.message || "Something Went Wrong";
        });
    },

    async getListData() {
      this.loading = true;
      customersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              customerId: doc.id,
              customerName: doc.data().customerName,
              address: doc.data().address,
              // npwp: doc.data().npwp,
              email: doc.data().email,
              telp: doc.data().telp,
              phone: doc.data().phone,
              isActive: doc.data().isActive,
              isVerify: doc.data().isVerify,
              isRegistered: doc.data().isRegistered,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          });
          this.loading = false;

          this.items = newData.sort(function (a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getListDataParam() {
      paramsCollection
        .get()
        .then((data) => {
          data.forEach((doc) => {
            let obj = {
              paramsId: doc.id,
              paramsGroup: doc.data().paramsGroup,
              params: doc.data().params,
              values: doc.data().values,
              status: doc.data().status,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            };
            this.editData(obj);
          });
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },
    async editData(item) {
      let value = JSON.stringify(item.values);
      const newData = {
        values: value,
      };
      console.debug(newData);
      await firebaseDB
        .doc(`/params/${item.paramsId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            console.debug("data tidak ada");
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          console.debug("data suskes");
        })
        .catch(() => {
          console.debug("data error");
        });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.pointer:hover {
  background: #eeeeee;
}
</style>
