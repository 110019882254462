<template>
  <v-card flat>
    <!-- Alert Message -->
    <v-alert
      v-if="errorMessage"
      type="warning"
      variant="outlined"
      border="left"
      icon="mdi-alert-circle-outline"
    >
      {{ errorMessage }}
    </v-alert>

    <!-- Top Toolbar -->
    <isttoolbar
      title="Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>

    <!-- Title Section -->
    <isttoolbar
      title="Add New Product Parameter"
      class="mb-6"
      dark
      color="primary"
    />

    <!-- Form Input Fields -->
    <v-form>
      <v-row class="px-2" dense>
        <!-- Product Models Selection -->
        <v-col cols="12" md="6">
          <span class="title"
            >Product Models<span class="error--text" v-if="!selectedValue"
              >*</span
            ></span
          >
          <v-menu v-model="menuOpen" :close-on-content-click="false">
            <template v-slot:activator="{ on, props }">
              <v-btn
                v-on="on"
                v-bind="props"
                block
                outlined
                color="primary"
                class="text-none mt-1"
              >
                {{ selectedValue || "Select an item" }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-if="!selectedValue" class="error--text mt-1"
            >Product Model harus dipilih!</span
          >
        </v-col>

        <!-- Merk Product with Combobox-->
        <v-col cols="12" md="6">
          <span class="title"
            >Merk Product<span
              class="error--text"
              v-if="param.paramsGroup.length === 0"
              >*</span
            ></span
          >
          <v-combobox
            :items="
              selectedValue === 'Gas'
                ? itemsOptionProductTypeGas
                : itemsOptionProductTypeUTI
            "
            item-text="orderProductType"
            item-value="orderProductType"
            v-model="param.paramsGroup"
            outlined
            dense
            clearable
            hide-details="auto"
            class="mt-1"
          />
          <span v-if="errorPG" class="error--text mt-1"
            >Field Tidak Boleh Kosong!</span
          >
        </v-col>

        <!-- Merk Product with Textfield-->
        <!-- <v-col cols="12" md="6">
          <span class="title"
            >Merk Product<span
              class="error--text"
              v-if="param.paramsGroup.length === 0"
              >*</span
            ></span
          >
          <v-text-field
            label="Merk Product"
            outlined
            dense
            v-model="param.paramsGroup"
            @input="handleInputPG"
            hide-details="auto"
            class="mt-1"
          />
          <span v-if="errorPG" class="error--text mt-1"
            >Field Tidak Boleh Kosong!</span
          >
        </v-col> -->

        <!-- Type Product -->
        <v-col cols="12" md="6">
          <span class="title"
            >Type Product<span
              class="error--text"
              v-if="param.params.length === 0"
              >*</span
            ></span
          >
          <v-text-field
            outlined
            dense
            v-model="param.params"
            @input="handleInputPS"
            hide-details="auto"
            class="mt-1"
          />
          <span v-if="errorPS" class="error--text mt-1"
            >Field Tidak Boleh Kosong!</span
          >
        </v-col>
      </v-row>

      <!-- Dynamic Section Based on Selected Product -->
      <v-container fluid>
        <div v-if="selectedValue">
          <v-row>
            <!-- GAS TABLE -->
            <v-col cols="12" md="6" v-show="selectedValue === 'Gas'">
              <v-data-table
                class="elevation-1 mt-4"
                :headers="headersModels"
                :items="modelList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Model Tabung List</v-toolbar-title>
                    <v-spacer />
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        outlined
                        min-width="100px"
                        @click="openAddModal($event)"
                      >
                        Add Gas
                      </v-btn>
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item, 'model')"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteItem(item, 'model')"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>

            <!-- UTI METER OPTIONS -->
            <v-col cols="12" md="12" v-show="selectedValue === 'UTI Meter'">
              <v-divider class="mb-4"></v-divider>

              <!-- Tape Length -->
              <v-row class="mb-4">
                <v-col cols="12">
                  <span class="title"
                    >Tape Length<span
                      class="error--text"
                      v-if="param.tapeLength.length === 0"
                    >
                      *</span
                    ></span
                  >
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col
                      v-for="(option, index) in [
                        '10 M',
                        '15 M',
                        '20 M',
                        '30 M',
                        '35 M',
                        '40 M',
                      ]"
                      :key="index"
                      cols="6"
                      sm="4"
                    >
                      <v-checkbox
                        v-model="param.tapeLength"
                        :label="option"
                        :value="option"
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <span v-if="errorUTITapeLength" class="error--text"
                    >Field Tape Length Tidak Boleh Kosong!</span
                  >
                </v-col>
              </v-row>

              <!-- Valve Connector -->
              <v-row>
                <v-col cols="12">
                  <span class="title"
                    >Valve Connector<span
                      class="error--text"
                      v-if="param.valveConnector.length === 0"
                    >
                      *</span
                    ></span
                  >
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col
                      v-for="(option, index) in [
                        'PF 2-1/4” TAP',
                        'F/C50 Valve',
                        'Q2 Valve',
                      ]"
                      :key="index"
                      cols="6"
                      sm="4"
                    >
                      <v-checkbox
                        :label="option"
                        :value="option"
                        :input-value="param.valveConnector === option"
                        @change="param.valveConnector = option"
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <span v-if="errorUTIvalveConnector" class="error--text"
                    >Field Valve Connector Tidak Boleh Kosong!</span
                  >
                </v-col>
              </v-row>

              <v-divider class="mb-4 mt-4"></v-divider>
            </v-col>

            <v-col cols="12" md="6" v-show="selectedValue === 'UTI Meter'">
              <v-data-table
                class="elevation-1 mt-4"
                :headers="headersApparatus"
                :items="apparatusList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Apparatus List</v-toolbar-title>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      outlined
                      min-width="100px"
                      @click.stop="dialogAddApparatus = true"
                    >
                      Add Apparatus
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editApparatus(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteApparatus(item)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>

            <!-- INSPECTION TABLE -->
            <v-col cols="12" md="6">
              <v-data-table
                class="elevation-1 mt-4"
                :headers="headersInspection"
                :items="inspectionList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Inspection List <span style="font-size: 9px; color: red; font-style: italic;">*optional</span></v-toolbar-title>
                    <v-spacer />
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        outlined
                        min-width="100px"
                        @click="openAddModal($event)"
                      >
                        Add Inspection
                      </v-btn>
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'inspection')"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteItem(item, 'inspection')"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <!-- CALIBRATION AND CERTIFICATE -->
          <v-row>
            <v-col cols="12" md="6" v-show="selectedValue === 'Gas'">
              <v-data-table
                class="elevation-1 mt-4"
                :headers="headersCalibration"
                :items="calibrationList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Calibration List</v-toolbar-title>
                    <v-spacer />
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        outlined
                        min-width="100px"
                        @click="openAddModal($event)"
                      >
                        Add Calibration
                      </v-btn>
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'calibration')"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteItem(item, 'calibration')"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="6" v-show="selectedValue === 'UTI Meter'">
              <v-data-table
                class="elevation-1 mt-4"
                :headers="headersCalibrationUti"
                :items="calibrationList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Calibration List</v-toolbar-title>
                    <v-spacer />
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        outlined
                        min-width="100px"
                        @click="openAddModal($event)"
                      >
                        Add Calibration
                      </v-btn>
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.preCalibration`]="{ item }">
                  <p>
                    <strong>Pre Calibration</strong><br />{{
                      item.preCalibration
                    }}
                  </p>
                </template>
                <template v-slot:[`item.postCalibration`]="{ item }">
                  <p>
                    <strong>Post Calibration</strong><br />{{
                      item.postCalibration
                    }}
                  </p>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'calibration')"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteItem(item, 'calibration')"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>

            <!-- CERTIFICATE TABLE -->
            <v-col cols="12" md="6">
              <v-data-table
                class="elevation-1 mt-4"
                :headers="headersCertificate"
                :items="certificateList"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Certificate List<span style="font-size: 9px; color: red; font-style: italic;">*optional</span></v-toolbar-title>
                    <v-spacer />
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        outlined
                        min-width="100px"
                        @click="openAddModal($event)"
                      >
                        Add Certificate
                      </v-btn>
                    </v-card-actions>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, 'certificate')"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small @click="deleteItem(item, 'certificate')"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>

    <!-- Dialog Success/Error (pop-up) -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title class="justify-center">
            {{
              dialog.success
                ? "Your Data has been Saved"
                : "Your Data Failed to Saved"
            }}
          </v-card-title>
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              outlined
              min-width="100px"
              @click="dialog.success ? back() : (dialog.show = false)"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialog Components (Add/Edit) -->
    <addApparatusDialog
  v-if="dialogAddApparatus"
  :dialog.sync="dialogAddApparatus"
  :data="apparatusList"
  @save="saveItem"
  @cancel="dialogAddApparatus = false"
/>

    <addProductDialog
      v-if="dialogAddProduct"
      :dialog="dialogAddProduct"
      :model="selectedValue"
      :data="modelList"
      @save="saveItem"
      @cancel="closeItem"
    />
    <addCertificateDialog
      v-if="dialogAddCertificate"
      :dialog="dialogAddCertificate"
      :data="certificateList"
      @save="saveItem"
      @cancel="closeItem"
    />
    <addInspectionNameDialog
      v-if="dialogAddInspectionName"
      :dialog="dialogAddInspectionName"
      :data="inspectionList"
      @save="saveItem"
      @cancel="closeItem"
    />
    <addCalibration
      v-if="dialogAddCalibration"
      :dialog="dialogAddCalibration"
      :data="calibrationList"
      :selectedValue="selectedValue"
      @save="saveItem"
      @cancel="closeItem"
    />

    <editApparatusDialog
      v-if="dialogEditApparatus"
      :dialog="dialogEditApparatus"
      :data="editedItem"
      @save="saveEditedItem"
      @cancel="closeEditItem"
    />

    <editProductDialog
      v-if="dialogEdit"
      :dialog="dialogEdit"
      :model="selectedValue"
      :data="editedItem"
      @save="saveEditedItem"
      @cancel="closeEditItem"
    />
    <editInspectionDialog
      v-if="dialogEditInspection"
      :dialog="dialogEditInspection"
      :model="selectedValue"
      :data="editedItem"
      @save="saveEditedItem"
      @cancel="closeEditItem"
    />
    <editCalibrationDialog
      v-if="dialogEditCalibration"
      :dialog="dialogEditCalibration"
      :model="selectedValue"
      :data="editedItem"
      @save="saveEditedItem"
      @cancel="closeEditItem"
    />
    <editCertificationDialog
      v-if="dialogEditCertificate"
      :dialog="dialogEditCertificate"
      :model="selectedValue"
      :data="editedItem"
      @save="saveEditedItem"
      @cancel="closeEditItem"
    />
  </v-card>
</template>

<script>
import addApparatusDialog from "@/components/dialog/NewParameter/addApparatusDialog.vue";
import editApparatusDialog from "../../../components/dialog/NewParameter/editApparatusDialog.vue";
import addProductDialog from "@/components/dialog/NewParameter/addProductDialog";
import addCertificateDialog from "@/components/dialog/NewParameter/addCertificateDialog";
import addInspectionNameDialog from "@/components/dialog/NewParameter/addInspectionNameDialog";
import addCalibration from "@/components/dialog/NewParameter/addCalibration";
import editProductDialog from "@/components/dialog/NewParameter/editProductDialog";
import editInspectionDialog from "@/components/dialog/NewParameter/editInspectionDialog.vue";
import editCalibrationDialog from "@/components/dialog/NewParameter/editCalibrationDialog.vue";
import editCertificationDialog from "../../../components/dialog/NewParameter/editCertificationDialog.vue";
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { paramsCollection } from "../../../firebase";

export default {
  components: {
    isttoolbar,
    addApparatusDialog,
    editApparatusDialog,
    addProductDialog,
    addCertificateDialog,
    addInspectionNameDialog,
    addCalibration,
    editProductDialog,
    editInspectionDialog,
    editCalibrationDialog,
    editCertificationDialog,
  },
  data: () => ({
    errorPG: false,
    errorPS: false,
    errorUTITapeLength: false,
    errorUTIvalveConnector: false,
    errorMessage: null,
    menuOpen: false,
    headersModels: [
      { text: "Type", align: "start", value: "type" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersInspection: [
      { text: "Inspection Name", align: "start", value: "reportType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCertificate: [
      { text: "Certificate Name", align: "start", value: "certificateType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCalibrationUti: [
      { text: "Deskripsi", align: "start", value: "deskripsi" },
      { text: "Reference Thermometer", value: "preCalibration" },
      { text: "", value: "postCalibration" },
      { text: "Maximum Error Allowed", value: "maximumErrorAllowed" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCalibration: [
      { text: "No Tube", align: "start", value: "noTube" },
      { text: "Gas Type", value: "gasType" },
      { text: "Concentration", value: "concentration" },
      { text: "Unit", value: "unit" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersApparatus: [
      { text: "Apparatus", value: "apparatus" },
      { text: "Manufacture / Model", value: "model" },
      { text: "Actions", value: "actions", sortable: false, width: "100px" },
    ],
    itemsOptionProductTypeGas: [],
    itemsOptionProductTypeUTI: [],
    apparatusList: [],
    dialogAddApparatus: false,
    dialogEditApparatus: false,
    editedApparatus: { id: null, apparatus: "", model: "" },
    selectEditedItem: "",
    tapeLength: "",
    valveConnector: "",
    editedItem: [],
    modelList: [],
    inspectionList: [],
    calibrationList: [],
    certificateList: [],
    items: ["Gas", "UTI Meter"],
    selectedValue: null,
    param: {
      paramsGroup: "",
      params: "",
      values: "",
      tapeLength: "",
      valveConnector: [],
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogAddProduct: false,
    dialogAddCertificate: false,
    dialogAddInspectionName: false,
    dialogAddCalibration: false,
    dialogEdit: false,
    dialogEditCalibration: false,
    dialogEditInspection: false,
    dialogEditCertificate: false,
    dialogDelete: false,
  }),
  computed: {
    form() {
      return {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: this.param.values,
        tapeLength: this.param.tapeLength,
        valveConnector: this.param.valveConnector,
      };
    },
  },
  created() {
    this.checkInputLocal();
    this.getListDataProductTypeOption();
  },
  methods: {
    getListDataProductTypeOption() {
      this.loading = true;
      paramsCollection
        .where("paramsGroup", "==", "Orders Product Type")
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderProductType: doc.data().values,
            });
          });
          this.loading = false;
          for (let i = 0; i < newData.length; i++) {
            newData[i].orderProductType = JSON.parse(
              newData[i].orderProductType.replace(/\\"/g, '"')
            );
          }
          this.itemsOptionProductTypeUTI = newData[0].orderProductType;
          this.itemsOptionProductTypeGas = newData[1].orderProductType;
          console.log(this.itemsOptionProductTypeGas, "<< GAS");
          console.log(this.itemsOptionProductTypeUTI, "<< UTI");
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },
    editApparatus(item) {
      this.editedItem = { ...item };
      this.selectEditedItem = "apparatus";
      this.dialogEditApparatus = true;
    },

    deleteApparatus(item) {
      const index = this.apparatusList.findIndex((el) => el.id === item.id);
      if (index !== -1) this.apparatusList.splice(index, 1);

      let dataBefore = JSON.parse(localStorage.getItem("userInput")) || {};
      dataBefore.apparatusList = this.apparatusList;
      localStorage.setItem("userInput", JSON.stringify(dataBefore));
    },
    handleInputPG(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);

      if (value.length > 0) {
        this.formHasErrors = false;
        this.errorPG = false;
        newData.calibrationName = value;
      } else {
        this.formHasErrors = true;
        this.errorPG = true;
        newData.calibrationName = value;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    handleInputPS(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);
      if (value.length > 0) {
        this.formHasErrors = false;
        this.errorPS = false;
        newData.inspectionName = value;
      } else {
        newData.inspectionName = value;
        this.formHasErrors = true;
        this.errorPS = true;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    handleInputTL(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);
      if (this.selectedValue == "UTI Meter" && value.length == 0) {
        // tambahkan keterangan error
        this.formHasErrors = true;
        this.errorUTITapeLength = true;
        newData.tapeLength = value;
      } else {
        this.errorUTITapeLength = false;
        this.formHasErrors = false;
        newData.tapeLength = value;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    handleInputVC(value) {
      let checkLocalStorage = localStorage.getItem("userInput");
      let newData = JSON.parse(checkLocalStorage);
      if (this.selectedValue == "UTI Meter" && value.length == 0) {
        // tambahkan keterangan error
        this.formHasErrors = true;
        this.errorUTIvalveConnector = true;
        newData.valveConnector = value;
      } else {
        this.errorUTIvalveConnector = false;
        this.formHasErrors = false;
        newData.valveConnector = value;
      }
      localStorage.setItem("userInput", JSON.stringify(newData));
    },
    selectItem(item) {
      this.selectedValue = item;
      this.menuOpen = false;
      let perviousCalibration = JSON.parse(
        localStorage.getItem("userInput")
      ).listCalibration;

      let requiredUTIProps = [
        "deskripsi",
        "preCalibration",
        "postCalibration",
        "maximumErrorAllowed",
      ];
      let requiredGASProps = ["noTube", "gasType", "concentration", "unit"];

      // Filter array berdasarkan properti yang dimiliki
      if (perviousCalibration && item == "UTI Meter") {
        this.calibrationList = perviousCalibration.filter((item) => {
          return requiredUTIProps.every((prop) =>
            Object.prototype.hasOwnProperty.call(item, prop)
          );
        });
      } else if (perviousCalibration && item == "Gas") {
        this.calibrationList = perviousCalibration.filter((item) => {
          return requiredGASProps.every((prop) =>
            Object.prototype.hasOwnProperty.call(item, prop)
          );
        });
      }

      localStorage.setItem("selectedItemValues", this.selectedValue);
    },
    openAddModal(event) {
      const label = event?.target?.textContent?.trim();

      if (label.includes("Gas") || label.includes("UTI Meter")) {
        this.dialogAddProduct = true;
      } else if (label.includes("Inspection")) {
        this.dialogAddInspectionName = true;
      } else if (label.includes("Certificate")) {
        this.dialogAddCertificate = true;
      } else if (label.includes("Calibration")) {
        this.dialogAddCalibration = true;
      }
    },
    closeItem() {
      this.dialogAddProduct = false;
      this.dialogAddInspectionName = false;
      this.dialogAddCertificate = false;
      this.dialogAddCalibration = false;
    },
    // check local storage untuk isi values jika di refresh
    checkInputLocal() {
      let previousData = JSON.parse(localStorage.getItem("userInput"));
      let perviousSelectedItem = localStorage.getItem("selectedItemValues");
      if (perviousSelectedItem) {
        this.selectedValue = perviousSelectedItem;
      }
      if (previousData && typeof previousData == "object") {
        if (previousData.apparatusList) {
          this.apparatusList = previousData.apparatusList;
        }

        if (previousData.inspectionName) {
          this.param.params = previousData.inspectionName;
        }
        if (previousData.calibrationName) {
          this.param.paramsGroup = previousData.calibrationName;
        }
        if (previousData.listGas) {
          this.modelList = previousData.listGas;
        }
        if (previousData.listCalibration) {
          let requiredUTIProps = [
            "deskripsi",
            "preCalibration",
            "postCalibration",
            "maximumErrorAllowed",
          ];
          let requiredGASProps = ["noTube", "gasType", "concentration", "unit"];

          if (this.selectedValue == "Gas") {
            this.calibrationList = previousData.listCalibration.filter(
              (item) => {
                return requiredGASProps.every((prop) =>
                  Object.prototype.hasOwnProperty.call(item, prop)
                );
              }
            );
          } else if (this.selectedValue == "UTI Meter") {
            this.calibrationList = previousData.listCalibration.filter(
              (item) => {
                return requiredUTIProps.every((prop) =>
                  Object.prototype.hasOwnProperty.call(item, prop)
                );
              }
            );
          }
        }
        if (previousData.certificate) {
          this.certificateList = previousData.certificate;
        }
        if (previousData.inspectionReport) {
          this.inspectionList = previousData.inspectionReport;
        }
        if (previousData.tapeLength) {
          this.tapeLength = previousData.tapeLength;
        }
        if (previousData.valveConnector) {
          this.valveConnector = previousData.valveConnector;
        }
      } else {
        let obj = {};
        localStorage.setItem("userInput", JSON.stringify(obj));
      }
    },
    // Edit Item Order
    // PR NANTI UNTUK PENYESUAIAN CALIBRATION
    editItem(item, str) {
      if (str == "model") {
        this.editedItem = {
          id: item.id,
          type: item.type,
        };
        this.dialogEdit = true;
      }
      if (str == "inspection") {
        this.editedItem = {
          id: item.id,
          reportType: item.reportType,
        };
        this.dialogEditInspection = true;
      }
      if (str == "calibration") {
        // PR NANTI
        if (this.selectedValue == "Gas") {
          this.editedItem = {
            id: item.id,
            noTube: item.noTube,
            gasType: item.gasType,
            concentration: item.concentration,
            unit: item.unit,
          };
        } else if (this.selectedValue == "UTI Meter") {
          this.editedItem = {
            id: item.id,
            deskripsi: item.deskripsi,
            maximumErrorAllowed: item.maximumErrorAllowed,
            preCalibration: item.preCalibration,
            postCalibration: item.postCalibration,
          };
        }
        this.dialogEditCalibration = true;
      }
      if (str == "certificate") {
        this.editedItem = {
          id: item.id,
          certificateType: item.certificateType,
        };
        this.dialogEditCertificate = true;
      }

      this.selectEditedItem = str;
    },
    saveEditedItem() {
      let index = 0;

      let previousData = localStorage.getItem("userInput");

      // let index = this.modelList.findIndex((el) => el.id == this.editedItem.id);
      if (this.selectEditedItem === "model") {
        index = this.modelList.findIndex((el) => el.id == this.editedItem.id);
        if (this.selectedValue === "Gas") {
          this.modelList.splice(index, 1, this.editedItem);
          this.editedItem = {
            id: null,
            type: "",
          };
        }
        this.dialogEdit = false;
      }

      if (this.selectEditedItem == "apparatus") {
        index = this.apparatusList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.apparatusList.splice(index, 1, this.editedItem);
        this.editedItem = { id: null, apparatus: "", model: "" };
        this.dialogEditApparatus = false;
      }

      if (this.selectEditedItem === "inspection") {
        index = this.inspectionList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.inspectionList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          reportType: "",
        };
        this.dialogEditInspection = false;
      }

      if (this.selectEditedItem == "calibration") {
        index = this.calibrationList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.calibrationList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          noTube: "",
          gasType: "",
          concentration: "",
          unit: "",
          deskripsi: "",
          maximumErrorAllowed: "",
          preCalibration: "",
          postCalibration: "",
          lot: "",
          cyl: "",
        };
        this.dialogEditCalibration = false;
      }

      if (this.selectEditedItem == "certificate") {
        index = this.certificateList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.certificateList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          certificateType: "",
        };
        this.dialogEditCertificate = false;
      }

      if (previousData) {
        let dataBefore = JSON.parse(previousData);

        dataBefore.listGas = this.modelList;
        dataBefore.totalGas = this.modelList.length;
        dataBefore.listCalibration = this.calibrationList;
        dataBefore.totalCalibration = this.calibrationList.length;
        dataBefore.totalCertificate = this.certificateList.length;
        dataBefore.certificate = this.certificateList;
        dataBefore.inspectionReport = this.inspectionList;
        dataBefore.totalInspection = this.inspectionList.length;

        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }
    },
    closeEditItem() {
      this.dialogEdit = false;
      this.dialogEditCalibration = false;
      this.dialogEditCertificate = false;
      this.dialogEditInspection = false;
      // this.editedItem = {
      //   id: null,
      //   type: "",
      // };
    },
    // Delete Item Order
    async deleteItem(item, str) {
      let previousData = localStorage.getItem("userInput");
      if (str == "model")
        this.modelList = this.modelList.filter((el) => el.id !== item.id);
      if (str == "inspection")
        this.inspectionList = this.inspectionList.filter(
          (el) => el.id !== item.id
        );
      if (str == "calibration")
        this.calibrationList = this.calibrationList.filter(
          (el) => el.id !== item.id
        );
      if (str == "certificate")
        this.certificateList = this.certificateList.filter(
          (el) => el.id !== item.id
        );

      if (previousData) {
        let dataBefore = JSON.parse(previousData);

        dataBefore.listGas = this.modelList;
        dataBefore.totalGas = this.modelList.length;
        dataBefore.listCalibration = this.calibrationList;
        dataBefore.totalCalibration = this.calibrationList.length;
        dataBefore.certificate = this.certificateList;
        dataBefore.totalCertificate = this.certificateList.length;
        dataBefore.inspectionReport = this.inspectionList;
        dataBefore.totalInspection = this.inspectionList.length;

        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }
    },
    // Save Item Order
    async saveItem() {
      this.dialogAddProduct = false;
      this.dialogAddInspectionName = false;
      this.dialogAddCertificate = false;
      this.dialogAddCalibration = false;

      let obj = {};

      // Certificate always included (even if empty)
      obj.certificate = this.certificateList;
      obj.totalCertificate = this.certificateList.length;

      if (this.modelList.length > 0) {
        //  INI BELUM TERMASUK UTI NANTI KALO ADA HARUS DIRUBAH !
        if (this.selectedValue === "Gas") {
          obj.listGas = this.modelList;
          obj.totalGas = this.modelList.length;
        }
      }

      if (this.selectedValue === "UTI Meter") {
        if (this.apparatusList.length > 0) {
          obj.apparatusList = this.apparatusList;
          obj.totalApparatus = this.apparatusList.length;
        }
      }

      if (this.calibrationList.length > 0) {
        obj.listCalibration = this.calibrationList;
        obj.totalCalibration = this.calibrationList.length;
      }

      if (this.inspectionList.length > 0) {
        obj.inspectionReport = this.inspectionList;
        obj.totalInspection = this.inspectionList.length;
      }

      if (this.param.params !== "") {
        obj.orderProductSeries = this.param.params;
        obj.inspectionName = this.param.params;
      }

      if (this.param.paramsGroup !== "") {
        obj.calibrationName = this.param.paramsGroup;
      }

      if (this.selectedValue == "UTI Meter" && this.tapeLength !== 0) {
        obj.tapeLength = this.tapeLength;
        obj.tapeLength = this.param.tapeLength;
      }

      if (this.selectedValue == "UTI Meter" && this.valveConnector !== 0) {
        obj.valveConnector = this.valveConnector;
        obj.valveConnector = this.param.valveConnector;
      }

      localStorage.setItem("userInput", JSON.stringify(obj));

      // Object.keys(this.form).forEach((f) => {
      //   if (
      //     f === "orderProduct" ||
      //     f === "orderProductType" ||
      //     f === "orderProductSeries" ||
      //     f === "orderProductSN" ||
      //     f === "notes"
      //   ) {
      //     if (!this.form[f]) this.formHasErrors = true;
      //     this.$refs[f].validate(true);
      //   }
      // });
      // if (!this.formHasErrors) {
      //   if (this.editedIndex > -1) {
      //     Object.assign(this.items[this.editedIndex], this.editedItem);
      //   } else {
      //     this.items.push(this.editedItem);
      //   }
      //   this.close();
      // } else {
      //   this.formHasErrors = false;
      // }
    },
    back() {
      localStorage.removeItem("userInput");
      localStorage.removeItem("selectedItemValues");

      this.$router.replace({
        name: "parameter-configuration-list",
      });
    },
    doubleCheck() {
      if (!this.param.paramsGroup) {
        this.errorPG = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Params Group / Brand Product kosong!";
      }
      if (!this.param.params) {
        this.errorPS = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Params / Product Series kosong!";
      }
      if (this.selectedValue == "UTI Meter" && !this.param.tapeLength) {
        this.errorUTITapeLength = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Tape Length kosong!";
      }
      if (this.selectedValue == "UTI Meter" && !this.param.valveConnector) {
        this.errorUTIvalveConnector = true;
        this.formHasErrors = true;
        this.dialog.error = true;
        this.dialog.show = true;
        this.dialog.success = false;
        this.dialog.errorMessage = "Field Valve Connector kosong!";
      }
    },
    async save() {
      this.doubleCheck();
      // this.errorMessage = "Save Function belum dijalankan pada branch 'NewParameter_Featured' karena proses ini masih berlangsung, mohon cek dan sesuaikan dahulu param values yang akan disimpan. Untuk membuat setelan awal silahkan pilih branch development atau rizky_branch pada Git lalu mulai kembali"

      let dataInLocalStorage = JSON.parse(localStorage.getItem("userInput"));

      this.param.paramsGroup = dataInLocalStorage.calibrationName;
      this.param.params = dataInLocalStorage.inspectionName;

      // Object.keys(this.form).forEach((f) => {
      //   if (!this.form[f]) this.formHasErrors = true;
      //   this.$refs[f].validate(true);
      // });
      if (!this.formHasErrors) {
        this.param.values = JSON.stringify(dataInLocalStorage);
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      let original = this.param.values;
      let stringify = JSON.stringify(original);
      let remove = stringify.replace(/\\n/g, "");
      const newData = {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        valveConnector: this.param.valveConnector,
        tapeLength: this.param.tapeLength,
        values: remove,
        status: true,
        isDeleted: false,
        createdAt: new Date().toISOString(),
        createdBy: "Admin",
        updatedBy: "",
        updatedAt: "",
        deletedAt: "",
        deletedBy: "",
      };
      console.log(newData, "<< cek");
      paramsCollection
        .add(newData)
        .then(() => {
          this.dialog.success = true;
          localStorage.removeItem("userInput");
          localStorage.removeItem("selectedItemValues");
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },
  },
};
</script>
