<template>
  <v-card flat>
    <isttoolbar
      title="Customers"
      dark
      color="secondary"
      icon="mdi-folder-account-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      title="New Customer"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Customer Name
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Customer Name"
            ref="customerName"
            v-model="param.customerName"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Email
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Email"
            ref="email"
            v-model="param.email"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Phone
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Phone"
            ref="phone"
            v-model="param.phone"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Telp
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Telp"
            ref="telp"
            v-model="param.telp"
            solo
            flat
            outlined
            dense
            class="ma-0"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            NPWP
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="NPWP"
            ref="npwp"
            v-model="param.npwp"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row> -->
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Address
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-textarea
            hide-details="auto"
            v-model="param.address"
            solo
            flat
            outlined
            dense
            ref="address"
            name="input-7-4"
            label="Address"
            :rows="6"
            :rules="[rules.required]"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { customersCollection } from "../../../firebase";

export default {
  components: {
    isttoolbar,
  },
  props: {
    source: String,
  },

  data: () => ({
    param: {
      customerName: "",
      email: "",
      phone: "",
      telp: "",
      // npwp: "",
      address: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
  }),
  computed: {
    form() {
      return {
        customerName: this.param.customerName,
        email: this.param.email,
        phone: this.param.phone,
        telp: this.param.telp,
        // npwp: this.param.npwp,
        address: this.param.address,
      };
    },
  },
  created() {},
  methods: {
    back() {
      this.$router.replace({
        name: "customers-list",
      });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (f !== "telp" && !this.form[f]) this.formHasErrors = true; // ✅ Abaikan validasi "telp"
        if (this.$refs[f]) this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      const newData = {
        customerName: this.param.customerName,
        address: this.param.address,
        // npwp: this.param.npwp,
        email: this.param.email,
        telp: this.param.telp,
        phone: this.param.phone,
        isVerify: true,
        isActive: true,
        isRegistered: true,
        isDeleted: false,
        createdAt: new Date().toISOString(),
        createdBy: "Admin",
        updatedBy: "",
        updatedAt: "",
        deletedAt: "",
        deletedBy: "",
      };
      customersCollection
        .add(newData)
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Somthing Went Wrong";
        });
    },
  },
};
</script>
