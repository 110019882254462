<template>
  <v-card flat>
    <isttoolbar
      title="Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer />
      <v-btn
        v-show="$route.query.statusPage === 'view'"
        @click="edit"
        outlined
        class="mx-1"
        min-width="100px"
        >Edit</v-btn
      >
      <v-btn
        v-show="$route.query.statusPage !== 'view'"
        @click="save"
        outlined
        class="mx-1"
        min-width="100px"
        >Save</v-btn
      >
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      :title="
        $route.query.statusPage === 'view' ? 'View Parameter' : 'Edit Parameter'
      "
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <!-- View page -->
    <v-form class="mb-8" :readonly="$route.query.statusPage === 'view'">
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Merk Product
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <!-- Merk Product with Textfields -->
          <!-- <v-text-field
            hide-details="auto"
            label="Params Group"
            ref="paramsGroup"
            v-model="param.paramsGroup"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field> -->

          <!-- Merk Product with Combobox -->
          <v-combobox
            :items="
              selectedValue === 'Gas'
                ? itemsOptionProductTypeGas
                : itemsOptionProductTypeUTI
            "
            item-text="orderProductType"
            item-value="orderProductType"
            v-model="param.paramsGroup"
            outlined
            dense
            clearable
            hide-details="auto"
            class="mt-1"
            :disabled="$route.query.statusPage === 'view'"
          />
        </v-col>
      </v-row>

      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Params
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Params"
            ref="params"
            v-model="param.params"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Table Values Tabung List -->
      <v-row align="center" class="">
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersListGas"
            :items="itemsGas"
            v-if="selectedValue === 'Gas'"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Tabung List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer />
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn
                  small
                  color="primary"
                  dark
                  @click="openAddDialog('listGas')"
                  v-show="$route.query.statusPage !== 'view'"
                >
                  Add
                </v-btn>
              </v-toolbar>
            </template>

            <!-- Nomor urut -->
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
            </template>

            <!-- Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item, 'model')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item, 'model')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-delete
              </v-icon>
            </template>

            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row v-if="selectedValue === 'UTI Meter'">
        <v-col cols="12" md="6">
          <span class="title">Tape Length</span>
          <v-row dense>
            <v-col
              v-for="(option, index) in tapeLengthOptions"
              :key="index"
              cols="6"
              sm="4"
            >
              <v-checkbox
                v-if="$route.query.statusPage === 'edit'"
                v-model="selectedTapeLength"
                :label="option"
                :value="option"
                hide-details
                dense
              />
              <v-checkbox
                v-else
                :label="option"
                :input-value="selectedTapeLength.includes(option)"
                hide-details
                dense
                readonly
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <span class="title">Valve Connector</span>
          <v-row dense>
            <v-col
              v-for="(option, index) in valveConnectorOptions"
              :key="index"
              cols="6"
              sm="4"
            >
              <v-checkbox
                v-if="$route.query.statusPage === 'edit'"
                v-model="selectedValveConnector"
                :label="option"
                :value="option"
                hide-details
                dense
              />
              <v-checkbox
                v-else
                :label="option"
                :input-value="selectedValveConnector.includes(option)"
                hide-details
                dense
                readonly
              />
            </v-col>
          </v-row>
        </v-col>

        <!-- Table Values Apparatus -->
        <v-col col="12" md="12">
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersApparatus"
            :items="apparatusList"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Apparatus List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer />
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn
                  small
                  color="primary"
                  dark
                  @click="openAddDialog('apparatus')"
                  v-show="$route.query.statusPage !== 'view'"
                >
                  Add
                </v-btn>
              </v-toolbar>
            </template>

            <!-- Nomor urut -->
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item, 'apparatus')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item, 'apparatus')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Table Values Inspection -->
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersInspection"
            :items="inspectionList"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Inspection List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn
                  small
                  color="primary"
                  dark
                  @click="openAddDialog('inspection')"
                  v-show="$route.query.statusPage !== 'view'"
                >
                  Add
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item, 'inspection')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item, 'inspection')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Table Values Calibration -->
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersCalibration"
            :items="calibrationList"
            v-if="selectedValue === 'Gas' || selectedValue === 'UTI Meter'"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Calibration List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn
                  small
                  color="primary"
                  dark
                  @click="openAddDialog('calibration')"
                  v-show="$route.query.statusPage !== 'view'"
                >
                  Add
                </v-btn>
              </v-toolbar>
            </template>

            <!-- Nomor urut -->
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item, 'calibration')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item, 'calibration')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Table Values Certificate -->
      <v-row>
        <v-col>
          <v-data-table
            class="elevation-1 mt-8"
            :headers="headersCertificate"
            :items="certificateList"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Certificate List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn
                  small
                  color="primary"
                  dark
                  @click="openAddDialog('certificate')"
                  v-show="$route.query.statusPage !== 'view'"
                >
                  Add
                </v-btn>
              </v-toolbar>
            </template>

            <!-- Nomor urut -->
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item, 'certificate')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item, 'certificate')"
                v-show="$route.query.statusPage !== 'view'"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data></template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">Status</span>
        </v-col>
        <v-col cols="8" class="pa-1">
          <v-switch
            ref="status"
            name="status"
            color="success"
            v-model="param.status"
            inset
            :label="`${param.status ? 'Active' : 'Inactive'}`"
          ></v-switch>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="mt-6 mb-6">
      <v-row>
        <v-col cols="6">
          <v-row align="center" class="ml-2 mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Created By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="create"
                v-model="param.createdBy"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="createDate"
                v-model="param.createdAt"
                class="ma-0"
                flat
                outlined
                dense
                solo
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="ml-2 mr-2"> </v-row>
        </v-col>
        <v-col cols="6">
          <v-row align="center" class="mr-2">
            <v-col cols="3" class="pa-1">
              <span class="title">Updated By</span>
            </v-col>
            <v-col cols="5" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="close"
                v-model="param.updatedBy"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4" align-self="start" class="pa-1">
              <v-text-field
                hide-details="auto"
                ref="closeDate"
                v-model="param.updatedAt"
                solo
                flat
                dense
                outlined
                class="ma-0"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" class="mr-2"> </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- DIALOG EDIT -->

    <v-dialog v-model="dialogDelete" persistent width="unset" min-width="290px">
      <v-card min-width="290px">
        <v-card-title class="justify-center" style="word-break: inherit"
          >Are you sure want to Delete ?</v-card-title
        >
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            min-width="100px"
            @click="deleteItemConfirm"
            outlined
            >Yes</v-btn
          >
          <v-btn color="red" min-width="100px" @click="closeDelete" outlined
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <editProductDialog
      v-show="dialogEdit"
      :dialog="dialogEdit"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editApparatusDialog
      v-show="dialogEditApparatus"
      v-if="
        dialogEditApparatus &&
        typeof editedItem === 'object' &&
        !Array.isArray(editedItem)
      "
      :dialog="dialogEditApparatus"
      :model="selectedValue"
      :data="editedItem"
      @save="saveEditedItem"
      @cancel="closeEditItem"
    />

    <editInspectionDialog
      v-show="dialogEditInspection"
      :dialog="dialogEditInspection"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editCalibrationDialog
      v-show="dialogEditCalibration"
      :dialog="dialogEditCalibration"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    />

    <editCertificationDialog
      v-show="dialogEditCertificate"
      :dialog="dialogEditCertificate"
      :model="selectedValue"
      :data="editedItem"
      v-on:save="saveEditedItem"
      v-on:cancel="closeEditItem"
    ></editCertificationDialog>

    <!-- Tambahkan dialog untuk menambah data -->
    <v-dialog v-model="dialogAdd.show" persistent max-width="500">
      <v-card>
        <v-card-title> Add New Data </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dialogAdd.newItem.type"
            label="Type (Gas List)"
            dense
            outlined
            v-if="dialogAdd.target === 'listGas'"
          />
          <!-- Apparatus input fields -->
          <div v-if="dialogAdd.target === 'apparatus'">
            <v-text-field
              v-model="dialogAdd.newItem.apparatus"
              label="Apparatus Name"
              dense
              outlined
            />
            <v-text-field
              v-model="dialogAdd.newItem.model"
              label="Manufacture / Model"
              dense
              outlined
            />
          </div>
          <v-text-field
            v-model="dialogAdd.newItem.reportType"
            label="Inspection Name"
            dense
            outlined
            v-if="dialogAdd.target === 'inspection'"
          />
          <v-text-field
            v-model="dialogAdd.newItem.certificateType"
            label="Certificate Name"
            dense
            outlined
            v-if="dialogAdd.target === 'certificate'"
          />
          <v-row v-if="dialogAdd.target === 'calibration'">
            <v-col>
              <v-text-field
                v-model="dialogAdd.newItem.noTube"
                label="No Tube"
                dense
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="dialogAdd.newItem.gasType"
                label="Gas Type"
                dense
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="dialogAdd.newItem.concentration"
                label="Concentration"
                dense
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="dialogAdd.newItem.unit"
                label="Unit"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="addItemConfirm">Save</v-btn>
          <v-btn color="red darken-1" text @click="dialogAdd.show = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { paramsCollection, firebaseDB } from "../../../firebase";
import editProductDialog from "@/components/dialog/NewParameter/editProductDialog";
import editInspectionDialog from "@/components/dialog/NewParameter/editInspectionDialog.vue";
import editCalibrationDialog from "@/components/dialog/NewParameter/editCalibrationDialog.vue";
import editApparatusDialog from "../../../components/dialog/NewParameter/editApparatusDialog.vue";
import editCertificationDialog from "../../../components/dialog/NewParameter/editCertificationDialog.vue";

export default {
  components: {
    isttoolbar,
    editProductDialog,
    editApparatusDialog,
    editInspectionDialog,
    editCalibrationDialog,
    editCertificationDialog,
  },
  props: {
    source: String,
  },

  data: () => ({
    dialogAdd: {
      show: false,
      target: "",
      newItem: {},
    },
    param: {
      paramsGroup: "",
      params: "",
      values: "",
      tapeLength: [],
      valveConnector: [],
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    //semisal uti udah ada , headerListGas bisa di ganti ke model list gas , tapi masih belum modular , jadi ada yang perlu di ubah
    headersListGas: [
      { text: "No", value: "no", sortable: false },
      {
        text: "Tabung",
        align: "start",
        value: "type",
        width: "auto",
      },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersInspection: [
      { text: "No", value: "no", sortable: false },
      { text: "Inspection Name", align: "start", value: "reportType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCertificate: [
      { text: "No", value: "no", sortable: false },
      { text: "Certificate Name", align: "start", value: "certificateType" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersCalibration: [
      { text: "No", value: "no", sortable: false },
      { text: "No Tube", align: "start", value: "noTube" },
      { text: "Gas Type", value: "gasType" },
      { text: "Concentration", value: "concentration" },
      { text: "Unit", value: "unit" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    headersApparatus: [
      { text: "No", value: "no", sortable: false },
      { text: "Apparatus", align: "start", value: "apparatus" },
      { text: "Manufacture / Model", value: "model" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    tapeLengthOptions: ["10 M", "15 M", "20 M", "30 M", "35 M", "40 M"],
    valveConnectorOptions: ["PF 2-1/4” TAP", "F/C50 Valve", "Q2 Valve"],
    selectedTapeLength: [],
    selectedValveConnector: [],
    itemsOptionProductTypeGas: [],
    itemsOptionProductTypeUTI: [],
    itemsGas: [],
    apparatusList: [],
    inspectionList: [],
    calibrationList: [],
    certificateList: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogEdit: false,
    dialogEditApparatus: false,
    dialogEditCalibration: false,
    dialogEditInspection: false,
    dialogEditCertificate: false,
    dialogDelete: false,
    selectEditTarget: "",
    selectedDeletedItem: "",
    selectedDeletedItemObj: {},
    selectedValue: "",
    editedItem: [],
  }),
  computed: {
    form() {
      return {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: this.param.values,
      };
    },
  },
  created() {
    this.dialog.success = false;
    this.dialog.error = false;
    this.dialog.show = true;
    let newData = {};
    firebaseDB
      .doc(`/params/${this.$route.query.paramsId}`)
      .get()
      .then((doc) => {
        newData = doc.data();
        newData.paramsId = doc.id;
        let original = doc.data().values;
        newData.values = this.convertData(original);
        newData.createdAt = doc
          .data()
          .createdAt.match(/([^T]+)/)[0]
          .split("-")
          .reverse()
          .join("/");
        newData.updatedAt =
          doc.data().updatedAt !== ""
            ? doc
                .data()
                .updatedAt.match(/([^T]+)/)[0]
                .split("-")
                .reverse()
                .join("/")
            : "";

        let getParsedData = JSON.parse(newData.values);

        // Deteksi jenis produk dari data
        if (getParsedData.listGas) {
          this.selectedValue = "Gas";
          this.itemsGas = getParsedData.listGas;
        }

        if (
          getParsedData.listCalibration &&
          getParsedData.listCalibration.some((item) => item.deskripsi)
        ) {
          this.selectedValue = "UTI Meter";
          this.headersCalibration = [
            { text: "No", value: "no", sortable: false },
            { text: "Deskripsi", align: "start", value: "deskripsi" },
            { text: "Reference Thermometer", value: "preCalibration" },
            { text: "Post Calibration", value: "postCalibration" },
            { text: "Maximum Error Allowed", value: "maximumErrorAllowed" },
            {
              text: "Actions",
              value: "actions",
              width: "100px",
              sortable: false,
            },
          ];
        }

        // Parse UTI Meter specific fields
        if (getParsedData.tapeLength) {
          this.selectedTapeLength = getParsedData.tapeLength;
        }

        if (getParsedData.valveConnector) {
          this.selectedValveConnector = getParsedData.valveConnector;
        }

        if (getParsedData.apparatusList) {
          this.apparatusList = getParsedData.apparatusList;
        }

        // Tetap set common data
        if (getParsedData.certificate) {
          this.certificateList = getParsedData.certificate;
        }
        if (getParsedData.inspectionReport) {
          this.inspectionList = getParsedData.inspectionReport;
        }
        if (getParsedData.listCalibration) {
          this.calibrationList = getParsedData.listCalibration;
        }

        this.getListDataProductTypeOption();

        this.param = newData;
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = false;
      })
      .catch(() => {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = false;
      });
  },
  methods: {
    getListDataProductTypeOption() {
      this.loading = true;
      paramsCollection
        .where("paramsGroup", "==", "Orders Product Type")
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderProductType: doc.data().values,
            });
          });
          this.loading = false;
          for (let i = 0; i < newData.length; i++) {
            newData[i].orderProductType = JSON.parse(
              newData[i].orderProductType.replace(/\\"/g, '"')
            );
          }
          this.itemsOptionProductTypeUTI = newData[0].orderProductType;
          this.itemsOptionProductTypeGas = newData[1].orderProductType;
          console.log(this.itemsOptionProductTypeGas, "<< GAS");
          console.log(this.itemsOptionProductTypeUTI, "<< UTI");
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },
    updateParamValues() {
      const newValues = {
        certificate: this.certificateList,
        inspectionReport: this.inspectionList,
        listCalibration: this.calibrationList,
      };

      if (this.selectedValue === "Gas") {
        newValues.listGas = this.itemsGas;
      }

      if (this.selectedValue === "UTI Meter") {
        newValues.apparatusList = this.apparatusList;
        newValues.tapeLength = this.selectedTapeLength;
        newValues.valveConnector = this.selectedValveConnector;
      }

      this.param.values = JSON.stringify(newValues);
    },

    // Buka dialog add
    openAddDialog(target) {
      console.log("open");
      this.dialogAdd.target = target;
      this.dialogAdd.newItem = {};
      this.dialogAdd.show = true;
    },

    // Tambahkan data baru
    addItemConfirm() {
      if (this.dialogAdd.target === "listGas") {
        this.itemsGas.push({ ...this.dialogAdd.newItem, id: Date.now() });
      } else if (this.dialogAdd.target === "inspection") {
        this.inspectionList.push({ ...this.dialogAdd.newItem, id: Date.now() });
      } else if (this.dialogAdd.target === "certificate") {
        this.certificateList.push({
          ...this.dialogAdd.newItem,
          id: Date.now(),
        });
      } else if (this.dialogAdd.target === "calibration") {
        this.calibrationList.push({
          ...this.dialogAdd.newItem,
          id: Date.now(),
        });
      } else if (this.dialogAdd.target === "apparatus") {
        this.apparatusList.push({
          ...this.dialogAdd.newItem,
          id: Date.now(),
        });
      }

      this.updateParamValues();
      this.dialogAdd.show = false; // Tutup dialog
    },
    deleteItem(item, str) {
      this.dialogDelete = true;
      this.selectedDeletedItemObj = item;
      this.selectedDeletedItem = str;
    },
    deleteItemConfirm() {
      let previousData = localStorage.getItem("userInput");

      if (this.selectedDeletedItem == "model")
        this.itemsGas = this.itemsGas.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );
      if (this.selectedDeletedItem == "inspection")
        this.inspectionList = this.inspectionList.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );
      if (this.selectedDeletedItem == "calibration")
        this.calibrationList = this.calibrationList.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );
      if (this.selectedDeletedItem == "certificate")
        this.certificateList = this.certificateList.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );

      if (this.selectedDeletedItem == "apparatus") {
        this.apparatusList = this.apparatusList.filter(
          (el) => el.id !== this.selectedDeletedItemObj.id
        );
      }

      if (previousData) {
        let dataBefore = JSON.parse(previousData);

        if (this.modelList.length > 0 && this.selectedValue === "Gas") {
          dataBefore.listGas = this.modelList;
          dataBefore.totalGas = this.modelList.length;
        }
        if (this.calibrationList.length > 0) {
          dataBefore.listCalibration = this.calibrationList;
          dataBefore.totalCalibration = this.calibrationList.length;
        }
        if (this.certificateList.length > 0) {
          dataBefore.certificate = this.certificateList;
          dataBefore.totalCertificate = this.certificateList.length;
        }
        if (this.inspectionList.length > 0) {
          dataBefore.inspectionReport = this.inspectionList;
          dataBefore.totalInspection = this.inspectionList.length;
        }
        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }

      this.updateParamValues();
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedDeletedItem = "";
      this.selectedDeletedItemObj = {};
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    editItem(item, str) {
      this.selectEditTarget = str;

      if (str == "apparatus") {
        this.editedItem = {
          id: item.id,
          apparatus: item.apparatus,
          model: item.model,
        };
        this.dialogEditApparatus = true;
      }

      if (str == "model") {
        this.editedItem = {
          id: item.id,
          type: item.type,
        };
        this.dialogEdit = true;
      }
      if (str == "inspection") {
        this.editedItem = {
          id: item.id,
          reportType: item.reportType,
        };
        this.dialogEditInspection = true;
      }
      if (str == "calibration") {
        this.editedItem = {
          id: item.id,
          noTube: item.noTube,
          gasType: item.gasType,
          concentration: item.concentration,
          unit: item.unit,
        };
        this.dialogEditCalibration = true;
      }
      if (str == "certificate") {
        this.editedItem = {
          id: item.id,
          certificateType: item.certificateType,
        };
        this.dialogEditCertificate = true;
      }
    },
    saveEditedItem() {
      let index = 0;
      let previousData = localStorage.getItem("userInput");

      if (this.selectEditTarget === "model") {
        index = this.itemsGas.findIndex((el) => el.id == this.editedItem.id);

        this.itemsGas.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          type: "",
        };
        this.dialogEdit = false;
      }

      if (this.selectEditTarget === "inspection") {
        index = this.inspectionList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.inspectionList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          reportType: "",
        };
        this.dialogEditInspection = false;
      }

      if (this.selectEditTarget == "calibration") {
        index = this.calibrationList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.calibrationList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          noTube: "",
          gasType: "",
          concentration: "",
          unit: "",
          deskripsi: "",
          maximumErrorAllowed: "",
          preCalibration: "",
          postCalibration: "",
          lot: "",
          cyl: "",
        };
        this.dialogEditCalibration = false;
      }

      if (this.selectEditTarget == "certificate") {
        index = this.certificateList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.certificateList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          certificateType: "",
        };
        this.dialogEditCertificate = false;
      }

      if (this.selectEditTarget == "apparatus") {
        index = this.apparatusList.findIndex(
          (el) => el.id == this.editedItem.id
        );
        this.apparatusList.splice(index, 1, this.editedItem);
        this.editedItem = {
          id: null,
          apparatus: "",
          model: "",
        };
        this.dialogEditApparatus = false;
      }

      if (previousData) {
        let dataBefore = JSON.parse(previousData);

        dataBefore.listGas = this.itemsGas;
        dataBefore.totalGas = this.itemsGas.length;
        dataBefore.listCalibration = this.calibrationList;
        dataBefore.totalCalibration = this.calibrationList.length;
        dataBefore.certificate = this.certificateList;
        dataBefore.totalCertificate = this.certificateList.length;
        dataBefore.inspectionReport = this.inspectionList;
        dataBefore.totalInspection = this.inspectionList.length;

        localStorage.setItem("userInput", JSON.stringify(dataBefore));
      }
    },
    closeEditItem() {
      this.dialogEdit = false;
      this.dialogEditCalibration = false;
      this.dialogEditCertificate = false;
      this.dialogEditInspection = false;
    },
    edit() {
      this.$router.replace({
        name: "parameter-configuration-edit",
        query: { paramsId: this.$route.query.paramsId, statusPage: "edit" },
      });
    },
    back() {
      localStorage.removeItem("userInput");
      localStorage.removeItem("selectedItemValues");

      this.$router.replace({
        name: "parameter-configuration-list",
      });
    },
    async save() {
      // Object.keys(this.form).forEach((f) => {
      //   if (!this.form[f]) this.formHasErrors = true;
      //   this.$refs[f].validate(true);
      // });
      this.updateParamValues();
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      let original = this.param.values;
      let stringify = JSON.stringify(original);
      let remove = stringify.replace(/\\n/g, "");
      const newData = {
        paramsGroup: this.param.paramsGroup,
        params: this.param.params,
        values: remove,
        status: this.param.status,
        updatedAt: new Date().toISOString(),
        updatedBy: "Admin",
      };
      firebaseDB
        .doc(`/params/${this.$route.query.paramsId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Params not found";
          }
          localStorage.removeItem("userInput");
          localStorage.removeItem("selectedItemValues");
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },
    convertData(item) {
      let data = JSON.parse(item);
      if (typeof data === "string" || typeof data === "number") {
        return data;
      } else {
        let stringify = JSON.stringify(data, null, 4);
        return stringify;
      }
    },
  },
};
</script>
