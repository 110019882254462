import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/core/routes";
import store from "./store/store";
import currencyHelper from "@/helper/currencyHelper";
import authHelper from "@/helper/authHelper";
import i18n from './i18n';


// Import vue-toastification
import Toast from "vue-toastification";
// Import style untuk toast
import "vue-toastification/dist/index.css";

// Konfigurasi Toast
Vue.use(Toast, {
  position: "top-right", // Posisi toast
  timeout: 3000, // Waktu tampil
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
});

Vue.config.productionTip = false;

new Vue({
  mixins: [currencyHelper, authHelper],
  vuetify,
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount("#app");