<template>
  <div class="surat-resmi">
    <v-container fluid class="header-container">
      <v-row align="center" justify="space-between">
        <v-col cols="3">
          <img
            src="@/assets/images/logo-ism.png"
            alt="Indo Safety Marine Logo"
            class="logo"
          />
        </v-col>
        <v-col cols="9" class="header">
          <h1>PT. Indo Safety Marine</h1>
          <br />
          <p>Ruko Sentra Menteng</p>
          <p>
            Jl. MH Thamrin Blok B7 MN32 3rd Floor, Bintaro Jaya Sektor 7 Sentra
            Menteng
          </p>
          <p>Tangerang Selatan 15224, Indonesia</p>
          <p>Hotline : +62817-750838, Email : info@indosafetymarine.com</p>
          <p>www.indosafetymarine.com | www.utimeterjakarta.com</p>
        </v-col>
      </v-row>
    </v-container>

    <div class="content">
      <h3 class="title"><strong>INSPECTION REPORT</strong></h3>
      <p>Checked On: {{ formattedDate }}</p>
      <p>Vessel Name: {{ data.vesselName }}</p>
      <br />
      
      <!-- TEST RESULT -->
      <h4><strong>Test Result</strong></h4>

      <v-data-table
        :headers="headers"
        :items="data.productsList"
        item-key="index"
        dense
      >
        <template #item="{ item }">
          <tr>
            <td>
              {{
                item.orderProduct === "Gas Meter"
                  ? `Gas Detector / ${item.orderProductSeries}`
                  : item.orderProduct === "UTI Meter"
                  ? `${item.orderProduct} / ${item.orderProductSeries}`
                  : item.orderProduct
              }}
              <br />
              SN: {{ item.orderProductSN }} {{ item.calibrationName }}
            </td>
            <td>
              <span v-for="(data, index) in item.inspectionReport" :key="index">
                - {{ data.checked ? data.reportType : data.shift }}
                <span v-if="index !== item.inspectionReport.length - 1"
                  ><br
                /></span>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <!-- DESCRIPTION -->
    <v-row class="description mt-6">
      <v-col cols="6">
        <h4>Bahasa Version</h4>
        <p>
          Daftar penggantian suku cadang dan pekerjaan yang tertulis pada
          laporan ini belum bersifat final dan dapat berubah sewaktu-waktu
          hingga selesai dilakukan proses kalibrasi.
        </p>
      </v-col>
      <v-col cols="6">
        <h4>English Version</h4>
        <p>
          List of replacement parts and actions to be taken described in this
          inspection report are not final and are subject to change until
          calibration procedure are performed.
        </p>
      </v-col>
    </v-row>

    <!-- SIGNATURE + FOOTER -->
    <div class="footer-wrapper">
      <div class="signature-container">
        <div class="signature">
          <p>Tested By:</p>
          <p>
            <strong>{{
              data.inspectionEngineer ? data.inspectionEngineer : "-"
            }}</strong>
          </p>
          <p>Calibration Engineer</p>
        </div>
      </div>

      <v-footer class="footer">
        <p>IndoSafetyMarine</p>
      </v-footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "InspectionReport",
  props: {
    data: Object,
  },
  data: () => ({
    headers: [
      { text: "Device", key: "device" },
      { text: "Result", key: "result" },
    ],
  }),
  computed: {
    formattedDate() {
      return new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style>
.surat-resmi {
  width: 100%;
  max-width: 210mm;
  min-height: 297mm;
  margin: auto;
  padding: 20px;
  font-size: 12px;
  line-height: 1.5;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  border-bottom: 2px solid black;
  padding-bottom: 15px;
}

.logo {
  width: 100%;
  max-width: 200px;
  padding-left: 40px;
  margin-top: 20px;
}

.header {
  text-align: center;
  font-size: 14px;
}

.header p {
  margin-top: 10px;
}

.header h1 {
  font-size: 18px;
  margin-bottom: 5px;
}

.title {
  text-align: center;
  margin: 20px 0;
}

.footer-wrapper {
  margin-top: auto;
}

.signature-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 10px;
  padding-right: 40px;
}

.signature {
  text-align: right;
  font-size: 14px;
}

.footer {
  text-align: center;
  width: 100%;
  font-size: 12px;
  padding-top: 10px;
  border-top: 2px solid black;
}
</style>
