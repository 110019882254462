<template>
  <v-chip :color="chipColor" dark label small>
    {{ status }}
  </v-chip>
</template>

<script>
export default {
  name: "ChipStatus",
  props: {
    status: {
      type: String,
      default: "Entry",
    },
  },
  computed: {
    chipColor() {
      switch (this.status) {
        case "Entry":
          return "grey";
        case "Waiting for Inspection process": //
          return "yellow darken-2";
        case "Waiting for Client to approve Inspection": //
          return "blue-grey";
        case "Inspection is Completed": //
          return "blue";
          case "Inspection is Pending": //
          return "orange";
          case "PO not finished yet": //
          return "orange";
        case "Progress for Inspection":
          return "blue-grey";
        case "Waiting for Approval Client":
          return "blue";
        case "Proggress for Quotation":
          return "cyan";
        case "Waiting for PO Client":
          return "indigo";
        case "Final Order": //
          return "teal";
        case "Reject":
          return "red";
        case "Done":
          return "teal";
        default:
          return "grey";
      }
    },
  },
};
</script>
