<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ["data", "options"],
  mounted() {
    this.addPlugin({
      afterDraw: function (chart) {
        const ctx = chart.chart.ctx; // Context Canvas
        chart.data.datasets.forEach((dataset, datasetIndex) => {
          chart.getDatasetMeta(datasetIndex).data.forEach((arc, index) => {
            const value = dataset.data[index];
            const percentage = value + " Unit"; // Hitung Persentase
            const x = arc.tooltipPosition().x; // Posisi X Teks
            const y = arc.tooltipPosition().y; // Posisi Y Teks

            ctx.save();
            ctx.fillStyle = "#000"; // Warna Teks (Hitam)
            ctx.font = "bold 14px Arial"; // Gaya Teks
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(percentage, x, y); // Tulis Teks di Depan
            ctx.restore();
          });
        });
      },
    });

    this.renderChart(this.data, this.options);
  },
};
</script>