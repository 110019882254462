<template>
  <v-dialog v-model="localDialog" persistent max-width="600px">
    <v-form ref="form" v-model="formValid">
      <v-card>
        <isttoolbar
          title="Add Apparatus"
          dark
          color="secondary"
          icon="mdi-cogs"
        />
        <v-container>
          <!-- Apparatus Field -->
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">Apparatus</span>
            </v-col>
            <v-col cols="8" class="pa-1">
              <v-text-field
                v-model="apparatus"
                :rules="[rules.required]"
                label="Apparatus"
                solo
                flat
                outlined
                dense
                class="ma-0"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <!-- Manufacture / Model Field -->
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">Manufacture / Model</span>
            </v-col>
            <v-col cols="8" class="pa-1">
              <v-text-field
                v-model="model"
                :rules="[rules.required]"
                label="Manufacture / Model"
                solo
                flat
                outlined
                dense
                class="ma-0"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" outlined @click="saveNewApparatus">
            Save
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="cancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: {
    dialog: Boolean,
    data: Array,
  },
  data() {
    return {
      localDialog: false,
      apparatus: "",
      model: "",
      formValid: true,
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  watch: {
    dialog: {
      immediate: true,
      handler(val) {
        this.localDialog = val;
      },
    },
    localDialog(val) {
      this.$emit("update:dialog", val);
    },
  },
  methods: {
    saveNewApparatus() {
      if (!this.$refs.form.validate()) return;

      const newItem = {
        id: Date.now(),
        apparatus: this.apparatus.trim(),
        model: this.model.trim(),
      };

      this.data.push(newItem);

      // Reset & close
      this.apparatus = "";
      this.model = "";
      this.formValid = true;
      this.localDialog = false;

      this.$emit("save");
    },
    cancel() {
      this.localDialog = false;
      this.apparatus = "";
      this.model = "";
      this.$emit("cancel");
    },
  },
};
</script>