<template>
  <div class="surat-resmi">
    <v-container fluid style="border-bottom: 1px solid #000">
      <v-row align="center" justify="space-between">
        <v-col cols="3">
          <img
            src="@/assets/images/logo-ism.png"
            contain
            alt="Indo Safety Marine Logo"
            width="100%"
            class="logo"
          />
        </v-col>
        <v-col cols="9">
          <div class="header">
            <h1>PT. Indo Safety Marine</h1>
            <br />
            <p>Ruko Sentra Menteng</p>
            <p>
              Jl. MH Thamrin Blok B7 MN32 3rd Floor, Bintaro Jaya Sektor 7
              Sentra Menteng
            </p>
            <p>Tangerang Selatan 15224, Indonesia</p>
            <p>Hotline : +62817-750838, Email : info@indosafetymarine.com</p>
            <p>www.indosafetymarine.com | www.utimeterjakarta.com</p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="content">
      <h3 style="text-align: center; text-transform: uppercase">
        <strong>Receipt of Equipment / Delivery Order</strong>
      </h3>

      <div class="surat-resmi">
        <div class="content">
          <!-- HEADERS CONTENT -->
          <v-row>
            <v-col>
              <p>
                Company name :
                {{ data.customerName ? data.customerName : "-" }}
              </p>
              <p>PO Number : {{ data.poNumber || "-" }}</p>
              <p>Vessel Name : {{ data.vesselName || "-" }}</p>
            </v-col>
            <v-col>
              <p>Date : {{ data.deliveryDate || "-" }}</p>
              <p>Delivery No : {{ data.ordersNo || "-" }}</p>
            </v-col>
          </v-row>
          <p><br /></p>

          <!-- TEST RESULT -->
          <p><strong>Test Result</strong></p>

          <v-data-table
            :headers="headers"
            :items="data.productsList"
            item-key="index"
          >
            <template #item="{ item, index }">
              <tr>
                <!-- Adding 1 to index to start numbering from 1 -->
                <td>{{ index + 1 }}</td>

                <!-- Displaying the quantity -->
                <td>{{ item.jml || "-" }}</td>

                <!-- Displaying the unit -->
                <td>{{ item.unit || "-" }}</td>

                <td>
                  <span
                    v-for="(report, dataIndex) in item.inspectionReport.filter(
                      (r) => r.checked
                    )"
                    :key="dataIndex"
                  >
                    • {{ report.reportType }}<br />
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <v-row>
      <v-col cols="6">
        <div class="signature">
          <p>{{ data.customerName || "-" }}</p>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="signature">
            <p>{{ this.userLogin || "-" }}</p>
        </div>
      </v-col>
    </v-row>

    <v-footer class="footer">
      <v-row>
        <v-col class="text-left" cols="12">
          <p>Indo Safety Marine</p>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "workForm_vue",
  props: {
    data: Object,
  },
  data: () => ({
    userLogin: "",
    headers: [
      {
        text: "No",
        key: "index",
      },
      {
        text: "Qty",
        key: "jml",
      },
      {
        text: "Unit",
        key: "unit",
      },
      {
        text: "Description",
        key: "result",
      },
    ],
  }),
  mounted() {
    const user = JSON.parse(localStorage.getItem("ismUser"));
    this.userLogin = user?.name || user?.username || "User";
  },
};
</script>

<style>
.surat-resmi {
  display: flex;
  flex-direction: column;
  min-height: 58vh; /* Ensure that the container takes at least the full viewport height */
  max-width: 21cm; /* Set maximum width to A4 width */
  margin: 0 auto; /* Center the container horizontally */
  padding: 1cm; /* Add padding to mimic A4 margin */
  box-sizing: border-box; /* Include padding in width calculation */
}

.logo {
  width: 100%;
  max-width: 200px;
  padding-left: 40px;
  margin-top: 20px;
}

.header {
  /* border-bottom: 1px solid #000; */
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 5px;
  text-align: center;
}

.header h1 {
  font-size: 22px;
  margin: 0;
  line-height: 0.5;
}

.header p {
  margin: 0;
  font-size: 12px;
  line-height: 1.2;
}

/* Style untuk content surat */
.content {
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1.5;
}

/* Style untuk tanda tangan */
.signature {
  margin-top: 50px;
  margin-bottom: 30%;
}

.signature p {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding-left: 0;
}

.footer {
  position: absolute;
  background-color: none;
  bottom: 0;
  width: 93%;
}

@media print {
  .surat-resmi {
    page-break-inside: avoid;
  }
}
</style>
