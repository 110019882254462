var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('isttoolbar',{attrs:{"title":"Service Order","dark":"","color":"secondary","icon":"mdi-cog-outline"}},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"white"},on:{"click":_vm.goToAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add Service ")],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" Service List "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:`item.index`,fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:`item.statusOrder`,fn:function({ item }){return [_c('ChipStatus',{attrs:{"status":item.statusDesc}})]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
                item.statusDesc ===
                  'Waiting for Client to approve Inspection' ||
                item.statusOrder === '2' ||
                item.statusOrder === '5'
              ),expression:"\n                item.statusDesc ===\n                  'Waiting for Client to approve Inspection' ||\n                item.statusOrder === '2' ||\n                item.statusOrder === '5'\n              "}],staticClass:"pointer",on:{"click":function($event){return _vm.clickView(item)}}},[_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
                item.statusDesc === 'Waiting for Client to approve Inspection'
              ),expression:"\n                item.statusDesc === 'Waiting for Client to approve Inspection'\n              "}],staticClass:"pointer",on:{"click":function($event){return _vm.clickEdit(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.clickDelete(item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"unset","min-width":"290px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{attrs:{"min-width":"290px"}},[_c('v-card-title',{staticClass:"justify-center",staticStyle:{"word-break":"inherit"}},[_vm._v("Are you sure want to Delete ?")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary","min-width":"100px","outlined":""},on:{"click":function($event){_vm.deleteData();
              _vm.dialogDelete = false;}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"red","min-width":"100px","outlined":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("No")])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dialog.success && !_vm.dialog.error),expression:"!dialog.success && !dialog.error"}]},[_c('v-card-title',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialog.success || _vm.dialog.error),expression:"dialog.success || dialog.error"}]},[(_vm.dialog.success)?_c('v-card-title',{staticClass:"justify-center",staticStyle:{"word-break":"inherit"}},[_vm._v("Your Data has been Deleted")]):(_vm.dialog.error)?_c('v-card-title',{staticClass:"justify-center",staticStyle:{"word-break":"inherit"}},[_vm._v("Your Data Failed to Deleted")]):_vm._e(),(_vm.dialog.error)?_c('v-card-text',{staticClass:"d-flex justify-center error--text",staticStyle:{"word-break":"inherit"}},[_vm._v(" "+_vm._s(_vm.dialog.errorMessage)+" ")]):_vm._e(),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary","min-width":"100px","outlined":""},on:{"click":_vm.resetDialog}},[_vm._v("OK")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }