<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-form ref="form" :readonly="$route.query.statusPage === 'view'">
      <v-card outlined class="pa-2 mb-2">
        <isttoolbar
          title="Work Form Detail"
          dark
          color="secondary"
          icon="mdi-briefcase"
        />

        <v-container>
          <!-- Informasi Produk -->
          <v-row class="mt-1" dense>
            <v-col cols="12" md="6">
              <v-row
                v-for="(field, index) in productFields"
                :key="index"
                align="center"
                class="mr-2 ml-2 mb-3"
              >
                <v-col cols="12" sm="4" class="pa-1">
                  <span class="title">{{ field.label }}</span>
                </v-col>
                <v-col cols="12" sm="8" align-self="start" class="pa-1">
                  <v-text-field
                    :ref="field.ref"
                    :value="field.value"
                    hide-details="auto"
                    solo
                    flat
                    outlined
                    readonly
                    dense
                    class="ma-0"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <!-- GAS METER -->
              <div v-if="data.orderProduct === 'Gas Meter'">
                <v-row align="center">
                  <v-col cols="12" class="pa-1 text-center">
                    <span class="title">Reference Gas</span>
                  </v-col>
                </v-row>
                <v-row align="center" class="mr-2 ml-2">
                  <v-col cols="12" class="pa-1">
                    <v-data-table
                      :headers="headers"
                      :items="dataGas"
                      class="elevation-1"
                      :options.sync="options"
                      :server-items-length="Number(data.totalGas)"
                      hide-default-footer
                    >
                      <template v-slot:[`item.lot`]="{ item }">
                        <v-text-field
                          v-model="item.lot"
                          dense
                          flat
                          outlined
                          solo
                          hide-details
                          class="ma-0"
                          placeholder="contoh = 1 atau 0.1"
                          @keypress="allowOnlyDecimal"
                        />
                      </template>

                      <template v-slot:[`item.cyl`]="{ item }">
                        <v-text-field
                          v-model="item.cyl"
                          dense
                          flat
                          outlined
                          solo
                          hide-details
                          class="ma-0"
                          placeholder="contoh = 1 atau 0.1"
                          @keypress="allowOnlyDecimal"
                        />
                      </template>

                      <template v-slot:no-data> No data available </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </div>

              <!-- UTI METER -->
              <div v-else-if="data.orderProduct === 'UTI Meter'">
                <div
                  v-for="(group, gIndex) in checkboxGroups"
                  :key="gIndex"
                  class="mb-12"
                >
                  <v-row class="mr-2 ml-2">
                    <v-col cols="12" sm="5" class="pa-1">
                      <span class="font-weight-medium">{{ group.title }}</span>
                    </v-col>
                    <v-col cols="12" sm="12" class="pa-1">
                      <v-row dense no-gutters class="d-flex flex-wrap">
                        <v-col
                          v-for="(box, bIndex) in group.boxes"
                          :key="bIndex"
                          cols="12"
                          sm="6"
                          md="4"
                          class="pr-2"
                        >
                          <v-checkbox
                            v-model="checkbox[box.model]"
                            :label="box.label"
                            dense
                            hide-details
                            class="ma-0"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <!-- INSPECTION TOOLBAR -->
        <isttoolbar
          title="Inspection Result"
          class="mb-2 mt-2"
          dark
          color="primary"
        >
          <v-spacer />
          <v-btn
            v-if="
              !['Inspection is Completed', 'Inspection is Pending'].includes(
                docStatus
              )
            "
            color="light-blue"
            @click="openAddInspectionDialog"
          >
            <v-icon left>mdi-plus</v-icon> Add Inspection
          </v-btn>
        </isttoolbar>

        <!-- TABLE INSPECTION -->
        <v-data-table
          class="elevation-1 mt-2 mb-2 mr-10 ml-10"
          :headers="headersInspector"
          :items="dataInspector"
        >
          <!-- Kolom No (index/nomor urut) -->
          <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 }}
          </template>

          <!-- Kolom Checkbox -->
          <template v-slot:[`item.checkbox`]="{ item }">
            <v-checkbox v-model="item.checked" />
          </template>

          <!-- Kolom Actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="
                !['Inspection is Completed', 'Inspection is Pending'].includes(
                  docStatus
                )
              "
              icon
              color="red"
              @click="deleteInspection(item)"
              title="Delete Inspection"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <!-- Optional: Message kalau data kosong -->
          <template v-slot:no-data>
            <div class="text-center pa-4">No data available</div>
          </template>
        </v-data-table>

        <!-- CATATAN ENGINEER -->
        <v-row align="center" class="mr-8 ml-8 mt-4 mb-4">
          <v-col cols="2" class="pa-1">
            <span class="title">Inspection Notes</span>
          </v-col>
          <v-col align-self="start" class="pa-1">
            <v-textarea
              hide-details="auto"
              v-model="engineerNotes"
              ref="engineer-notes"
              class="ma-0"
              flat
              outlined
              dense
              solo
              :rows="3"
            />
          </v-col>
        </v-row>

        <!-- BUTTONS -->
        <v-card-actions class="mr-8">
          <v-spacer />
          <v-btn
            v-if="
              !['Inspection is Completed', 'Inspection is Pending'].includes(
                docStatus
              )
            "
            color="blue darken-1"
            outlined
            @click="saveNewInspection"
            >Save</v-btn
          >
          <v-btn color="blue darken-1" outlined @click="$emit('cancel')"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>

    <!-- DIALOG ADD INSPECTION -->
    <v-dialog v-model="addInspectionDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline font-weight-medium justify-center">
          Add New Inspection
        </v-card-title>

        <v-card-text class="pt-2 px-4">
          <v-form @submit.prevent="addInspection">
            <v-text-field
              v-model="newInspection.reportType"
              label="Report Type"
              outlined
              dense
              placeholder="e.g. Visual, Leak Test, etc"
              hide-details="auto"
              autofocus
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end px-4 pb-4">
          <v-btn text color="grey darken-1" @click="closeAddInspectionDialog">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="addInspection"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: { isttoolbar },
  props: ["dialog", "data", "docStatus"],
  data: () => ({
    checkbox: {
      tl10: false,
      tl15: false,
      tl20: false,
      tl25: false,
      tl30: false,
      tl35: false,
      tl40: false,
      pfTap: false,
      fc50: false,
      q2Valve: false,
    },
    dataGas: [],
    options: { page: 0, size: -1 },
    headers: [
      { text: "Lot", value: "lot", align: "start" },
      { text: "Cyl", value: "cyl", align: "center" },
      { text: "Gas Type", value: "gasType", align: "center" },
    ],
    headersInspector: [
      { text: "No", value: "no" },
      { text: "Report Types", value: "reportType", width: "60%" },
      { text: "Option", value: "checkbox" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    dataInspector: [],
    engineerNotes: "",
    addInspectionDialog: false,
    newInspection: { reportType: "", checked: false },
  }),
  computed: {
    productFields() {
      return [
        {
          label: "Product",
          value: this.data.orderProduct,
          ref: "orderProduct",
        },
        {
          label: "Product Merk",
          value: this.data.orderProductType,
          ref: "orderProductType",
        },
        {
          label: "Product Type",
          value: this.data.orderProductSeries,
          ref: "orderProductSeries",
        },
        {
          label: "Product Serial Number",
          value: this.data.orderProductSN,
          ref: "orderProductSN",
        },
        {
          label: "Calibration",
          value: this.data.calibrationName,
          ref: "calibrationName",
        },
        {
          label: "Inspection",
          value: this.data.inspectionName,
          ref: "inspectionName",
        },
      ];
    },
    checkboxGroups() {
      return [
        {
          title: "Tape Length",
          boxes: [
            { label: "10 M", model: "tl10" },
            { label: "15 M", model: "tl15" },
            { label: "20 M", model: "tl20" },
            { label: "30 M", model: "tl30" },
            { label: "35 M", model: "tl35" },
            { label: "40 M", model: "tl40" },
          ],
        },
        {
          title: "Valve Connection",
          boxes: [
            { label: "PF 2-1/4” TAP", model: "pfTap" },
            { label: "F/C50 Valve", model: "fc50" },
            { label: "Q2 Valve", model: "q2Valve" },
          ],
        },
      ];
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        // ✅ RESET checkbox lebih dulu
        Object.keys(this.checkbox).forEach((k) => (this.checkbox[k] = false));

        this.engineerNotes = this.data.engineerNotes || "";
        this.dataInspector = (this.data.inspectionReport || []).map(
          (el, index) => ({
            ...el,
            no: index + 1,
            jenis: "inspection",
          })
        );

        // GAS METER
        if (this.data.orderProduct === "Gas Meter") {
          this.dataGas = (this.data.listCalibration || []).map((el) => ({
            no: el.no || "",
            noTube: el.noTube || "",
            gasType: el.gasType || "",
            concentration: el.concentration || "",
            unit: el.unit || "",
            deskripsi: el.deskripsi || "",
            maximumErrorAllowed: el.maximumErrorAllowed || "",
            preCalibration: el.preCalibration || "",
            postCalibration: el.postCalibration || "",
            lot: el.lot || "",
            cyl: el.cyl || "",
          }));
        }

        // UTI METER
        if (this.data.orderProduct === "UTI Meter") {
          const tape =
            String(this.data.tapeLength || "").match(/\d+/)?.[0] || "";
          const tapeKey = `tl${tape}`;
          if (Object.prototype.hasOwnProperty.call(this.checkbox, tapeKey)) {
            this.checkbox[tapeKey] = true;
          }

          const valve = (this.data.valveConnector || "").toUpperCase();
          if (valve === "PF 2-1/4” TAP") this.checkbox.pfTap = true;
          else if (valve === "F/C50 VALVE") this.checkbox.fc50 = true;
          else if (valve === "Q2 VALVE") this.checkbox.q2Valve = true;
        }
      }
    },
  },
  methods: {
    openAddInspectionDialog() {
      this.newInspection = { reportType: "", checked: false };
      this.addInspectionDialog = true;
    },
    closeAddInspectionDialog() {
      this.addInspectionDialog = false;
    },
    addInspection() {
      if (!this.newInspection.reportType.trim())
        return this.$toast.error("Report Type cannot be empty!");
      const newItem = {
        ...this.newInspection,
        no: this.dataInspector.length + 1,
      };
      this.dataInspector.push(newItem);
      this.data.inspectionReport = [...this.dataInspector];
      this.addInspectionDialog = false;
      this.$toast.success("Inspection added successfully!");
    },
    deleteInspection(item) {
      this.dataInspector = this.dataInspector
        .filter((i) => i.no !== item.no)
        .map((el, i) => ({ ...el, no: i + 1 }));
      this.data.inspectionReport = [...this.dataInspector];
      this.$toast.success("Inspection deleted successfully!");
    },
    saveNewInspection() {
      if (!this.engineerNotes.trim())
        return this.$toast.error("Description cannot be empty!");

      // GAS METER: simpan Reference Gas dari listCalibration (bukan listGas lagi)
      if (this.data.orderProduct === "Gas Meter") {
        this.data.listCalibration = this.dataGas.map((el) => ({
          no: el.no || "",
          noTube: el.noTube || "",
          gasType: el.gasType || "",
          concentration: el.concentration || "",
          unit: el.unit || "",
          deskripsi: el.deskripsi || "",
          maximumErrorAllowed: el.maximumErrorAllowed || "",
          preCalibration: el.preCalibration || "",
          postCalibration: el.postCalibration || "",
          lot: el.lot || "",
          cyl: el.cyl || "",
        }));

        // Validasi minimal 1 reference gas
        if (!this.data.listCalibration.length) {
          return this.$toast.error("Please input at least one Reference Gas.");
        }
      }

      // UTI METER: simpan tapeLength dan valveConnector
      if (this.data.orderProduct === "UTI Meter") {
        const selectedTape = Object.keys(this.checkbox).find(
          (k) => this.checkbox[k] && k.startsWith("tl")
        );
        if (selectedTape) this.data.tapeLength = selectedTape.replace("tl", "");

        const selectedValve = ["pfTap", "fc50", "q2Valve"].find(
          (k) => this.checkbox[k]
        );
        if (selectedValve) {
          this.data.valveConnector =
            selectedValve === "pfTap"
              ? "PF 2-1/4” TAP"
              : selectedValve === "fc50"
              ? "F/C50 Valve"
              : "Q2 Valve";
        }

        // Validasi minimal 1 inspection
        if (!this.dataInspector.length) {
          return this.$toast.error("Please add at least one Inspection.");
        }
      }

      this.data.engineerNotes = this.engineerNotes;
      this.data.inspectionReport = [...this.dataInspector];
      this.$emit("save", this.data);
      this.$toast.success("Work Form saved successfully!");
    },

    allowOnlyDecimal(e) {
      const char = String.fromCharCode(e.keyCode || e.which);
      const allowed = /^[0-9.]$/;

      // Cegah input jika bukan angka atau titik
      if (!allowed.test(char)) {
        e.preventDefault();
      }

      // Cegah lebih dari satu titik
      if (char === "." && e.target.value.includes(".")) {
        e.preventDefault();
      }
    },
  },
};
</script>
