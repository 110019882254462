<template>
  <v-card flat>
    <!-- Toolbar -->
    <isttoolbar title="Work Form" dark color="secondary" icon="mdi-briefcase">
      <v-spacer />
      <v-btn @click="save" color="green" class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" color="grey" class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>

    <!-- Sub Toolbar -->
    <isttoolbar
      title="New Work Form"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>

    <!-- Form -->
    <v-container>
      <v-form ref="form" class="mb-10">
        <!-- Order No -->
        <v-row align="center" class="ml-2">
          <v-col cols="12" sm="4" md="2" class="pa-1">
            <span class="title">
              Order No
              <span class="error--text">*</span>
            </span>
          </v-col>
          <v-col cols="12" sm="8" md="5" align-self="start" class="pa-1">
            <v-autocomplete
              hide-details="auto"
              label="Select Order No"
              @input="setDataOrder"
              v-model="param.orderNo"
              :items="dataOrders"
              item-text="ordersNo"
              ref="ordersNo"
              class="ma-0"
              :loading="loadingOrder"
              return-object
              flat
              outlined
              dense
              solo
              clearable
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <!-- Customer Name -->
        <v-row align="center" class="ml-2">
          <v-col cols="12" sm="4" md="2" class="pa-1">
            <span class="title">Customer Name</span>
          </v-col>
          <v-col cols="12" sm="8" md="5" align-self="start" class="pa-1">
            <v-text-field
              hide-details="auto"
              label="Customer Name"
              ref="customerName"
              :value="param.customerName"
              solo
              flat
              outlined
              readonly
              dense
              class="ma-0"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Vessel Name -->
        <v-row align="center" class="ml-2">
          <v-col cols="12" sm="4" md="2" class="pa-1">
            <span class="title">Vessel Name</span>
          </v-col>
          <v-col cols="12" sm="8" md="5" align-self="start" class="pa-1">
            <v-text-field
              hide-details="auto"
              label="Vessel Name"
              ref="vesselName"
              :value="param.vesselName"
              solo
              flat
              outlined
              readonly
              dense
              class="ma-0"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Status Customer -->
        <v-row align="center" class="ml-2">
          <v-col cols="12" sm="4" md="2" class="pa-1">
            <span class="title">Status Customer</span>
          </v-col>
          <v-col cols="12" sm="8" md="5" align-self="start" class="pa-1">
            <v-text-field
              hide-details="auto"
              label="Status Customer"
              ref="statusCustomer"
              :value="param.statusCustomer"
              solo
              flat
              outlined
              readonly
              dense
              class="ma-0"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Notes -->
        <v-row align="center" class="ml-2">
          <v-col cols="12" sm="4" md="2" class="pa-1">
            <span class="title">Notes Customer</span>
          </v-col>
          <v-col cols="12" sm="8" md="5" align-self="start" class="pa-1">
            <v-text-field
              hide-details="auto"
              label="Notes"
              ref="notes"
              :value="param.notes"
              solo
              flat
              outlined
              readonly
              dense
              class="ma-0"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- List Product -->
        <v-data-table
          class="elevation-1 mt-8"
          :headers="headers"
          :items="items"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>List Product</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn text @click="newItem">Add Product</v-btn>
          </template>
        </v-data-table>
      </v-form>
    </v-container>

    <!-- Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
          >
            Your Data has been Saved
          </v-card-title>
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
          >
            Your Data Failed to Saved
          </v-card-title>
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- DIALOG WORK FORM -->
    <workFormDialog
      v-show="dialogWorkForm"
      :dialog="dialogWorkForm"
      :data="dataDetail"
      :docStatus="param.statusDesc"
      v-if="dataDetail"
      v-on:save="saveItem"
      v-on:cancel="closeItem"
    />
  </v-card>
</template>
<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import workFormDialog from "@/components/dialog/workFormDialog";
import { ordersCollection } from "@/firebase";

export default {
  components: {
    isttoolbar,
    workFormDialog,
  },
  props: {
    source: String,
  },

  data: () => ({
    masterParams: [],
    loadingOrder: false,
    dataOrders: [],
    dataDetail: {},
    param: {
      ordersNo: "",
      customerName: "",
      vesselName: "",
      statusCustomer: "",
      notes: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    items: [],
    headers: [
      { text: "Product", align: "start", value: "orderProduct" },
      { text: "Product Type", value: "orderProductType" },
      { text: "Product Series", value: "orderProductSeries" },
      { text: "Product Serial Number", value: "orderProductSN" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    dialogWorkForm: false,
    dialogCRUD: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      orderProduct: "",
      orderProductType: "",
      orderProductSeries: "",
      orderProductSN: "",
      notes: "",
      statusWorkForm: false,
    },
    defaultItem: {
      orderProduct: "",
      orderProductType: "",
      orderProductSeries: "",
      orderProductSN: "",
      notes: "",
      statusWorkForm: false,
    },
    orders: [],
  }),
  computed: {
    form() {
      return {
        ordersNo: this.param.ordersNo,
      };
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
  },
  watch: {
    dialogCRUD(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.getListOrders();
    this.masterParams = JSON.parse(localStorage.getItem("masterParams"));
    this.masterParams.forEach((element) => {
      if (
        element.paramsGroup === "Orders" &&
        element.params === "Orders Product"
      ) {
        this.orderProduct = JSON.parse(element.values);
      }
    });
  },
  methods: {
    back() {
      this.$router.replace({
        name: "workform-list",
      });
    },
    getListOrders() {
      ordersCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          const skipStatuses = [
            "Waiting for Client to approve Inspection",
            "Inspection is Completed",
            "Inspection is Pending",
            "Final Order",
            "PO not finished yet"
          ];

          let newData = [];
          data.forEach((doc) => {
            let obj = doc.data();
            obj.orderId = doc.id;

            // ❌ Skip jika statusDesc masuk dalam daftar skipStatuses
            if (skipStatuses.includes(obj.statusDesc)) return;

            newData.push(obj);
          });

          this.dataOrders = newData.sort((a, b) => {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (f === "customerName" || f === "vesselName" || f === "description") {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (!this.formHasErrors) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        if (this.items.length === 0) {
          this.dialog.error = true;
          this.dialog.errorMessage = "Field list product must be filled";
        } else {
          await this.doAdd();
        }
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      const user = JSON.parse(localStorage.getItem("ismUser") || "{}");
      const engineerName = user.username || "Unknown";
      const now = new Date().toISOString();
      const customerName = this.param.customerName || "Client";

      let gasMeter = this.items.filter((x) => x.orderProduct === "Gas Meter");
      let utiMeter = this.items.filter((x) => x.orderProduct === "UTI Meter");
      let others = this.items.filter((x) => x.orderProduct === "Others");
      let productDesc = [];

      if (gasMeter.length > 0) productDesc.push("Gas Meter");
      if (utiMeter.length > 0) productDesc.push("Uti Meter");
      if (others.length > 0) productDesc.push("Others");

      try {
        const docRef = ordersCollection.doc(this.param.orderNo.orderId);
        const docSnap = await docRef.get();

        if (!docSnap.exists) {
          this.dialog.error = true;
          this.dialog.errorMessage = "Workform not found";
          return;
        }

        const oldData = docSnap.data();

        const newData = {
          ordersNo: this.param.ordersNo,
          customerId: this.param.orderNo.customerId,
          customerName: customerName,
          vesselId: this.param.orderNo.vesselId,
          vesselName: this.param.vesselName,
          statusCustomer: this.param.statusCustomer,
          description: this.param.orderNo.description,
          products: productDesc.toString(),
          productsList: this.items,
          statusOrder: 1,
          statusDesc: "Waiting for Client to approve Inspection",

          inspectionEngineer: engineerName,
          inspectionDate: now,

          isDeleted: false,

          createdAt: oldData.createdAt || now,
          createdBy: oldData.createdBy || engineerName,

          updatedAt: now,
          updatedBy: engineerName,

          inProgressBy: `Menunggu ${customerName} menerima hasil inspeksi`,
          inProgressAt: now,

          approvedBy: "",
          approvedAt: "",
          rejectedBy: "",
          rejectedAt: "",
          finalBy: "",
          finalAt: "",
          deletedBy: "",
          deletedAt: "",
        };

        await docRef.update(newData);
        this.dialog.success = true;
      } catch (err) {
        console.error("Update failed", err);
        this.dialog.error = true;
        this.dialog.errorMessage = "Failed to save data.";
      }
    },
    setDataOrder(item) {
      if (item === null) {
        this.param.ordersNo = "";
        this.param.customerName = "";
        this.param.vesselName = "";
        this.param.notes = "";
        this.param.statusCustomer = "";
        this.items = [];
      } else {
        this.param.ordersNo = item.ordersNo;
        this.param.customerName = item.customerName;
        this.param.vesselName = item.vesselName;
        this.param.notes = item.description;
        this.param.statusCustomer = item.statusCustomer ? "B2B" : "End User";
        this.items = item.productsList;
      }
    },
    editItem(item) {
      this.dataDetail = item;
      this.dialogWorkForm = true;
    },
    closeItem() {
      this.dialogWorkForm = false;
    },
    async saveItem() {
      const user = JSON.parse(localStorage.getItem("ismUser") || "{}");
      const engineerName = user.username || "Unknown";
      const now = new Date().toISOString();

      let gasMeter = this.items.filter((x) => x.orderProduct === "Gas Meter");
      let utiMeter = this.items.filter((x) => x.orderProduct === "UTI Meter");
      let others = this.items.filter((x) => x.orderProduct === "Others");
      let productDesc = [];

      if (gasMeter.length > 0) {
        productDesc.push("Gas Meter");
      }
      if (utiMeter.length > 0) {
        productDesc.push("Uti Meter");
      }
      if (others.length > 0) {
        productDesc.push("Others");
      }

      // DATA YANG AKAN DIKIRIM
      const newData = {
        ordersNo: this.param.ordersNo,
        customerId: this.param.orderNo.customerId,
        customerName: this.param.customerName,
        vesselId: this.param.orderNo.vesselId,
        vesselName: this.param.vesselName,
        statusCustomer: this.param.statusCustomer,
        description: this.param.orderNo.description,
        products: productDesc.toString(),
        productsList: this.items,
        statusOrder: 0,
        statusDesc: "Entry",

        inspectionEngineer: engineerName,
        inspectionDate: now,

        isDeleted: false,

        createdAt: now,
        createdBy: engineerName,

        updatedAt: "",
        updatedBy: "",

        inProgressBy: 'Data inspeksi belum di simpan',
        inProgressAt: now,

        approvedBy: "",
        approvedAt: "",
        rejectedBy: "",
        rejectedAt: "",
        finalBy: "",
        finalAt: "",
        deletedBy: "",
        deletedAt: "",
      };
      ordersCollection
        .doc(this.param.orderNo.orderId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Customer not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
        });
      this.dialogWorkForm = false;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialogCRUD = false;
      this.formHasErrors = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    newItem() {
      Object.keys(this.form).forEach((f) => {
        if (
          f === "orderProduct" ||
          f === "orderProductType" ||
          f === "orderProductSeries" ||
          f === "orderProductSN" ||
          f === "notes"
        ) {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (!this.formHasErrors) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
        } else {
          this.items.push(this.editedItem);
        }
        this.close();
      } else {
        this.formHasErrors = false;
      }
    },
  },
};
</script>
