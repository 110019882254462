<template>
  <v-card flat>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="generateFileName(updateDataforPDF)"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="legal"
      :pdf-margin="1"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="pdfGasMeter"
    >
      <section slot="pdf-content">
        <ContentToPrintGasMeter
          :data="updateDataforPDF"
          v-if="updateDataforPDF && updateDataforPDF.productsList"
        />
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="generateFileName(updateDataforPDF)"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="legal"
      :pdf-margin="1"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      ref="pdfUtiMeter"
    >
      <section slot="pdf-content">
        <ContentToPrintUtiMeter
          :data="updateDataforPDF"
          v-if="updateDataforPDF && updateDataforPDF.productsList"
        />
      </section>
    </vue-html2pdf>

    <isttoolbar
      title="Certificates"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer></v-spacer>
    </isttoolbar>

    <v-card>
      <v-card-title class="d-flex flex-wrap align-center">
        <span class="text-h6">Certificate List</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="search-input"
          dense
        />
      </v-card-title>

      <v-data-table
        class="elevation-1 pointer-table"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        dense
      >
        <template v-slot:[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>

        <!-- <template v-slot:[`item.statusCustomer`]="{ item }">
          {{ item.statusCustomer ? "B2B" : "End User" }}
        </template> -->

        <template v-slot:[`item.statusDesc`]="{ item }">
          <ChipStatus :status="item.statusDesc" />
        </template>

        <!-- <template v-slot:[`item.statusOrder`]="{ item }">
          {{
            item.statusOrder === 1
              ? "In Progress"
              : item.statusOrder === 2
              ? "Approve"
              : item.statusOrder === 3
              ? "Reject"
              : item.statusOrder === 4
              ? "Final"
              : "Entry"
          }}
        </template> -->

        <template v-slot:[`item.poNumber`]="{ item }">
          <div class="text-center">
            <!-- Jika sudah ada poNumber dan statusOrder === 2 -->
            <v-chip
              v-if="item.poNumber && item.statusOrder === 2"
              color="grey"
              text-color="white"
              class="font-weight-medium"
              small
            >
              {{ item.poNumber }}
            </v-chip>

            <!-- Jika belum ada poNumber dan statusOrder === 2 -->
            <v-btn
              v-else-if="!item.poNumber && item.statusOrder === 2"
              icon
              color="primary"
              @click="openDialog(item)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <!-- Status Order === 5 -->
            <span
              v-else-if="item.statusOrder === 5"
              class="red--text font-weight-medium"
            >
              Purchase Order not finished yet
            </span>

            <!-- Status Order === 4 -->
            <span
              v-else-if="item.statusOrder === 4"
              class="green--text font-weight-medium"
            >
              {{ item.poNumber || "PO Confirmed" }}
            </span>

            <!-- Default: jika poNumber ada -->
            <p v-else-if="item.poNumber">Dokumen tidak diketahui</p>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="item.statusDesc === 'Final Order'"
                class="pointer"
                @click="generatePDF(item, index)"
              >
                <v-list-item-title>Create Certificate</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.statusDesc === 'PO not finished yet'"
                class="pointer"
                @click="clickApproveorReject($event, item)"
              >
                <v-list-item-title>Confirm Purchase Order</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.statusDesc === 'PO not finished yet'"
                class="pointer"
                @click="clickApproveorReject($event, item)"
              >
                <v-list-item-title>Reject Document</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:no-data>
          <v-alert type="info" text>No data available.</v-alert>
        </template>
      </v-data-table>
    </v-card>

    <!-- Your existing dialog code -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogVisible"
        persistent
        width="unset"
        min-width="290px"
      >
        <!-- Your dialog content -->
        <v-card min-width="290px">
          <v-card-title class="justify-center" style="word-break: inherit"
            >Input PO Number</v-card-title
          >
          <div class="pa-5">
            <v-text-field v-model="valuePO"> </v-text-field>
          </div>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              @click="cancelInputPO()"
              outlined
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              min-width="100px"
              @click="confirmInputPO(valuePO)"
              outlined
              >Confirm PO Number</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- DIALOG -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogDelete"
        persistent
        width="unset"
        min-width="290px"
      >
        <v-card min-width="290px">
          <v-card-title class="justify-center" style="word-break: inherit"
            >Are you sure want to Delete ?</v-card-title
          >
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              @click="
                deleteData();
                dialogDelete = false;
              "
              outlined
              >Yes</v-btn
            >
            <v-btn
              color="red"
              min-width="100px"
              @click="dialogDelete = false"
              outlined
              >No</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Deleted</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Deleted</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="resetDialog"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ContentToPrintGasMeter from "../../../components/pdfPrint/certificate_gas_pdf";
import ContentToPrintUtiMeter from "../../../components/pdfPrint/certificate_uti_pdf";
import ChipStatus from "@/components/chips/statusChip.vue";
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB, ordersCollection } from "@/firebase";

export default {
  components: {
    isttoolbar,
    VueHtml2pdf,
    ChipStatus,
    ContentToPrintGasMeter,
    ContentToPrintUtiMeter,
  },
  data: () => ({
    signatures: "",
    valuePO: "",
    dialogVisible: false,
    updateDataforPDF: {},
    search: "",
    loading: false,
    headers: [
      { text: "No", value: "index" },
      { text: "Order No", align: "start", value: "ordersNo" },
      { text: "Customer", value: "customerName" },
      { text: "Product", value: "products" },
      { text: "Status", value: "statusDesc", align: "center" },
      {
        text: "No Purchase Order (PO)",
        value: "poNumber",
        align: "center",
        class: "text-center",
      },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    items: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogDelete: false,
    item: {},
    param: {
      customerId: "",
      customerName: "",
      vesselId: "",
      vesselName: "",
      statusCustomer: true,
      description: "",
      products: "",
      productsList: [],
    },
    itemInDialog: null,
  }),
  watch: {},
  created() {
    this.getListData();
  },
  methods: {
    generateFileName(data) {
      if (!data) return "certificate";

      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());

      const ordersNo = data.ordersNo || "ORD";
      const vessel = data.vesselName?.replace(/\s+/g, "_") || "VESSEL";

      const productType = data.products === "Gas Meter" ? "Gas" : "UTI";
      return `${productType}-${ordersNo}-${vessel}-${day}${month}${year}`;
    },
    openDialog(item) {
      this.dialogVisible = true;
      this.itemInDialog = item;
    },
    cancelInputPO() {
      this.dialogVisible = false;
      this.itemInDialog = null;
      this.valuePO = "";
    },
    confirmInputPO(payload) {
      if (!payload) {
        return alert("isi dahulu PO nya");
      }

      this.itemInDialog.poNumber = payload;
      let newData = this.itemInDialog;
      firebaseDB
        .doc(`/orders/${this.itemInDialog.orderId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Order not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
          this.dialogVisible = false;
          this.itemInDialog = null;
          this.valuePO = "";
          this.getListData();
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    async generatePDF(val) {
      if (!val.poNumber) {
        this.dialog.show = true;
        this.dialog.error = true;
        this.dialog.errorMessage =
          "There is no PO number yet, please fill it in first";
        return;
      }

      if (!val.productsList || !Array.isArray(val.productsList)) {
        this.dialog.error = true;
        this.dialog.errorMessage = "productsList data is invalid";
        return;
      }

      const updatedData = {
        ...val,
        productsList: val.productsList.map((product) => {
          const filtered =
            product.inspectionReport?.filter((r) => r.checked) || [];
          return { ...product, inspectionReport: filtered };
        }),
      };

      this.updateDataforPDF = updatedData;

      if (val.products === "Uti Meter") {
        this.$refs.pdfUtiMeter.generatePdf();
      } else if (val.products === "Gas Meter") {
        this.$refs.pdfGasMeter.generatePdf();
      }
    },
    async clickApproveorReject(event, value) {
      const user = JSON.parse(localStorage.getItem("ismUser") || "{}");
      const engineerName = user.username || "Unknown";
      const now = new Date().toISOString();

      // Confirm Approv Purchase Order
      if (event.target.textContent.includes("Confirm Purchase Order")) {
        value.statusOrder = 2;
        value.statusDesc = "Final Order";

        try {
          await firebaseDB.doc(`/orders/${value.orderId}`).update({
            statusOrder: value.statusOrder,
            statusDesc: value.statusDesc,
            updatedAt: now,
            updatedBy: engineerName,
          });

          this.dialog.success = true;
          this.getListData();
        } catch (error) {
          this.dialog.error = true;
          this.dialog.errorMessage = "Failed to update PO approval.";
        }
      }

      // Reject (opsional)
      if (event.target.textContent.includes("Reject Document")) {
        value.statusOrder = 7;
        value.statusDesc = "Rejected";

        try {
          await firebaseDB.doc(`/orders/${value.orderId}`).update({
            statusOrder: value.statusOrder,
            statusDesc: value.statusDesc,
            rejectedAt: now,
            rejectedBy: engineerName,
            updatedAt: now,
            updatedBy: engineerName,
          });

          this.dialog.success = true;
          this.getListData();
        } catch (error) {
          this.dialog.error = true;
          this.dialog.errorMessage = "Failed to update PO approval.";
        }
      }
    },
    resetDialog() {
      this.dialog = {
        show: false,
        success: false,
        error: false,
        errorMessage: "",
      };
      this.item = {};
    },
    clickDelete(item) {
      this.dialogDelete = true;
      this.item = item;
    },
    deleteData() {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;
      const newData = {
        isDeleted: true,
        deletedAt: new Date().toISOString(),
        deletedBy: "Admin",
      };
      firebaseDB
        .doc(`/orders/${this.item.orderId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Order not found";
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialog.success = true;
          this.getListData();
        })
        .catch(() => {
          this.dialog.error = true;
          this.dialog.errorMessage = "Something Went Wrong";
        });
    },

    getListData() {
      this.loading = true;
      ordersCollection
        .where("statusDesc", "in", ["Final Order", "PO not finished yet"])
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderId: doc.id,
              ordersNo: doc.data().ordersNo,
              customerId: doc.data().customerId,
              customerName: doc.data().customerName,
              vesselId: doc.data().vesselId,
              vesselName: doc.data().vesselName,
              statusCustomer: doc.data().statusCustomer,
              description: doc.data().description,
              products: doc.data().products,
              productsList: doc.data().productsList
                ? doc.data().productsList
                : [],
              poNumber: doc.data().poNumber,
              statusOrder: doc.data().statusOrder,
              statusDesc: doc.data().statusDesc,
              createdAt: doc.data().createdAt,
            });
          });
          this.loading = false;

          this.items = newData.sort(function (a, b) {
            return a.createdAt > b.createdAt
              ? -1
              : a.createdAt < b.createdAt
              ? 1
              : 0;
          });

          this.param = this.items;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.pointer-table {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  background: #eeeeee;
}
</style>
