<template>
  <div>
    <v-card>
      <isttoolbar
        title="Detail Order"
        dark
        color="secondary"
        icon="mdi-clipboard-text"
      />
      <v-card-text>
        <v-col cols="3">
          <v-text-field
            id="ordernumber"
            disabled
            label="Order Number"
            :value="dataDetails.ordersNo ? dataDetails.ordersNo : '-'"
          >
          </v-text-field>
        </v-col>
        <v-row class="pa-4">
          <v-col>
            <v-text-field
              id="customername"
              disabled
              label="Customer Name"
              :value="dataDetails.customerName ? dataDetails.customerName : '-'"
            >
            </v-text-field>
            <v-text-field
              id="companyname"
              disabled
              label="Company Name"
              :value="dataDetails.companyName ? dataDetails.companyName : '-'"
            >
            </v-text-field>
            <v-text-field
              id="orderstart"
              disabled
              label="Order Start"
              :value="
                dataDetails.createdAt
                  ? formatCustomDate(dataDetails.createdAt)
                  : '-'
              "
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              id="statusorder"
              disabled
              label="Status Order"
              :value="dataDetails.statusDesc ? dataDetails.statusDesc : '-'"
            >
            </v-text-field>
            <v-text-field
              id="vesselname"
              disabled
              label="Vessel Name"
              :value="dataDetails.vesselName ? dataDetails.vesselName : '-'"
            >
            </v-text-field>
            <v-text-field
              id="orderfinish"
              disabled
              label="Order Finish"
              :value="dataDetails.finalAt ? dataDetails.finalAt : '-'"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="dataDetails.productsList"
          :loading="loading"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.no`]="{ index }">
            <p>{{ index + 1 }}</p>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <!-- Jika produk adalah Gas Meter -->
            <div v-if="item.orderProduct === 'Gas Meter'">
              <dl v-if="item.listGas && item.listGas.length">
                <dt
                  class="text-caption"
                  v-for="(gas, index) in item.listGas"
                  :key="'gas-' + index"
                >
                  • {{ gas.gasType }}
                </dt>
              </dl>
            </div>

            <!-- Jika produk adalah UTI Meter -->
            <div v-else-if="item.orderProduct === 'UTI Meter'">
              <p v-if="item.tapeLength">• Tape Length: {{ item.tapeLength }}</p>
              <p v-if="item.valveConnector">
                • Valve Connector: {{ item.valveConnector }}
              </p>
              <dl v-if="getFilteredApparatus(item).length">
                <dt
                  class="text-caption"
                  v-for="(app, index) in getFilteredApparatus(item)"
                  :key="'app-' + index"
                >
                  • Apparatus: {{ app.apparatus }}<br />
                  • Model: {{ app.model }}
                </dt>
              </dl>
            </div>

            <!-- Produk lainnya -->
            <div v-else>
              <p>-</p>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn width="300" variant="text" @click="closeDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
export default {
  name: "AdminIndosafetymarineOrderDetailByCustomerAccount",
  components: {
    isttoolbar,
  },
  props: {
    dataDetails: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Product", value: "orderProduct" },
        { text: "Series", value: "orderProductSeries" },
        { text: "Type", value: "orderProductType" },
        { text: "Serial Number", value: "orderProductSN" },
        { text: "Details", value: "details" },
      ],
      loading: false,
    };
  },

  mounted() {},

  methods: {
    closeDialog() {
      this.$emit("closeDialogForm");
    },
    formatCustomDate(isoDateString) {
      const dateObject = new Date(isoDateString);
      const day = dateObject.getDate();
      const month = dateObject.toLocaleString("en-US", { month: "short" });
      const year = dateObject.getFullYear();

      return `${day} ${month} ${year}`;
    },
    getFilteredApparatus(item) {
      return (item.apparatusList || []).filter((a) => a.apparatus && a.model);
    },
  },
};
</script>
