import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {}, // Kosong, karena akan diisi dari komponen
  id: {}  // Kosong, karena akan diisi dari komponen
};

const i18n = new VueI18n({
  locale: 'id', // Bahasa default
  fallbackLocale: 'en', // Jika tidak ada terjemahan, gunakan bahasa Inggris
  messages
});

// ✅ Tambahkan metode untuk menambahkan teks secara dinamis
i18n.addTranslation = function (lang, key, value) {
  if (!this.messages[lang]) {
    Vue.set(this.messages, lang, {}); // Pastikan bahasa tersedia
  }
  Vue.set(this.messages[lang], key, value);
};

export default i18n;