<template>
  <v-card flat>
    <isttoolbar
      title="User Management"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer></v-spacer>
      <v-btn outlined color="white" @click="goToAdd">
        <v-icon left>mdi-plus</v-icon>Add User
      </v-btn>
    </isttoolbar>
    <v-card>
      <v-card-title>
        List User
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>

        <template v-slot:[`item.isActive`]="{ item }">
          <span :class="item.isActive ? 'status-active' : 'status-inactive'">
            {{ item.isActive ? "Active" : "Inactive" }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="pointer" @click="clickView(item)">
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickEdit(item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.isActive"
                class="pointer"
                @click="clickInactive(item)"
              >
                <v-list-item-title>Inactive</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!item.isActive"
                class="pointer"
                @click="clickDelete(item)"
              >
                <v-list-item-title>Delete User</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:no-data></template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog.show" persistent max-width="300">
      <v-card>
        <v-card-title class="text-h6 justify-center">
          {{ dialog.success ? "Success" : "Error" }}
        </v-card-title>
        <v-card-text class="text-center">
          {{
            dialog.success
              ? "Operation completed successfully."
              : dialog.errorMessage
          }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="dialog.show = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { usersCollection, firebaseDB } from "../../../firebase";

export default {
  components: {
    isttoolbar,
  },
  data: () => ({
    search: "",
    loading: false,
    dialog: {
      show: false,
      success: false,
      error: false,
      errorMessage: "",
    },
    headers: [
      { text: "No", value: "index" },
      { text: "Name", align: "start", value: "name" },
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Role", value: "role" },
      { text: "Status", value: "isActive" },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],
    items: [],
  }),
  watch: {},
  created() {
    // this.deleteUsers();
    // this.checkEmailAndListUsers();

    this.loading = true;
    usersCollection
      .orderBy("createdAt", "desc")
      .get()
      .then((data) => {
        let users = [];
        data.forEach((doc) => {
          if (doc.data().role !== "Customer") {
            users.push({
              userId: doc.data().userId,
              name: doc.data().name,
              email: doc.data().email,
              username: doc.data().username,
              role: doc.data().role,
              isActive: doc.data().isActive,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          }
          this.loading = false;
          this.items = users;
        });
      })
      .catch((err) => {
        this.loading = false;
        console.debug(err, "users list");
      });
  },
  methods: {
    goToAdd() {
      this.$router.replace({ name: "user-management-add" });
    },
    clickView(item) {
      this.$router.replace({
        name: "user-management-edit",
        query: { username: item.username, statusPage: "view" },
      });
    },
    clickEdit(item) {
      this.$router.replace({
        name: "user-management-edit",
        query: { username: item.username, statusPage: "edit" },
      });
    },
    clickInactive(item) {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;

      firebaseDB
        .collection("users")
        .where("userId", "==", item.userId)
        .limit(1)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            this.dialog.error = true;
            this.dialog.errorMessage = "User not found.";
            return;
          }

          snapshot.forEach(async (doc) => {
            await doc.ref.update({
              isActive: false,
              updatedAt: new Date().toISOString(),
              updatedBy: "Admin",
            });
          });

          this.dialog.success = true;
          this.getListData(); // Refresh data list
        })
        .catch((error) => {
          this.dialog.error = true;
          this.dialog.errorMessage = error.message || "Something went wrong.";
        });
    },
    clickDelete(item) {
      this.dialog.success = false;
      this.dialog.error = false;
      this.dialog.show = true;

      firebaseDB
        .collection("users")
        .where("userId", "==", item.userId)
        .limit(1)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            this.dialog.error = true;
            this.dialog.errorMessage = "User not found.";
            return;
          }

          snapshot.forEach(async (doc) => {
            await doc.ref.delete().then(() => {
              this.dialog.success = true;
              this.getListData(); // Refresh data list
            });
          });
        })
        .catch((error) => {
          this.dialog.error = true;
          this.dialog.errorMessage = error.message || "Something went wrong.";
        });
    },
    getListData() {
      this.loading = true;
      usersCollection
        .orderBy("createdAt", "desc")
        .get()
        .then((data) => {
          let users = [];
          data.forEach((doc) => {
            if (doc.data().role !== "Customer") {
              users.push({ ...doc.data(), userId: doc.data().userId });
            }
          });
          this.items = users;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.dialog.show = true;
          this.dialog.error = true;
          this.dialog.errorMessage = err.message || "Failed to load user data.";
        });
    },

    // async checkEmailAndListUsers() {
    //   try {
    //     const snapshot = await firebaseDB.collection("users").get();
    //     const users = [];
    //     let emailExists = false;

    //     snapshot.forEach((doc) => {
    //       const user = doc.data();
    //       users.push(user);

    //       if (user.email === "rizkyak994@gmail.com") {
    //         emailExists = true;
    //       }
    //     });

    //     console.log("All Users:", users);
    //     console.log(
    //       emailExists
    //         ? "✅ Email rizkyak994@gmail.com already exists."
    //         : "❌ Email not found."
    //     );
    //   } catch (error) {
    //     console.error("Error checking email:", error.message);
    //   }
    // },

    // async deleteUsers() {
    //   const userIds = [
    //     "107481401196785663180",
    //     "E9mj2w6NVZgZzRZs7yHcluK6Rnz1",
    //     "101798479628762930218",
    //   ];

    //   try {
    //     for (const id of userIds) {
    //       await firebaseDB
    //         .collection("users")
    //         .where("userId", "==", id)
    //         .get()
    //         .then((snapshot) => {
    //           if (snapshot.empty) {
    //             console.warn(`No user found with ID: ${id}`);
    //             return;
    //           }
    //           snapshot.forEach((doc) => {
    //             doc.ref.delete().then(() => {
    //               console.log(`Deleted user with ID: ${id}`);
    //             });
    //           });
    //         });
    //     }
    //   } catch (error) {
    //     console.error("Error deleting users:", error);
    //   }
    // },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.pointer:hover {
  background: #eeeeee;
}

.status-active {
  color: #4caf50;
  font-weight: bold;
}

.status-inactive {
  color: #f44336;
  font-weight: bold;
}
</style>
