<template>
  <v-card flat>
    <isttoolbar
      title="Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      title="Add Option Product"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form>
      <v-row class="ml-2" dense align="center" justify="space-around">
        <!-- Type Product -->
        <v-col cols="12" md="4" class="pa-1">
          <span class="title"
            >Type Product : <span class="error--text">*</span></span
          >
          <v-menu v-model="menuOpen" :close-on-content-click="false">
            <template v-slot:activator="{ on, props }">
              <div class="btn-wrapper">
                <v-btn v-on="on" v-bind="props" block>
                  {{ selectedValue || "Select Type Product" }}
                </v-btn>
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <!-- Product Name -->
        <v-col cols="12" md="4" class="pa-1">
          <span class="title"
            >Product : <span class="error--text" style="font-style: italic;">*</span></span
          >
          <v-text-field
            hide-details="auto"
            label="Pilih produk dari tabel List Product"
            ref="productName"
            item-value="productName"
            item-text="productName"
            :value="param.productName.paramsGroup"
            solo
            flat
            outlined
            dense
            class="ma-0"
            disabled
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>

      <v-row align="center" class="ml-2"> </v-row>
      <br />
      <v-card-title>
        List Product <br />
        <small class="ml-2 mobile-hide" style="color: gray; font-style: italic">
          *Pilih product dalam table untuk menampilkan product ke dalam pilihan
          product order</small
        >
        <v-spacer> </v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search by name"
          single-line
          hide-details
          dense
          class="ma-0"
          style="max-width: 300px"
        />
      </v-card-title>
      <v-data-table
        class="elevation-1 pa-8"
        :headers="headers"
        :items="filteredItems"
        :loading="loading"
        :items-per-page="5"
        @click:row="handleClick"
        style="cursor: pointer"
      >
        <template v-slot:[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>
      </v-data-table>
    </v-form>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
// import { paramsCollection } from "../../../firebase";
import { paramsCollection } from "../../../firebase";
// import { ordersCollection } from "@/firebase";

export default {
  name: "AdminIndosafetymarineOptionProductAdd",
  components: {
    isttoolbar,
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "No", value: "index" },
      {
        text: "Merk Product",
        align: "start",
        value: "paramsGroup",
      },
      { text: "Type Product", value: "params" },
    ],
    itemsOptionProduct: [],
    items: ["Gas Meter", "UTI Meter"],
    selectedValue: null,
    selectedValueProduct: null,
    menuOpen: false,
    menuOpenListProduct: false,
    param: {
      productName: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
  }),
  computed: {
    form() {
      return {
        productName: this.param.productName,
      };
    },
    filteredItems() {
      const keyword = this.search.toLowerCase();
      return this.itemsOptionProduct.filter((item) => {
        return (
          item.paramsGroup?.toLowerCase().includes(keyword) ||
          item.params?.toLowerCase().includes(keyword)
        );
      });
    },
  },
  created() {
    this.dialog.success = false;
    this.dialog.error = false;
    this.getListData();
  },
  methods: {
    getListData() {
      this.loading = true;
      paramsCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              paramsGroup: doc.data().paramsGroup,
              params: doc.data().params,
              configured: false,
            });
          });
          this.loading = false;

          this.itemsOptionProduct = newData
            .filter(
              (item) =>
                item.paramsGroup !== "Work Form" &&
                item.paramsGroup !== "Order Product" &&
                item.paramsGroup !== "Orders Product Type" &&
                item.paramsGroup !== "Orders"
            )
            .sort(function (a, b) {
              return a.createdAt > b.createdAt
                ? -1
                : a.createdAt < b.createdAt
                ? 1
                : 0;
            });
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },
    handleClick(value) {
      this.param.productName = value;
    },
    selectItem(item) {
      this.selectedValue = item;
      this.menuOpen = false;
      // this.getListData();
    },
    back() {
      this.$router.replace({
        name: "parameter-configuration-list",
      });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (f === "productName") {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        }
      });
      if (this.selectedValue == null) {
        this.dialog.show = true;
        this.dialog.error = true;
        this.dialog.errorMessage = "Pilih Type Product Anda !";
      }

      if (!this.formHasErrors && this.selectedValue != null) {
        this.dialog.success = false;
        this.dialog.error = false;
        this.dialog.show = true;
        await this.doAdd();
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      paramsCollection
        .where("paramsGroup", "==", "Orders Product Type")
        .get()
        .then((data) => {
          data.forEach((doc) => {
            if (doc.data().params == `${this.selectedValue}`) {
              const newData = JSON.parse(doc.data().values);

              // cek duplikat data
              let result = newData.filter(
                (item) =>
                  item.orderProductType === this.param.productName.paramsGroup
              );
              if (result.length > 0) {
                this.dialog.error = true;
                this.dialog.errorMessage =
                  "Maaf duplikat data, product ini sudah ada";
                return;
              } else {
                newData.push({
                  orderProductType: `${this.param.productName.paramsGroup}`,
                });
                const newDataString = JSON.stringify(newData);
                return doc.ref.update({ values: newDataString });
              }
            } else {
              console.log("Dokumen tidak ditemukan");
            }
          });
        })
        .then(async () => {
          this.dialog.success = true;
        })
        .catch((error) => {
          console.error("Error updating customer:", error.message);
          this.dialog.error = true;
          this.dialog.errorMessage = "Something went wrong";
        });
    },
  },
};
</script>

<style scoped>
/* Padding dan layout */
@media (max-width: 960px) {
  .v-card-title {
    font-size: 16px;
    flex-wrap: wrap;
    text-align: center;
  }

  .v-text-field {
    font-size: 14px;
  }

  .v-btn {
    font-size: 14px;
    min-width: 100px !important;
  }

  .v-data-table {
    font-size: 13px;
  }

  .ma-0,
  .pa-8 {
    padding: 8px !important;
    margin: 0 !important;
  }

  .ml-2,
  .pr-8 {
    margin-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* Tampilan untuk layar mobile sangat kecil */
@media (max-width: 600px) {
  .btn-wrapper {
    display: flex;
    justify-content: center;
  }

  .mobile-hide {
    display: none !important;
  }

  .v-card-title {
    font-size: 14px;
  }

  .v-btn {
    font-size: 12px;
    padding: 6px 10px;
  }

  .v-text-field {
    font-size: 13px;
  }

  .v-data-table {
    font-size: 12px;
  }

  .title {
    font-size: 14px;
    display: block;
    margin-bottom: 4px;
  }

  .v-col {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
</style>
