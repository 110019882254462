<template>
  <v-dialog v-model="localDialog" persistent max-width="600px">
    <v-form ref="form">
      <v-card>
        <isttoolbar
          title="Edit Apparatus"
          dark
          color="secondary"
          icon="mdi-cogs"
        />
        <v-container>
          <!-- Apparatus Field -->
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">Apparatus</span>
            </v-col>
            <v-col cols="8" class="pa-1">
              <v-text-field
                v-model="apparatus"
                :readonly="isViewMode"
                hide-details="auto"
                solo
                flat
                outlined
                dense
                class="ma-0"
                :error="noInput && !apparatus"
                :error-messages="!apparatus ? 'This field is required' : ''"
              />
            </v-col>
          </v-row>

          <!-- Manufacture / Model Field -->
          <v-row align="center" class="mr-2 ml-2">
            <v-col cols="4" class="pa-1">
              <span class="title">Manufacture / Model</span>
            </v-col>
            <v-col cols="8" class="pa-1">
              <v-text-field
                v-model="model"
                :readonly="isViewMode"
                hide-details="auto"
                solo
                flat
                outlined
                dense
                class="ma-0"
                :error="noInput && !model"
                :error-messages="!model ? 'This field is required' : ''"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            outlined
            @click="saveEditApparatus"
            v-if="!isViewMode"
          >
            Save
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="cancel">
            {{ isViewMode ? 'Close' : 'Cancel' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";

export default {
  components: {
    isttoolbar,
  },
  props: {
    dialog: Boolean,
    data: Object,
  },
  data() {
    return {
      apparatus: "",
      model: "",
      noInput: false,
      localDialog: false,
    };
  },
  computed: {
    isViewMode() {
      return this.$route.query.statusPage === "view";
    },
  },
  watch: {
    dialog: {
      immediate: true,
      handler(value) {
        this.localDialog = value;
        if (value && this.data) {
          this.apparatus = this.data.apparatus || "";
          this.model = this.data.model || "";
        }
      },
    },
    localDialog(val) {
      this.$emit("update:dialog", val);
    },
  },
  methods: {
    saveEditApparatus() {
      if (!this.apparatus.trim() || !this.model.trim()) {
        this.noInput = true;
        return;
      }
      this.noInput = false;
      this.data.apparatus = this.apparatus;
      this.data.model = this.model;
      this.$emit("save");
    },
    cancel() {
      this.localDialog = false;
      this.$emit("cancel");
    },
  },
};
</script>