<template>
  <v-card flat>
    <isttoolbar
      title="User Management"
      dark
      color="secondary"
      icon="mdi-folder-account-outline"
    >
      <v-spacer />
      <v-btn @click="save" outlined class="mx-1" min-width="100px">Save</v-btn>
      <v-btn @click="back" outlined class="mx-1" min-width="100px"
        >Cancel</v-btn
      >
    </isttoolbar>
    <isttoolbar
      title="Create User"
      class="mb-6"
      dark
      color="primary"
    ></isttoolbar>
    <v-form>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Role
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-autocomplete
            hide-details="auto"
            label="Select Role"
            @input="setDataRole"
            v-model="param.role"
            :items="dataRole"
            item-value="role"
            item-text="role"
            ref="role"
            class="ma-0"
            return-object
            flat
            outlined
            dense
            solo
            clearable
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Name
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Name"
            ref="name"
            v-model="param.name"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Username
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Username"
            ref="username"
            v-model="param.username"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Email
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            hide-details="auto"
            label="Email"
            ref="email"
            v-model="param.email"
            solo
            flat
            outlined
            dense
            class="ma-0"
            :rules="[rules.email]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Password
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            label="Password"
            ref="password"
            class="ma-0"
            flat
            outlined
            dense
            solo
            name="password"
            hide-details="auto"
            v-model="param.password"
            :value="param.password"
            :type="statusPassword ? 'password' : 'text'"
            :append-icon="statusPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (statusPassword = !statusPassword)"
            @input="(_) => (param.password = _)"
            :rules="[rules.required, rules.password]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="ml-2">
        <v-col cols="2" class="pa-1">
          <span class="title">
            Confirm Password
            <span class="error--text">*</span>
          </span>
        </v-col>
        <v-col cols="5" align-self="start" class="pa-1">
          <v-text-field
            label="Confirm Password"
            ref="confirmPassword"
            class="ma-0"
            flat
            outlined
            dense
            solo
            name="confirmPassword"
            hide-details="auto"
            v-model="param.confirmPassword"
            :value="param.confirmPassword"
            :type="statusConfirm ? 'password' : 'text'"
            :append-icon="statusConfirm ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (statusConfirm = !statusConfirm)"
            @input="(_) => (param.confirmPassword = _)"
            :rules="[rules.required, rules.password]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center">
      <v-dialog v-model="dialog.show" persistent max-width="290">
        <v-card v-show="!dialog.success && !dialog.error">
          <v-card-title>
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-title>
        </v-card>
        <v-card v-show="dialog.success || dialog.error">
          <v-card-title
            v-if="dialog.success"
            class="justify-center"
            style="word-break: inherit"
            >Your Data has been Saved</v-card-title
          >
          <v-card-title
            v-else-if="dialog.error"
            class="justify-center"
            style="word-break: inherit"
            >Your Data Failed to Saved</v-card-title
          >
          <v-card-text
            v-if="dialog.error"
            class="d-flex justify-center error--text"
            style="word-break: inherit"
          >
            {{ dialog.errorMessage }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              min-width="100px"
              outlined
              @click="dialog.success ? back() : (dialog.show = false)"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB, firebaseAuth } from "../../../firebase";

export default {
  components: {
    isttoolbar,
  },
  props: {
    source: String,
  },

  data: () => ({
    statusUserTaken: false,
    statusPassword: true,
    statusConfirm: true,
    dataRole: [{ role: "Admin" }, { role: "Engineer" }, { role: "Staff" }],
    param: {
      role: "",
      name: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    formHasErrors: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      password: (value) => {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 characters with at least one capital letter, a number and a special character."
        );
      },
    },
    dialog: { show: false, success: false, error: false, errorMessage: "" },
  }),
  computed: {
    form() {
      return {
        role: this.param.role,
        name: this.param.name,
        username: this.param.username,
        email: this.param.email,
        password: this.param.password,
        confirmPassword: this.param.confirmPassword,
      };
    },
  },
  created() {},
  methods: {
    setDataRole(item) {
      if (item === null) {
        this.param.role = "";
      } else {
        this.param.role = item.role;
      }
    },
    back() {
      this.$router.replace({
        name: "user-management-list",
      });
    },
    async save() {
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        if (this.param.password !== this.param.confirmPassword) {
          this.dialog.show = true;
          this.dialog.error = true;
          this.dialog.errorMessage = "Password does not match";
        } else {
          this.dialog.success = false;
          this.dialog.error = false;
          this.dialog.show = true;
          await this.doAdd();
        }
      } else {
        this.formHasErrors = false;
      }
    },
    async doAdd() {
      const newData = {
        email: this.param.email,
        password: this.param.password,
        confirmPassword: this.param.confirmPassword,
        username: this.param.username,
        name: this.param.name,
        role: this.param.role,
        isActive: true,
        createdAt: new Date().toISOString(),
        createdBy: "Admin",
        updatedBy: "",
        updatedAt: "",
      };
      firebaseDB
        .doc(`/users/${newData.username}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.statusUserTaken = true;
          } else {
            return firebaseAuth.createUserWithEmailAndPassword(
              newData.email,
              newData.password
            );
          }
        })
        .then((data) => {
          let userId = data.user.uid;
          newData.userId = userId;
          return firebaseDB.doc(`/users/${newData.username}`).set(newData);
        })
        .then(() => {
          this.dialog.success = true;
        })
        .catch((error) => {
          if (this.statusUserTaken) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Username is already taken";
          } else if (error.code === "auth/email-already-in-use") {
            this.dialog.error = true;
            this.dialog.errorMessage = "Email is already in use";
          } else if (error.code === "auth/invalid-email") {
            this.dialog.error = true;
            this.dialog.errorMessage = "Invalid email format";
          } else if (error.code === "auth/weak-password") {
            this.dialog.error = true;
            this.dialog.errorMessage = "Password is too weak";
          } else {
            this.dialog.error = true;
            this.dialog.errorMessage = error.message || "Something went wrong";
          }
        });
    },
  },
};
</script>
