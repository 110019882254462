<template>
  <v-card flat>
    <isttoolbar
      title="Parameter Configuration"
      dark
      color="secondary"
      icon="mdi-cog-outline"
    >
      <v-spacer></v-spacer>
      <v-row class="toolbar-actions" align="center" justify="end" dense>
        <!-- Tombol pertama -->
        <v-btn
          class="mr-2"
          tonal
          color="light-blue"
          @click="goToAdd"
          variant="tonal"
        >
          <v-icon left>mdi-plus</v-icon>
          <!-- Teks tombol hanya tampil di layar besar -->
          <span class="d-none d-sm-inline">Add Parameter Product</span>
        </v-btn>

        <!-- Tombol kedua -->
        <v-btn
          class="mr-2"
          tonal
          color="blue"
          @click="goToAdd('option-product-add')"
          variant="tonal"
        >
          <v-icon left>mdi-plus</v-icon>
          <!-- Teks tombol hanya tampil di layar besar -->
          <span class="d-none d-sm-inline">Add Option Product Type</span>
        </v-btn>
      </v-row>
    </isttoolbar>

    <v-card class="pa-5" elevation="5">
      <v-row class="mb-4" dense>
        <!-- Row 1: List Option Product & List Option Product Type UTI Meter -->
        <v-col cols="12" md="12">
          <v-card-title>
            List Option Product
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            class="elevation-1 custom-header-gray"
            :headers="headersOptionProduct"
            :items="itemsOptionProduct"
            :search="search"
            :loading="loading"
          >
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
          </v-data-table>
        </v-col>

        <!-- <v-col cols="12" md="6">
          <v-card-title>
            List Option Product Type UTI Meter
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            class="elevation-1 custom-header-gray"
            :headers="headersOptionProductTypeUTI"
            :items="itemsOptionProductTypeUTI"
            :search="search"
            :loading="loading"
            :items-per-page="2"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                small
                color="red"
                @click="clickDeleteList(item, 'UTI')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col> -->

        <!-- Row 2: List Option Product Type Gas Meter & List Option Product Others -->
        <v-col cols="12" md="6">
          <v-card-title>
            List Option Product Type Gas Meter
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            class="elevation-1 custom-header-gray"
            :headers="headersOptionProductTypeGas"
            :items="itemsOptionProductTypeGas"
            :search="search"
            :loading="loading"
            :items-per-page="2"
          >
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                small
                color="red"
                @click="clickDeleteList(item, 'GAS')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" md="6">
          <v-card-title>
            List Option Product Type UTI Meter
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            class="elevation-1 custom-header-gray"
            :headers="headersOptionProductTypeUTI"
            :items="itemsOptionProductTypeUTI"
            :search="search"
            :loading="loading"
            :items-per-page="2"
          >
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                small
                color="red"
                @click="clickDeleteList(item, 'UTI')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>

        <!-- <v-col cols="12" md="6">
          <v-card-title>
            List Option Product Others
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            class="elevation-1 custom-header-gray"
            :headers="headersOptionProductOthers"
            :items="itemsOptionProductTypeOthers"
            :search="search"
            :loading="loading"
            :items-per-page="3"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                small
                color="red"
                @click="clickDeleteList(item, 'OTHER')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col> -->
      </v-row>

      <v-divider></v-divider>

      <v-card-title>
        List All Product
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 custom-header-gray"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      >
        <template v-slot:[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.series`]="{ item }">
          {{ item.listGas ? "Gas Detector" : "UTI Meter" }}
        </template>

        <!-- Column Certificate  -->
        <template v-slot:[`item.certificate`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            style="color: green"
            v-if="item.certificate === 'OK'"
          ></span>
          <span class="mdi mdi-close" style="color: red" v-else></span>
        </template>

        <!-- Column GAS / Apparatus list  -->
        <!-- <template v-slot:[`item.listGas`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            v-if="item.certificate === 'OK'"
          ></span>
          <span class="mdi mdi-close" v-else></span>
        </template> -->

        <template v-slot:[`item.listGas`]="{ item }">
          <template v-if="item.listGas === 'OK'">
            <span
              class="mdi mdi-check-bold"
              style="color: green"
              title="Gas Detector"
            ></span>
          </template>
          <template v-else-if="hasApparatus(item.values)">
            <span
              class="mdi mdi-check-bold"
              style="color: green"
              title="UTI Meter"
            ></span>
          </template>
          <template v-else>
            <span
              class="mdi mdi-close"
              style="color: red"
              title="No Data"
            ></span>
          </template>
        </template>

        <!-- Column Calibration list  -->
        <template v-slot:[`item.listCalibration`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            style="color: green"
            v-if="item.listCalibration === 'OK'"
          ></span>
          <span class="mdi mdi-close" style="color: red" v-else></span>
        </template>

        <!-- Column Inspection Report  -->
        <template v-slot:[`item.inspectionReport`]="{ item }">
          <span
            class="mdi mdi-check-bold"
            style="color: green"
            v-if="item.inspectionReport === 'OK'"
          ></span>
          <span class="mdi mdi-close" style="color: red" v-else></span>
        </template>

        <!-- Column Status  -->
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            :color="item.status ? 'green' : 'red'"
            text-color="white"
            small
            class="ma-1"
          >
            {{ item.status ? "Active" : "Inactive" }}
          </v-chip>
        </template>

        <!-- Action Table  -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left :offset-x="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="pointer" @click="clickView(item)">
                <v-list-item-title>View</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickEdit(item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer" @click="clickDelete(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data></template>
      </v-data-table>
    </v-card>

    <!-- // DIALOG DELETE LIST -->
    <v-dialog v-model="dialogDelete" max-width="400">
      <v-card>
        <v-card-title class="text-h6 text-center justify-center">
          Konfirmasi Hapus
        </v-card-title>
        <v-card-text class="text-center">
          Apakah kamu yakin ingin menghapus parameter
          <strong>{{ item.params }}</strong
          >?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="grey" text @click="closeDelete">Batal</v-btn>
          <v-btn color="red" text @click="deleteItemConfirm">Hapus</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- // DIALOG DELETE LIST (for Option Product Type) -->
    <v-dialog v-model="dialogDeleteList" max-width="400">
      <v-card>
        <v-card-title class="text-h6 text-center justify-center">
          Konfirmasi Hapus
        </v-card-title>
        <v-card-text class="text-center">
          Apakah kamu yakin ingin menghapus
          <strong>{{ item.orderProductType }}</strong>
          dari
          <strong>
            {{
              item.type === "GAS"
                ? "Option Product Type - Gas Meter"
                : item.type === "UTI"
                ? "Option Product Type - UTI Meter"
                : item.type === "OTHERS"
                ? "Option Product Type - Others"
                : "Option"
            }}
          </strong>
          ?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="grey" text @click="closeDeleteList">Batal</v-btn>
          <v-btn color="red" text @click="deleteOptionListConfirm">Hapus</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="dialog.success" timeout="3000" color="green" top right>
      Berhasil menghapus data.
    </v-snackbar>
    <v-snackbar v-model="dialog.error" timeout="3000" color="red" top right>
      {{ dialog.errorMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
import isttoolbar from "@/components/toolbars/ISTtoolbar";
import { firebaseDB, paramsCollection } from "../../../firebase";
export default {
  components: {
    isttoolbar,
  },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "No", value: "index", width: "50px" },
      { text: "Series", value: "series", width: "50px" },
      {
        text: "Merk",
        align: "start",
        value: "paramsGroup",
        width: "130px",
      },
      { text: "Type", value: "params", width: "130px" },
      { text: "Certificate", value: "certificate", width: "80px" },
      { text: "Gas/Appertus List", value: "listGas", width: "80px" },
      { text: "Calibration List", value: "listCalibration", width: "80px" },
      { text: "Inspection Report", value: "inspectionReport", width: "80px" },
      { text: "Status", value: "status", width: "50px" },
      { text: "Actions", value: "actions", width: "50px", sortable: false },
    ],
    headersOptionProduct: [
      { text: "No", value: "index", width: "50px" },
      { text: "Product Name", value: "orderProduct", align: "center" },
    ],
    headersOptionProductTypeUTI: [
      { text: "No", value: "index", width: "50px" },
      { text: "Type", value: "orderProductType", align: "center" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "100px",
      },
    ],
    headersOptionProductTypeGas: [
      { text: "No", value: "index", width: "50px" },
      { text: "Type", value: "orderProductType", align: "center" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "100px",
      },
    ],
    headersOptionProductOthers: [
      { text: "No", value: "index", width: "50px" },
      { text: "Type", value: "orderProductType", align: "center" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "100px",
      },
    ],
    items: [],
    itemsOptionProduct: [],
    itemsOptionProductTypeGas: [],
    itemsOptionProductTypeUTI: [],
    itemsOptionProductTypeOthers: [],
    dialog: { show: false, success: false, error: false, errorMessage: "" },
    dialogDelete: false,
    dialogDeleteList: false,
    item: {},
  }),

  watch: {},
  created() {
    this.getListData();
    this.getListDataProductOption();
    this.getListDataProductTypeOption();
  },

  methods: {
    hasApparatus(values) {
      try {
        const parsed = typeof values === "string" ? JSON.parse(values) : values;
        return parsed.apparatusList && parsed.apparatusList.length > 0;
      } catch (e) {
        return false;
      }
    },
    clickDeleteList(item, type) {
      this.item = {
        ...item,
        type,
      };
      this.dialogDeleteList = true;
    },

    closeDeleteList() {
      this.dialogDeleteList = false;
      this.item = {};
    },

    deleteOptionListConfirm() {
      if (this.item?.type) {
        this.deleteOptionList(this.item, this.item.type);
        this.dialogDeleteList = false;
      }
    },

    deleteOptionList(itemToDelete, type) {
      this.dialog.success = false;
      this.dialog.error = false;
      this.loading = true;

      // Tentukan index dokumen berdasarkan type
      const indexMap = {
        UTI: 0,
        GAS: 1,
        OTHERS: 2,
      };
      const targetIndex = indexMap[type];

      if (targetIndex === undefined) {
        this.dialog.error = true;
        this.dialog.errorMessage = "Invalid product type!";
        return;
      }

      paramsCollection
        .where("paramsGroup", "==", "Orders Product Type")
        .where("isDeleted", "==", false)
        .get()
        .then((querySnapshot) => {
          const doc = querySnapshot.docs[targetIndex];

          if (!doc || !doc.exists) {
            throw new Error("Document not found.");
          }

          let rawValues = doc.data().values;

          let parsedValues =
            typeof rawValues === "string"
              ? JSON.parse(rawValues.replace(/\\"/g, '"'))
              : rawValues;

          const updatedValues = parsedValues.filter(
            (entry) => entry.orderProductType !== itemToDelete.orderProductType
          );

          return doc.ref.update({
            values: JSON.stringify(updatedValues),
            updatedAt: new Date().toISOString(),
            updatedBy: "Admin",
          });
        })
        .then(() => {
          this.dialog.success = true;
          this.getListDataProductTypeOption(); // Refresh tampilan
        })
        .catch((error) => {
          console.error("Delete failed:", error);
          this.dialog.error = true;
          this.dialog.errorMessage = error.message || "Something went wrong.";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetDialog() {
      this.dialog = {
        show: false,
        success: false,
        error: false,
        errorMessage: "",
      };
      this.item = {};
    },

    goToAdd(payload) {
      console.log(payload);
      if (payload == "option-product-add") {
        return this.$router.replace({ name: `${payload}` });
      }
      if (payload == "option-product-type-add") {
        return this.$router.replace({ name: `${payload}` });
      }
      this.$router.replace({ name: "parameter-configuration-add" });
    },

    clickView(item) {
      this.$router.replace({
        name: "parameter-configuration-edit",
        query: { paramsId: item.paramsId, statusPage: "view" },
      });
    },

    clickEdit(item) {
      this.$router.replace({
        name: "parameter-configuration-edit",
        query: { paramsId: item.paramsId, statusPage: "edit" },
      });
    },

    clickDelete(item) {
      this.dialogDelete = true;
      this.item = item;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.item = {};
    },

    deleteItemConfirm() {
      this.dialog.success = false;
      this.dialog.error = false;

      const newData = {
        isDeleted: true,
        deletedAt: new Date().toISOString(),
        deletedBy: "Admin", // Bisa ganti sesuai user login
      };

      firebaseDB
        .doc(`/params/${this.item.paramsId}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.dialog.error = true;
            this.dialog.errorMessage = "Parameter not found.";
            return;
          }
          return doc.ref.update(newData);
        })
        .then(() => {
          this.dialogDelete = false;
          this.dialog.success = true;
          this.getListData(); // refresh data
        })
        .catch((error) => {
          console.error("Delete failed:", error);
          this.dialog.error = true;
          this.dialog.errorMessage = "Something went wrong.";
        });
    },

    getListData() {
      this.loading = true;
      paramsCollection
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              paramsId: doc.id,
              paramsGroup: doc.data().paramsGroup,
              params: doc.data().params,
              certificate: this.convertCertificate(doc.data()),
              listGas: this.convertListGas(doc.data()),
              listCalibration: this.convertListCalibration(doc.data()),
              inspectionReport: this.convertInspectionReport(doc.data()),
              values: this.convertData(doc.data().values),
              status: doc.data().status,
              createdAt: doc.data().createdAt,
              createdBy: doc.data().createdBy,
              updatedAt: doc.data().updatedAt,
              updatedBy: doc.data().updatedBy,
            });
          });

          this.loading = false;
          // this.items = newData.sort(function (a, b) {
          //   return a.createdAt > b.createdAt
          //     ? -1
          //     : a.createdAt < b.createdAt
          //     ? 1
          //     : 0;
          // });

          this.items = newData
            .filter(
              (item) =>
                item.paramsGroup !== "Work Form" &&
                item.paramsGroup !== "Order Product" &&
                item.paramsGroup !== "Orders Product Type" &&
                item.paramsGroup !== "Orders"
            )
            .sort((a, b) => {
              return a.createdAt > b.createdAt
                ? -1
                : a.createdAt < b.createdAt
                ? 1
                : 0;
            });

          console.log(this.items);
          localStorage.setItem("masterParams", JSON.stringify(newData));
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },

    getListDataProductOption() {
      this.loading = true;
      paramsCollection
        .where("params", "==", "Orders Product")
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderProduct: doc.data().values,
            });
          });
          this.loading = false;
          newData = JSON.parse(newData[0].orderProduct.replace(/\\"/g, '"'));
          this.itemsOptionProduct = newData;
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },

    getListDataProductTypeOption() {
      this.loading = true;
      paramsCollection
        .where("paramsGroup", "==", "Orders Product Type")
        .where("isDeleted", "==", false)
        .get()
        .then((data) => {
          let newData = [];
          data.forEach((doc) => {
            newData.push({
              orderProductType: doc.data().values,
            });
          });
          this.loading = false;
          for (let i = 0; i < newData.length; i++) {
            newData[i].orderProductType = JSON.parse(
              newData[i].orderProductType.replace(/\\"/g, '"')
            );
          }
          this.itemsOptionProductTypeUTI = newData[0].orderProductType;
          this.itemsOptionProductTypeGas = newData[1].orderProductType;
          this.itemsOptionProductTypeOthers = newData[2].orderProductType;
        })
        .catch((err) => {
          this.loading = false;
          console.debug(err, "params list");
        });
    },

    convertCertificate(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.certificate) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertListGas(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.listGas) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertListCalibration(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.listCalibration) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertRandom(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.listCalibration) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertInspectionReport(item) {
      let result = "";
      let data1 = JSON.parse(item.values);
      if (typeof data1 === "string") {
        try {
          let data2 = JSON.parse(data1);
          if (data2.inspectionReport) {
            result = "OK";
          }
        } catch (error) {
          return "Data Broken";
        }
      }
      return result;
    },

    convertData(item) {
      let data = JSON.parse(item);
      if (typeof data === "string" || typeof data === "number") {
        return data;
      } else {
        return item;
      }
    },
  },
};
</script>

<style scoped>
/* Kustomisasi header tabel */
.custom-header-gray ::v-deep(.v-data-table__wrapper thead tr) {
  background-color: #d5d5d5;
}

/* Styling untuk perangkat kecil */
@media (max-width: 960px) {
  .pa-5 {
    padding: 16px !important;
  }

  .v-card-title {
    font-size: 16px;
    text-align: center;
  }

  .v-data-table {
    font-size: 14px;
  }
}

/* Styling untuk perangkat sangat kecil */
@media (max-width: 600px) {
  .v-card-title {
    font-size: 14px;
  }

  .v-text-field {
    font-size: 14px;
  }
}
</style>
